import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const ReassignmentModal = ({ show, handleClose, ticket, onTaskUpdated, avatarUrl }) => {
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [srTypes, setSrTypes] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
    const [selectedSrType, setSelectedSrType] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [description, setDescription] = useState('');
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [showTeam, setShowTeam] = useState(false);
    const [needByDate, setNeedByDate] = useState('');

    useEffect(() => {
        if (ticket) {
            setRemarks(ticket.remarks || '');
            setAttachments(ticket.attachments || []);
            setDescription(ticket.description || '');
            setNeedByDate(ticket.need_by_date ? new Date(ticket.need_by_date).toISOString().split('T')[0] : '');
            fetchDepartments(ticket.location.company.id);
        }
    }, [ticket]);

    useEffect(() => {
        if (selectedDepartment) {
            fetchSubDepartments(selectedDepartment.value);
        } else {
            setSubDepartments([]);
            setSelectedSubDepartment(null);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedSubDepartment) {
            fetchSrTypes(selectedSubDepartment.value);
        } else {
            setSrTypes([]);
            setSelectedSrType(null);
        }
    }, [selectedSubDepartment]);

    const fetchDepartments = async (companyId) => {
        try {
            const response = await axios.get('/departments/fetch_dept', { params: { company_id: companyId } });
            const departments = response.data.map(dept => ({ value: dept.id, label: dept.name }));
            setDepartments(departments);
            setSelectedDepartment(departments.find(dept => dept.value === ticket.department_id) || null);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const fetchSubDepartments = async (departmentId) => {
        try {
            const response = await axios.get('/main_types/fetch_main_types', { params: { department_id: departmentId } });
            const subDepartments = response.data.map(subDept => ({ value: subDept.id, label: subDept.name }));
            setSubDepartments(subDepartments);
            setSelectedSubDepartment(subDepartments.find(subDept => subDept.value === ticket.main_type_id) || null);
        } catch (error) {
            toast.error("Error fetching sub-departments");
        }
    };

    const fetchSrTypes = async (mainTypeId) => {
        try {
            const response = await axios.get('/ticket_types/fetch_ticket_types', { params: { main_type_id: mainTypeId } });
            const srTypes = response.data.map(type => ({ value: type.id, label: type.name }));
            setSrTypes(srTypes);
            setSelectedSrType(srTypes.find(type => type.value === ticket.ticket_type_id) || null);
        } catch (error) {
            toast.error("Error fetching SR types");
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`/employees/fetch_employee`, {
                params: {
                    department_id: selectedDepartment ? selectedDepartment.value : null,
                    location_id: ticket ? ticket.location_id : null,
                },
            });
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const handleToggleTeam = () => {
        setShowTeam(!showTeam);
        if (!showTeam) {
            fetchEmployees();
        }
    };

    const handleChangeDescription = (value) => {
        setDescription(value);
    };

    const handleAddAttachment = (event) => {
        const files = Array.from(event.target.files);
        setAttachments([...attachments, ...files]);
    };

    const handleViewAttachment = async (attachmentId) => {
        try {
            const response = await axios.get(`/service_requests/${ticket.id}/fetch_attachment/${attachmentId}`);
            const fileUrl = response.data.file_url;
            const newWindow = window.open(fileUrl, '_blank');
            if (newWindow) {
                newWindow.opener = null;
            } else {
                alert('Please allow popups for this website');
            }
        } catch (error) {
            console.error("Error fetching attachment:", error);
        }
    };

    const generateTicketNumber = () => {
        if (!selectedDepartment || !selectedSubDepartment || !selectedSrType) {
            return 'TICKET-INVALID';
        }

        const getInitials = (str) => str ? str.substring(0, 2).toUpperCase() : '';

        const departmentCode = getInitials(selectedDepartment.label);
        const subDepartmentCode = getInitials(selectedSubDepartment.label);
        const srTypeCode = getInitials(selectedSrType.label);
        const date = new Date();
        const dateCode = `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getFullYear()).substring(2)}`;

        const datePrefix = `${departmentCode}${subDepartmentCode}${srTypeCode}${dateCode}`;

        const existingNumbers = tickets
            .filter(req => req.ticket_number.startsWith(datePrefix))
            .map(req => parseInt(req.ticket_number.replace(`${datePrefix}-`, ''), 10))
            .filter(num => !isNaN(num));

        const nextNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;
        const numberPrefix = String(nextNumber).padStart(3, '0');

        return `${datePrefix}-${numberPrefix}`;
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('ticket[remarks]', remarks);
            formData.append('ticket[description]', description);
            formData.append('ticket[need_by_date]', needByDate);
            if (selectedDepartment) formData.append('ticket[department_id]', selectedDepartment.value);
            if (selectedSubDepartment) formData.append('ticket[main_type_id]', selectedSubDepartment.value);
            if (selectedSrType) formData.append('ticket[ticket_type_id]', selectedSrType.value);

            attachments.forEach((file, index) => {
                if (file instanceof File) {
                    formData.append(`ticket[attachments_attributes][${index}][file]`, file);
                }
            });

            await axios.post(`/service_requests/${ticket.id}/update_reassignment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success('Reassignment details updated successfully');
            handleClose();
            onTaskUpdated();
        } catch (error) {
            console.error("Error updating reassignment details:", error);
            toast.error('Error updating reassignment details');
        }
    };

    const resetForm = () => {
        setSelectedDepartment(null);
        setSelectedSubDepartment(null);
        setSelectedSrType(null);
        setRemarks('');
        setAttachments([]);
        setDescription('');
        setEmployees([]);
        setSelectedEmployees([]);
        setShowTeam(false);
        setNeedByDate('');
    };

    const handleCloseModal = () => {
        resetForm();
        handleClose();
    };

    const inputStyle = { height: '30px', fontSize: '14px' };

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg">
            <Modal.Header>
                <Modal.Title>Re-assign SR to Other Department</Modal.Title>
                <FontAwesomeIcon icon={faTimes} onClick={handleCloseModal} style={{ cursor: 'pointer', marginLeft: 'auto' }} />
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label>SR #</label>
                            <input
                                type="text"
                                className="form-control"
                                value={ticket ? ticket.ticket_number : ''}
                                style={{ backgroundColor: "#f1f1f1", ...inputStyle }}
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Department</label>
                            <Select
                                options={departments}
                                value={selectedDepartment}
                                onChange={setSelectedDepartment}
                                placeholder="Select Department"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label>Company Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={ticket && ticket.location && ticket.location.company ? ticket.location.company.name : ''}
                                style={{ backgroundColor: "#f1f1f1", ...inputStyle }}
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Sub-Dept</label>
                            <Select
                                options={subDepartments}
                                value={selectedSubDepartment}
                                onChange={setSelectedSubDepartment}
                                placeholder="Select Sub-Dept"
                                isDisabled={!selectedDepartment}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label>Time Created</label>
                            <input
                                type="text"
                                className="form-control"
                                value={ticket ? new Date(ticket.created_at).toLocaleString() : ''}
                                style={{ backgroundColor: "#f1f1f1", ...inputStyle }}
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label>SR Type</label>
                            <Select
                                options={srTypes}
                                value={selectedSrType}
                                onChange={setSelectedSrType}
                                placeholder="Select SR Type"
                                isDisabled={!selectedSubDepartment}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label>Need By Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={needByDate}
                                onChange={(e) => setNeedByDate(e.target.value)}
                                style={{ backgroundColor: "#f1f1f1", ...inputStyle }}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className={'d-flex justify-content-center align-items-center label-color'}>Remarks</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Remarks"
                                style={inputStyle}
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className={'d-flex justify-content-center align-items-center label-color'}>Description</label>
                        <ReactQuill
                            value={description}
                            onChange={handleChangeDescription}
                            theme="snow"
                            style={{ height: '150px' }}
                        />
                    </div>
                    <div className="form-group">
                        <label className={'d-flex justify-content-center align-items-center label-color'} style={{ marginTop: '3rem' }}>Attachments (Doc, JPEG, PNG, PDF) </label>
                        <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                            {attachments.length > 0 ? (
                                attachments.map((file, index) => (
                                    <div
                                        key={index}
                                        style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <span>{file.file_file_name || file.name}</span>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                onClick={() => handleViewAttachment(file.id)}
                                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                onClick={() => document.getElementById('fileInput').click()}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No attachments</p>
                            )}
                        </div>
                        {attachments.length === 0 && (
                            <FontAwesomeIcon
                                icon={faPlus}
                                onClick={() => document.getElementById('fileInput').click()}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                        )}
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleAddAttachment}
                        />
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Re-Assign
                </Button>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReassignmentModal;
