import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import Sidebar from "./Layout/Sidebar";
import TopNavBar from "./Layout/TopNavBar";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #f5f5f5;
`;

const PanelContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background: #f8f9fa;
  }
`;

const ActionButton = styled.button`
  background-color: rgba(48, 48, 48, 1);;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
`;

const UploadButton = styled.button`
  background-color: rgba(48, 48, 48, 1);;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const FontSetPanel = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const AddRowButton = styled.button`
  background-color: rgba(48, 48, 48, 1);;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
`;

const FontPage = () => {
    const [creatingFontSet, setCreatingFontSet] = useState(false);
    const [fontSetRows, setFontSetRows] = useState([{ id: null, fontName: '', selectFont: '', specificSize: '1.0', priceChange: '0' }]);
    const [fonts, setFonts] = useState([]);
    const [fontSets, setFontSets] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fontName, setFontName] = useState(""); // Added state for font name
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [fontSetName, setFontSetName] = useState("");
    const [editingFontSet, setEditingFontSet] = useState(null);

    useEffect(() => {
        fetchFonts();
        fetchFontSets();
    }, []);

    const fetchFonts = () => {
        axios.get('/sapphire_x_you/fonts_data')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setFonts(response.data);
                } else {
                    setFonts([]);
                    console.error('Fonts response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the fonts!', error);
            });
    };

    const fetchFontSets = () => {
        axios.get('/sapphire_x_you/font_sets')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setFontSets(response.data);
                } else {
                    setFontSets([]);
                    console.error('Font sets response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the font sets!', error);
            });
    };

    const addRow = () => {
        setFontSetRows([...fontSetRows, { id: null, fontName: '', selectFont: '', specificSize: '1.0', priceChange: '0' }]);
    };

    const removeRow = (index) => {
        const newRows = fontSetRows.filter((row, i) => i !== index);
        setFontSetRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...fontSetRows];
        newRows[index][field] = value;
        setFontSetRows(newRows);
    };

    const handleCreateFontSet = () => {
        setCreatingFontSet(true);
        setEditingFontSet(null);
    };

    const handleEditFontSet = (fontSet) => {
        setFontSetName(fontSet.name);
        axios.get(`/sapphire_x_you/${fontSet.id}/show_font_set`)
            .then(response => {
                setFontSetRows(response.data.font_set_fonts.map(fontSetFont => {
                    const font = fonts.find(font => font.id === fontSetFont.font_id);
                    return {
                        id: fontSetFont.id,
                        fontName: font ? font.name : '',
                        selectFont: fontSetFont.font_id,
                        specificSize: fontSetFont.specific_size,
                        priceChange: fontSetFont.price_change
                    };
                }));
                setEditingFontSet(fontSet.id);
                setCreatingFontSet(true);
            })
            .catch(error => {
                console.error('There was an error fetching the font set!', error);
            });
    };


    const handleCancelCreateFontSet = () => {
        setCreatingFontSet(false);
        setFontSetRows([{ id: null, fontName: '', selectFont: '', specificSize: '1.0', priceChange: '0' }]);
        setFontSetName("");
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('font[file]', selectedFile);
        formData.append('font[name]', fontName); // Include font name in form data

        axios.post('/sapphire_x_you/upload_font', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                alert('Font uploaded successfully');
                setShowUploadModal(false);
                fetchFonts();
            })
            .catch(error => {
                alert('Failed to upload font');
                console.error('There was an error uploading the font!', error);
            });
    };

    const handleSubmitFontSet = (event) => {
        event.preventDefault();

        const fontSetData = {
            font_set: {
                name: fontSetName,
                font_set_fonts_attributes: fontSetRows.map(row => ({
                    id: row.id,
                    font_id: row.selectFont,
                    specific_size: row.specificSize,
                    price_change: row.priceChange,
                    font_name: row.fontName // Include font name here
                }))
            }
        };

        if (editingFontSet) {
            axios.put(`/sapphire_x_you/${editingFontSet}/update_font_set`, fontSetData)
                .then(response => {
                    alert('Font set updated successfully');
                    setCreatingFontSet(false);
                    fetchFontSets();
                })
                .catch(error => {
                    alert('Failed to update font set');
                    console.error('There was an error updating the font set!', error);
                });
        } else {
            axios.post('/sapphire_x_you/create_font_set', fontSetData)
                .then(response => {
                    alert('Font set created successfully');
                    setCreatingFontSet(false);
                    fetchFontSets();
                })
                .catch(error => {
                    alert('Failed to create font set');
                    console.error('There was an error creating the font set!', error);
                });
        }
    };

    return (
        <PageContainer>
            <TopNavBar />
            <MainContent>
                <Sidebar />
                <ContentArea>
                    {!creatingFontSet ? (
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <UploadButton onClick={() => setShowUploadModal(true)}><FontAwesomeIcon icon={faUpload} />  Upload Font</UploadButton>
                                <ActionButton onClick={handleCreateFontSet}><FontAwesomeIcon icon={faPlus} />  Create font set</ActionButton>
                            </div>
                            <PanelContainer>
                                <SearchBar type="text" placeholder="Filter search" />
                                <TableContainer>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Set title</th>
                                            <th>Fonts</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {fontSets.map(fontSet => (
                                            <tr key={fontSet.id}>
                                                <td>{fontSet.name}</td>
                                                <td>{fontSet.fonts_count}</td>
                                                <td>
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEditFontSet(fontSet)} style={{ cursor: 'pointer' }} />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            </PanelContainer>
                        </React.Fragment>
                    ) : (
                        <FontSetPanel>
                            <form onSubmit={handleSubmitFontSet}>
                                <div style={{ marginBottom: '20px' }}>
                                    <label>Font Set Name:</label>
                                    <input
                                        type="text"
                                        value={fontSetName}
                                        onChange={(e) => setFontSetName(e.target.value)}
                                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                    />
                                </div>
                                {fontSetRows.map((row, index) => (
                                    <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Font Name:</label>
                                            <input
                                                type="text"
                                                value={row.fontName}
                                                onChange={(e) => handleInputChange(index, 'fontName', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Select Font:</label>
                                            <select
                                                value={row.selectFont}
                                                onChange={(e) => handleInputChange(index, 'selectFont', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            >
                                                <option value="">Select Font</option>
                                                {fonts.map((font) => (
                                                    <option key={font.id} value={font.id}>{font.name || font.file_file_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Specific Size:</label>
                                            <input
                                                type="text"
                                                value={row.specificSize}
                                                onChange={(e) => handleInputChange(index, 'specificSize', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Price Change:</label>
                                            <input
                                                type="text"
                                                value={row.priceChange}
                                                onChange={(e) => handleInputChange(index, 'priceChange', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => removeRow(index)}
                                                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} color="#dc3545" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <AddRowButton type="button" onClick={addRow}>+ Add Row</AddRowButton>
                                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        type="button"
                                        onClick={handleCancelCreateFontSet}
                                        style={{ backgroundColor: '#6c757d', padding: '10px 20px', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </FontSetPanel>
                    )}
                </ContentArea>
            </MainContent>

            <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Font</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Font Name:</label>
                    <input
                        type="text"
                        value={fontName}
                        onChange={(e) => setFontName(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                    <input type="file" accept=".ttf,.otf" onChange={handleFileChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFileUpload}>
                        Upload Font
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
};

export default FontPage;
