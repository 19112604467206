import React from 'react';

const TaskView = ({ task }) => {
    return (
        <div className="employee-details" style={{justifyContent: 'space-around'}}>
            {/*<div className="employee-photo">*/}
            {/*    <img src={employee.photoUrl || '/path/to/default-avatar.png'} alt="Employee Avatar" />*/}
            {/*</div>*/}
            <div className="employee-info task">
                <p><span className="label">Company:</span> <span className="value">{task.companyName}</span></p>
                <p><span className="label">SR NO:</span>  <span className="value">{task.srNo}</span></p>
                <p><span className="label">Department:</span> <span className="value">{task.department}</span></p>
                <p><span className="label">Sub-Department:</span> <span className="value">{task.subDept}</span></p>
            </div>
            <div className="employee-info task">
                <p><span className="label">Location:</span> <span className="value">{task.locationName}</span></p>
                <p><span className="label">SR Type:</span> <span className="value">{task.srType}</span></p>
                <p><span className="label"> Time Created:</span> <span className="value">{task.timeCreated}</span></p>
                <p><span className="label">Need By Date:</span>  <span className="value">{task.needDate}</span></p>
            </div>
        </div>
    );
};

export default TaskView;
