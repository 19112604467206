import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCogs, faTicketAlt, faBuildingUser,faMapMarker } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavBarContainer = styled(Navbar)`
  background-color: rgba(53, 66, 82, 0.862);
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
`;

const StyledNav = styled(Nav)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  flex-grow: 1;
  text-align: center;
  position: relative;

  &.active {
    color: #fff;
    font-weight: bold;
    background-color: #2b3543;
    box-shadow: rgb(66, 139, 202) 0px -3px 0px 0px inset;
  }
  &:hover {
    color: rgba(255,255,255,.8);
    background-color: #354252;
  }
  &.active::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 7px solid rgb(66, 139, 202);
  }
`;

const TopNavBar = () => {
    return (
        <NavBarContainer variant="dark">
            <StyledNav>
                <StyledNavLink
                    to="/companies"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faBuilding} /> Company
                </StyledNavLink>
                <StyledNavLink
                    to="/locations"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faMapMarker} /> Location
                </StyledNavLink>
                <StyledNavLink
                    to="/departments"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faBuildingUser} /> Department
                </StyledNavLink>
                <StyledNavLink
                    to="/main_types"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faCogs} /> Sub Department
                </StyledNavLink>
                <StyledNavLink
                    to="/ticket_types"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faTicketAlt} /> SR Type
                </StyledNavLink>
            </StyledNav>
        </NavBarContainer>
    );
};

export default TopNavBar;
