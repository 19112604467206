import React, {useEffect, useState} from 'react';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { FaTimes } from "react-icons/fa";

const MilestoneModal = ({ show, onHide, handleSave, projectName, initialMilestoneState }) => {
    const commonStyle = {
        fontSize: '0.8rem',
        backgroundColor: '#fff'
    };


    useEffect(() => {
        // Whenever `initialMilestoneState` changes, update `newMilestone`
        setNewMilestone(initialMilestoneState);
    }, [initialMilestoneState]);

    const [newMilestone, setNewMilestone] = useState(initialMilestoneState);

    const handleChange = (name, value) => {
        setNewMilestone(prev => ({ ...prev, [name]: value }));
        if (name === 'plannedStartDate' || name === 'plannedDueDate') {
            calculatePlannedDays(value, name);
        }
        if (name === 'actualStartDate' || name === 'actualDueDate') {
            calculateActualDays(value, name);
        }
    };

    const calculatePlannedDays = (value, name) => {
        const startDate = name === 'plannedStartDate' ? value : newMilestone.plannedStartDate;
        const endDate = name === 'plannedDueDate' ? value : newMilestone.plannedDueDate;
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setNewMilestone(prev => ({ ...prev, numOfDaysPlanned: diffDays }));
        }
    };

    const calculateActualDays = (value, name) => {
        const startDate = name === 'actualStartDate' ? value : newMilestone.actualStartDate;
        const endDate = name === 'actualDueDate' ? value : newMilestone.actualDueDate;
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setNewMilestone(prev => ({ ...prev, numOfDaysActual: diffDays }));
        }
    };
    const handleSaveMilestone = () => {
        handleSave(newMilestone);
        setNewMilestone(initialMilestoneState);
        onHide();
    };

    const handleModalClose = () => {
        setNewMilestone(initialMilestoneState);
        onHide();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered size="lg">
            <Modal.Header>
                <Modal.Title>Create New Milestone</Modal.Title>
            </Modal.Header>
            <Button variant="link" onClick={handleModalClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                <FaTimes />
            </Button>
            <Modal.Body>
                <Form>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="text"
                                    value={projectName}
                                    readOnly
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Project Name</label>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className="floating-label-group">
                                <Form.Control
                                    as="select"
                                    value={newMilestone.priority || ''}
                                    onChange={(e) => handleChange('priority', e.target.value)}
                                    placeholder=" "
                                    style={commonStyle}
                                >
                                    <option value="">Select Priority</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High Priority</option>
                                </Form.Control>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="text"
                                    value={newMilestone.milestoneName || ''}
                                    onChange={(e) => handleChange('milestoneName', e.target.value)}
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Milestone Name *</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <div className="floating-label-group">
                                <ReactQuill
                                    value={newMilestone.description || ''}
                                    onChange={(value) => handleChange('description', value)}
                                    theme="snow"
                                    placeholder=" "
                                    style={{ height: '150px', fontSize: '0.8rem' }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-5">
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="date"
                                    value={newMilestone.plannedStartDate || ''}
                                    onChange={(e) => handleChange('plannedStartDate', e.target.value)}
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Planned Start Date *</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="date"
                                    value={newMilestone.plannedDueDate || ''}
                                    onChange={(e) => handleChange('plannedDueDate', e.target.value)}
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Planned Due Date *</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="date"
                                    value={newMilestone.actualStartDate || ''}
                                    onChange={(e) => handleChange('actualStartDate', e.target.value)}
                                    readOnly
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Actual Start Date</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="date"
                                    value={newMilestone.actualDueDate || ''}
                                    onChange={(e) => handleChange('actualDueDate', e.target.value)}
                                    readOnly
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Actual Due Date</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="text"
                                    value={newMilestone.numOfDaysPlanned || ''}
                                    readOnly
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Num of Days (Planned)</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="floating-label-group">
                                <Form.Control
                                    type="text"
                                    value={newMilestone.numOfDaysActual || ''}
                                    readOnly
                                    placeholder=" "
                                    style={commonStyle}
                                />
                                <label style={commonStyle}>Num of Days (Actual)</label>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSaveMilestone}>Save Milestone</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MilestoneModal;
