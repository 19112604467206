import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import TopNavBar from "../../Organization/Layout/TopNavBar";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import toast, { Toaster } from 'react-hot-toast';

const MainTypes = () => {
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [mainTypes, setMainTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentMainType, setCurrentMainType] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [mainTypeToDelete, setMainTypeToDelete] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedLocationId, setSelectedLocationId] = useState("");
    const [emails, setEmails] = useState("");
    const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

    useEffect(() => {
        fetchCompanies();
        fetchMainTypes();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get("/companies/fetch_company");
            setCompanies(response.data);
        } catch (error) {
            toast.error("Error fetching companies");
        }
    };

    const fetchDepartments = async (companyId, locationId) => {
        try {
            const response = await axios.get("/departments/fetch_dept", { params: { company_id: companyId, location_id: locationId } });
            setDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const fetchLocations = async (companyId) => {
        try {
            const response = await axios.get("/locations/fetch_location", { params: { company_id: companyId } });
            setLocations(response.data);
        } catch (error) {
            toast.error("Error fetching locations");
        }
    };

    const fetchMainTypes = async () => {
        try {
            const response = await axios.get("/main_types/fetch_main_types");
            setMainTypes(response.data);
        } catch (error) {
            toast.error("Error fetching main types");
        }
    };

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        setSelectedCompanyId(companyId);
        setSelectedLocationId(""); // Reset location
        setSelectedDepartmentId(""); // Reset department
        setDepartments([]); // Reset departments
        setEmails(""); // Reset emails
        if (companyId) {
            fetchLocations(companyId);
        } else {
            setLocations([]);
        }
    };

    const handleLocationChange = (e) => {
        const locationId = e.target.value;
        setSelectedLocationId(locationId);
        setSelectedDepartmentId(""); // Reset department
        setEmails(""); // Reset emails
        if (locationId) {
            fetchDepartments(selectedCompanyId, locationId);
        } else {
            setDepartments([]);
        }
    };

    const handleDepartmentChange = (e) => {
        const departmentId = e.target.value;
        setSelectedDepartmentId(departmentId);
    };

    const handleSave = async (mainType) => {
        try {
            if (currentMainType) {
                await toast.promise(
                    axios.put(`/main_types/${currentMainType.id}`, mainType),
                    {
                        loading: 'Saving...',
                        success: 'Main Type updated successfully!',
                        error: 'Error saving main type',
                    },
                    { position: 'top-center' }
                );
            } else {
                await toast.promise(
                    axios.post("/main_types", mainType),
                    {
                        loading: 'Saving...',
                        success: 'Main Type created successfully!',
                        error: 'Error saving main type',
                    },
                    { position: 'top-center' }
                );
            }
            setShowModal(false);
            setCurrentMainType(null);
            fetchMainTypes();
        } catch (error) {
            toast.error("Error saving main type");
        }
    };

    const handleCreate = () => {
        setCurrentMainType(null);
        setShowModal(true);
    };

    const handleEdit = (mainType) => {
        setCurrentMainType(mainType);
        setSelectedCompanyId(mainType.company_id);
        setSelectedLocationId(mainType.location_id);
        setSelectedDepartmentId(mainType.department_id);
        setEmails(mainType.emails.join(", "));
        fetchDepartments(mainType.company_id, mainType.location_id);
        setShowModal(true);
    };

    const confirmDelete = (mainTypeId) => {
        setMainTypeToDelete(mainTypeId);
        setShowConfirmModal(true);
    };

    const handleDelete = async () => {
        try {
            await toast.promise(
                axios.delete(`/main_types/${mainTypeToDelete}`),
                {
                    loading: 'Deleting...',
                    success: 'Main Type deleted successfully!',
                    error: 'Error deleting main type',
                },
                { position: 'top-center' }
            );
            setShowConfirmModal(false);
            fetchMainTypes();
        } catch (error) {
            toast.error("Error deleting main type");
        }
    };

    return (
        <div className="col-lg-12">
            <TopNavBar />
            <Toaster position="top-center" reverseOrder={false} />
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                    <button className="btn btn-primary mr-2" onClick={handleCreate}>
                        <FontAwesomeIcon icon={faPlus} /><span className="pl-1">Create Sub-Dept</span>
                    </button>
                    <div className="iq-header-title">
                        <h4 className="card-title">Sub-dept Management</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    {mainTypes.length === 0 ? (
                        <div style={{
                            backgroundColor: '#eaf6ea',
                            borderLeft: '3px solid #5cb85c',
                            padding: '10px 15px',
                            color: 'rgb(22, 160, 133)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '0',
                        }}>
                            No Record Found!
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <Table className="table mb-0 mt-2 table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" className="font-weight-bold">Sr. no.</th>
                                    <th scope="col" className="font-weight-bold">Name</th>
                                    <th scope="col" className="font-weight-bold">Short Name</th>
                                    <th scope="col" className="font-weight-bold">Company</th>
                                    <th scope="col" className="font-weight-bold">Location</th>
                                    <th scope="col" className="font-weight-bold">Department</th>
                                    <th scope="col" className="font-weight-bold">Status</th>
                                    <th scope="col" className="font-weight-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {mainTypes.map((mainType, index) => (
                                    <tr key={mainType.id}>
                                        <td>{index + 1}</td>
                                        <td>{mainType.name}</td>
                                        <td>{mainType.short_name}</td>
                                        <td>{mainType.company ? mainType.company.name : ''}</td>
                                        <td>{mainType.location ? mainType.location.name : ''}</td>
                                        <td>{mainType.department ? mainType.department.name : ''}</td>
                                        <td>
                                            <span className={`badge rounded-pill ${mainType.status ? 'badge-info' : 'badge-danger'}`}>
                                                {mainType.status ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td>
                                            <a onClick={() => handleEdit(mainType)} className="animate">
                                                <FontAwesomeIcon icon={faEdit} className="text-success pr-1 font-size-18" />
                                            </a>
                                            <a onClick={() => confirmDelete(mainType.id)} className="animate">
                                                <FontAwesomeIcon icon={faTrash} className="text-danger font-size-18" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>

            {showModal && (
                <MainTypeModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    onSave={handleSave}
                    mainType={currentMainType}
                    companies={companies}
                    departments={departments}
                    locations={locations}
                    onCompanyChange={handleCompanyChange}
                    onLocationChange={handleLocationChange}
                    onDepartmentChange={handleDepartmentChange}
                    selectedCompanyId={selectedCompanyId}
                    selectedLocationId={selectedLocationId}
                    emails={emails}
                />
            )}

            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={handleDelete}
                message="Are you sure you want to delete this main type?"
            />
        </div>
    );
};

const MainTypeModal = ({ show, onHide, onSave, mainType, companies, departments, locations, onCompanyChange, onLocationChange, onDepartmentChange, selectedCompanyId, selectedLocationId, emails }) => {
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [departmentId, setDepartmentId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [status, setStatus] = useState(false);
    const [emailList, setEmailList] = useState("");

    useEffect(() => {
        if (mainType) {
            setName(mainType.name);
            setShortName(mainType.short_name);
            setCompanyId(mainType.company_id);
            setDepartmentId(mainType.department_id);
            setLocationId(mainType.location_id);
            setStatus(mainType.status);
            setEmailList(mainType.emails.join(", "));
        } else {
            setName("");
            setShortName("");
            setCompanyId("");
            setDepartmentId("");
            setLocationId("");
            setStatus(false);
            setEmailList("");
        }
    }, [mainType]);

    useEffect(() => {
        if (companyId) {
            onCompanyChange({ target: { value: companyId } });
        }
    }, [companyId]);

    useEffect(() => {
        if (locationId) {
            onLocationChange({ target: { value: locationId } });
        }
    }, [locationId]);

    useEffect(() => {
        if (departmentId) {
            onDepartmentChange({ target: { value: departmentId } });
        }
    }, [departmentId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ name, short_name: shortName, company_id: companyId, department_id: departmentId, location_id: locationId, status, emails: emailList.split(",").map(email => email.trim()) });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header style={{ backgroundColor: 'rgb(22, 160, 133)', color: 'white' }}>
                <Modal.Title style={{ color: 'white' }}>{mainType ? "Edit Sub-Dept" : "Create Sub-Dept"}</Modal.Title>
                <Button variant="link" onClick={onHide} style={{ color: 'white', padding: '0' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body style={{ padding: '20px', borderRadius: '0 0 15px 15px' }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            as="select"
                            value={companyId}
                            onChange={(e) => {
                                setCompanyId(e.target.value);
                                onCompanyChange(e);
                            }}
                            required
                        >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            as="select"
                            value={locationId}
                            onChange={(e) => {
                                setLocationId(e.target.value);
                                onLocationChange(e);
                            }}
                            required
                            disabled={!selectedCompanyId}
                        >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                                <option key={location.id} value={location.id}>
                                    {location.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Department</Form.Label>
                        <Form.Control
                            as="select"
                            value={departmentId}
                            onChange={(e) => {
                                setDepartmentId(e.target.value);
                                onDepartmentChange(e);
                            }}
                            required
                            disabled={!selectedLocationId}
                        >
                            <option value="">Select Department</option>
                            {departments.map((department) => (
                                <option key={department.id} value={department.id}>
                                    {department.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Emails</Form.Label>
                        <Form.Control
                            type="text"
                            value={emailList}
                            onChange={(e) => setEmailList(e.target.value)}
                            placeholder="Enter emails separated by commas"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Sub-Dept Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Sub-Dept Short Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={shortName}
                            onChange={(e) => setShortName(e.target.value)}
                            maxLength={2}
                            placeholder={'2 Letters'}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customMainType"
                                checked={status}
                                onChange={(e) => setStatus(e.target.checked)}
                            />
                            <label className="custom-control-label" htmlFor="customMainType">
                                Active/InActive
                            </label>
                        </div>
                    </Form.Group>
                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="secondary" onClick={onHide} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', borderRadius: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" style={{ backgroundColor: '#007aff', borderColor: '#007aff', borderRadius: '10px' }}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default MainTypes;
