import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Modal, FloatingLabel, ProgressBar, Breadcrumb } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactApexChart from 'react-apexcharts';
import {FaPlus, FaMinus, FaTimes, FaEdit} from 'react-icons/fa';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import MilestoneModal from './Milestone';
import TaskModal from './TaskModal';
import toast, { Toaster } from 'react-hot-toast';

const ProjectDashboard = () => {
    const { id } = useParams();
    const [projectData, setProjectData] = useState(null);
    const [expandedMilestones, setExpandedMilestones] = useState({});
    const [showManageModal, setShowManageModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [showMilestoneModal, setShowMilestoneModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const quillRef = useRef(null);
    const [milestones, setMilestones] = useState([]);
    const [overallProgress, setOverallProgress] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [milestoneToEdit, setMilestoneToEdit] = useState(null);
    const [milestoneStats, setMilestoneStats] = useState({ completed: 0, inProgress: 0, notStarted: 0 });

    const initialTaskState = {
        milestone: { value: '', label: '' },
        taskType: { value: '', label: '' },
        taskName: '',
        description: '',
        plannedStartDate: '',
        plannedDueDate: '',
        actualStartDate: '',
        actualDueDate: '',
        numOfDaysPlanned: '',
        numOfDaysActual: '',
        selectedEmployees: []
    };
    const [initialMilestoneState, setInitialMilestoneState] = useState({
        milestoneName: '',
        priority: '',
        description: '',
        plannedStartDate: '',
        plannedDueDate: '',
        actualStartDate: '',
        actualDueDate: '',
        numOfDaysPlanned: 0,
        numOfDaysActual: 0
    });

    const [taskData, setTaskData] = useState(initialTaskState);

    useEffect(() => {
        axios.get(`/projects/fetch_all_projects`)
            .then(response => {
                const projects = response.data.projects;
                const selectedProject = projects.find(project => project.id === parseInt(id));
                if (selectedProject) {
                    setProjectData({
                        ...selectedProject,
                        est_start_date: formatDate(selectedProject.est_start_date),
                        est_end_date: formatDate(selectedProject.est_end_date),
                        act_start_date: formatDate(selectedProject.act_start_date),
                        act_end_date: formatDate(selectedProject.act_end_date)
                    });
                    setTeamMembers(selectedProject.employees.map(employee => employee.name));

                    axios.get(`/projects/${id}/milestones`)
                        .then(milestoneResponse => {
                            const { milestones, overall_progress } = milestoneResponse.data;
                            setMilestones(milestones);
                            setOverallProgress(overall_progress);
                        })
                        .catch(milestoneError => {
                            console.error('Error fetching milestones:', milestoneError);
                        });

                } else {
                    console.error('Project not found');
                }
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        const completed = milestones.filter(milestone => milestone.status === "Completed").length;
        const inProgress = milestones.filter(milestone => milestone.status === "In-Process").length;
        const notStarted = milestones.filter(milestone => milestone.status === "Not Started").length;

        setMilestoneStats({
            completed,
            inProgress,
            notStarted,
        });
    }, [milestones]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    };

    const toggleMilestone = (milestoneId) => {
        setExpandedMilestones(prevState => ({
            ...prevState,
            [milestoneId]: !prevState[milestoneId],
        }));
    };

    const removeTeamMember = (member) => {
        setTeamMembers(prevMembers => prevMembers.filter(m => m !== member));
    };

    const handleShowManageModal = (content) => {
        setModalContent(content);
        setShowManageModal(true);
    };

    const handleShowMilestoneModal = (milestone = null) => {
        if (milestone) {
            // Edit existing milestone
            setMilestoneToEdit(milestone);
            setInitialMilestoneState({
                milestoneName: milestone.name || '',
                priority: milestone.priority || '',
                description: milestone.description || '',
                plannedStartDate: milestone.planned_start_date || '',
                plannedDueDate: milestone.planned_due_date || '',
                actualStartDate: milestone.actual_start_date || '',
                actualDueDate: milestone.actual_due_date || '',
                numOfDaysPlanned: milestone.num_of_days_planned || 0,
                numOfDaysActual: milestone.num_of_days_actual || 0
            });
        } else {
            setMilestoneToEdit(null);
            setInitialMilestoneState({
                milestoneName: '',
                priority: '',
                description: '',
                plannedStartDate: '',
                plannedDueDate: '',
                actualStartDate: '',
                actualDueDate: '',
                numOfDaysPlanned: 0,
                numOfDaysActual: 0
            });
        }
        setShowMilestoneModal(true);
    };


    const handleCloseMilestoneModal = () => {
        setShowMilestoneModal(false);
        setMilestoneToEdit(null);
    };

    const handleShowTaskModal = () => {
        setShowTaskModal(true);
    };

    const handleCloseTaskModal = () => {
        setTaskData(initialTaskState);
        setShowTaskModal(false);
    };


    const handleSaveTask = (taskData) => {
        const formattedTaskData = {
            project_task: {
                milestone_id: taskData.milestone.value,
                task_type: taskData.taskType.value,
                task_name: taskData.taskName,
                description: taskData.description,
                planned_start_date: taskData.plannedStartDate,
                planned_due_date: taskData.plannedDueDate,
                actual_start_date: taskData.actualStartDate || null,
                actual_due_date: taskData.actualDueDate || null,
                num_of_days_planned: taskData.numOfDaysPlanned,
                num_of_days_actual: taskData.numOfDaysActual || null,
                employee_ids: taskData.selectedEmployees.map(emp => emp.value),
            }
        };

        axios.post(`/projects/${id}/create_task`, formattedTaskData)
            .then(response => {
                toast.success('Task saved successfully!');
                const newTask = response.data.task;
                setMilestones(prevMilestones => {
                    return prevMilestones.map(milestone => {
                        if (milestone.id === taskData.milestone.value) {
                            return {
                                ...milestone,
                                project_tasks: [...milestone.project_tasks, newTask]
                            };
                        }
                        return milestone;
                    });
                });
                setTaskData(initialTaskState);
                handleCloseTaskModal();
            })
            .catch(error => {
                toast.error('Error saving task. Please try again.');
                console.error('Error saving task:', error);
            });
    };

    const handleSaveMilestone = (milestoneData) => {
        const formattedData = {
            milestone: {
                name: milestoneData.milestoneName,
                priority: milestoneData.priority,
                description: milestoneData.description,
                planned_start_date: milestoneData.plannedStartDate,
                planned_due_date: milestoneData.plannedDueDate,
                actual_start_date: milestoneData.actualStartDate || null,
                actual_due_date: milestoneData.actualDueDate || null,
                num_of_days_planned: milestoneData.numOfDaysPlanned,
                num_of_days_actual: milestoneData.numOfDaysActual || null,
            },
            id: id
        };
        if (milestoneToEdit) {
            axios.put(`/projects/${id}/milestones/${milestoneToEdit.id}`, formattedData)
                .then(response => {
                    toast.success('Milestone updated successfully!');
                    const updatedMilestone = response.data.milestone;
                    setMilestones(prevMilestones =>
                        prevMilestones.map(m => m.id === milestoneToEdit.id ? updatedMilestone : m)
                    );
                    setShowMilestoneModal(false);
                })
                .catch(error => {
                    toast.error('Error updating milestone. Please try again.');
                });
        } else {
            axios.post(`/projects/${id}/create_milestone`, formattedData)
                .then(response => {
                    toast.success('Milestone saved successfully!');
                    const newMilestone = response.data.milestone;
                    setMilestones(prevMilestones => [...prevMilestones, newMilestone]);
                    setShowMilestoneModal(false);
                })
                .catch(error => {
                    toast.error('Error saving milestone. Please try again.');
                });
        }
    };




    const chartData = milestones.map(milestone => milestone.progress || 0);

    const series = [{
        data: chartData.map((progress, index) => ({
            x: `Milestone ${index + 1}`,
            y: progress
        }))
    }];

    const handleCloseManageModal = () => setShowManageModal(false);

    const expandableRowsComponent = ({ data }) => {
        const projectTasks = data && Array.isArray(data.project_tasks) ? data.project_tasks : [];

        return (
            <table style={{ width: '100%', marginTop: '10px', borderCollapse: 'collapse' }}>
                <thead>
                <tr>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}></th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}></th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd',backgroundColor: 'hsl(253deg 61% 85%)', color: "white" }}></th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Task Name</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Planned Start Date</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Planned End Date</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' ,backgroundColor: 'hsl(253deg 61% 85%)'}}>No Of Days</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' ,backgroundColor: 'hsl(253deg 61% 85%)'}}>Actual Start Date</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Actual End Date</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Progress</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500',backgroundColor: 'hsl(253deg 61% 85%)' }}>Status</th>
                </tr>
                </thead>
                <tbody>
                {projectTasks.map((task, idx) => (
                    <tr key={idx} style={{ backgroundColor: '#f9f9f9' }}>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}></td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}></td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}></td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{task.task_name}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{formatDate(task.planned_start_date)}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{formatDate(task.planned_due_date)}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{task.num_of_days_planned}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{task.actual_start_date ? formatDate(task.actual_start_date) : '-'}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{task.actual_due_date ? formatDate(task.actual_due_date) : '-'}</td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>
                            {task.progress ? (
                                <ProgressBar now={task.progress} label={`${task.progress}%`} style={{ height: '10px' }} />
                            ) : '-'}
                        </td>
                        <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '12px', fontWeight: '500' }}>{task.status || 'Not Started'}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    const columns = [
        {
            name: 'Milestone',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Planned Start Date',
            selector: row => formatDate(row.planned_start_date),
            sortable: true,
        },
        {
            name: 'Planned End Date',
            selector: row => formatDate(row.planned_due_date),
            sortable: true,
        },
        {
            name: 'No Of Days',
            selector: row => row.num_of_days_planned,
            sortable: true,
        },
        {
            name: 'Actual Start Date',
            selector: row => row.actual_start_date ? formatDate(row.actual_start_date) : '-',
            sortable: true,
        },
        {
            name: 'Actual End Date',
            selector: row => row.actual_due_date ? formatDate(row.actual_due_date) : '-',
            sortable: true,
        },
        {
            name: 'Progress',
            cell: row => (
                <div style={{ width: '100%', height: '10px', margin: 'auto' }}>
                    <ProgressBar
                        now={row.progress !== null && row.progress !== undefined ? row.progress : 0}
                        label={`${row.progress !== null && row.progress !== undefined ? row.progress : 0}%`}
                        style={{ height: '10px' }}
                        variant="custom"
                        className="custom-progress-bar"
                    />
                </div>
            ),
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }}>
                    <FaEdit
                        size={20}
                        color="green"
                        onClick={() => handleShowMilestoneModal(row)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    const formControlStyle = {
        height: '30px',
        padding: '0.375rem 0.75rem',
        fontSize: '0.875rem',
    };
    const milestoneTableStyles = {
        headCells: {
            style: {
                backgroundColor: 'hsl(253deg 61% 55%)',
                color: 'white',
            },
        },
    };
    return (
        <Container fluid className="my-4">
            <Toaster />
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <div className="card shadow-sm">
                        <div className="card-header text-center border bg_light">
                            <h3 style={{ fontWeight: 'bold' }}>{projectData && projectData.name ? projectData.name : 'Project Name'}</h3>
                        </div>
                        <div className="card-body">
                            <Form>
                                <Row className="mb-3">
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.est_start_date : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>Planned Start Date</label>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.act_start_date : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>Actual Start Date</label>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.act_start_date : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>Total Milestones</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.est_end_date : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>Planned End Date</label>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.act_end_date : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>Actual End Date</label>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Label>Milestone Progress</Form.Label>
                                        <div style={{ display: 'flex', gap: '0.9rem' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#4caf50',
                                                    borderRadius: '0.25rem',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem',
                                                    textAlign: 'center'
                                                }}>
                                                    Completed
                                                </div>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#4caf50',
                                                    color: '#fff',
                                                    borderRadius: '0.25rem',
                                                    minWidth: '2rem',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem'
                                                }}>
                                                    {milestoneStats.completed}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#2196f3',
                                                    borderRadius: '0.25rem',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem',
                                                    textAlign: 'center'
                                                }}>
                                                    In Progress
                                                </div>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#2196f3',
                                                    color: '#fff',
                                                    borderRadius: '0.25rem',
                                                    minWidth: '2rem',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem'
                                                }}>
                                                    {milestoneStats.inProgress}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#ff9800',
                                                    borderRadius: '0.25rem',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem',
                                                    textAlign: 'center'
                                                }}>
                                                    Not Started
                                                </div>
                                                <div style={{
                                                    padding: '0.25rem 0.5rem',
                                                    backgroundColor: '#ff9800',
                                                    color: '#fff',
                                                    borderRadius: '0.25rem',
                                                    minWidth: '2rem',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem'
                                                }}>
                                                    {milestoneStats.notStarted}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.no_of_days_planned : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>No Of Days (Planned)</label>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="floating-label-group">
                                            <input
                                                type="text"
                                                value={projectData ? projectData.no_of_days_actual : ''}
                                                placeholder=" "
                                                readOnly
                                            />
                                            <label>No Of Days (Actual)</label>
                                        </div>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <ReactQuill
                                        ref={quillRef}
                                        value={projectData && projectData.description ? projectData.description : ''}
                                        readOnly
                                        theme="snow"
                                        style={{ height: '150px' }}
                                    />
                                </Form.Group>

                                <Row style={{ marginTop: '50px' }}>
                                    <Col md={6} className="d-flex justify-content-center">
                                        <div className="card shadow-sm w-100" style={{ height: '100%' }}>
                                            <div className="card-header bg-light border">
                                                <h5 style={{ fontWeight: "bold", marginBottom: 0 }}>Overall Progress</h5>
                                            </div>
                                            <div className="p-3 border bg-light text-center" style={{ height: '100%' }}>
                                                <div style={{ width: "100%", height: "100%", margin: "auto" }}>
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: "radialBar",
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    hollow: {
                                                                        size: "70%",
                                                                    },
                                                                    dataLabels: {
                                                                        name: {
                                                                            show: false,
                                                                        },
                                                                        value: {
                                                                            fontSize: "20px",
                                                                            color: "#3e98c7",
                                                                            offsetY: 5,
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                            colors: ["#3e98c7"],
                                                            labels: ["Progress"],
                                                        }}
                                                        series={[overallProgress]}
                                                        type="radialBar"
                                                        height={250}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6} className="d-flex justify-content-center">
                                        <div className="card shadow-sm w-100" style={{ height: '100%' }}>
                                            <div
                                                className="card-header bg-light border d-flex justify-content-between align-items-center">
                                                <h5 style={{ fontWeight: "bold", marginBottom: 0 }}>TEAM</h5>
                                            </div>
                                            <div
                                                className="card-body p-0"
                                                style={{
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    padding: "10px",
                                                    height: "100%",
                                                }}
                                            >
                                                <ul className="list-group list-group-flush">
                                                    {teamMembers.map((member, index) => (
                                                        <li
                                                            key={index}
                                                            className="list-group-item d-flex align-items-center"
                                                            style={{
                                                                padding: "8px 15px",
                                                                fontSize: "16px",
                                                                position: "relative"
                                                            }}
                                                        >
                                                                <span
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        width: '10px',
                                                                        height: '10px',
                                                                        backgroundColor: 'yellow',
                                                                        borderRadius: '50%',
                                                                        marginRight: '10px',
                                                                    }}
                                                                ></span>
                                                            {member}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <div className="card shadow-sm">
                        <div
                            className="card-header bg_light border bg-secondary text-white d-flex justify-content-between align-items-center"
                            style={{ backgroundColor: 'hsl(253deg 61% 55%)' }}
                        >
                            <Button variant="primary" onClick={() => handleShowMilestoneModal()}>Create New Milestone</Button>

                            <h5 className="m-0 text-center">Milestones & Tasks</h5>
                            <Button variant="primary" onClick={handleShowTaskModal}>Create New Task</Button>

                        </div>
                        <div className="card-body">
                            <Row className="mb-3">
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Search Milestones & Tasks</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            style={formControlStyle}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <DataTable
                                columns={columns}
                                data={milestones}
                                pagination
                                highlightOnHover
                                expandableRows
                                expandableRowExpanded={(row) => expandedMilestones[row.id]}
                                expandOnRowClicked={false}
                                expandableRowsComponent={expandableRowsComponent}
                                customStyles={milestoneTableStyles}

                            />


                        </div>
                    </div>
                </Col>
            </Row>

            <MilestoneModal
                show={showMilestoneModal}
                onHide={handleCloseMilestoneModal}
                handleSave={handleSaveMilestone}
                projectName={projectData.name}
                initialMilestoneState={initialMilestoneState}
            />
            <TaskModal
                show={showTaskModal}
                onHide={handleCloseTaskModal}
                handleSave={handleSaveTask}
                projectId={id}
                initialTaskState={initialTaskState}
            />

        </Container>
    );
};

export default ProjectDashboard;
