import React, { useState, useEffect } from 'react';
import businessCardImage from '../../images/sapphirewhite.png';

const BusinessCard = ({currentUser}) => {
    const [formData, setFormData] = useState({
        total_balance: 'PKR 16,250/-',
        remaining_balance: 'PKR 16,250/-',
        discount: '25%',
        max_limit: 'PKR 0.00/-'
    });

    const [cardData, setCardData] = useState(null);

    useEffect(() => {
        setCardData({
            ...formData
        });
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className="card-preview">
            <div className="image-container mt-2">
                <img src={businessCardImage} alt="Business Card" className="business-card-image" />
            </div>
            <h4 className="card-title mt-2">{currentUser.full_name}</h4>
            <p className="card-number">Card No. SGC-2023-297</p>

            <form className="form-container">
                <div className="form-grid mb-4">
                    <div>
                        <label className="form-label">
                            <span>Total Balance:</span>
                            <span>{formData.total_balance}</span>
                        </label>
                    </div>

                    <div>
                        <label className="form-label">
                            <span>Remaining Balance:</span>
                            <span>{formData.remaining_balance}</span>
                        </label>
                    </div>

                    <div>
                        <label className="form-label">
                            <span>Discount Price:</span>
                            <span>{formData.discount}</span>
                        </label>
                    </div>

                    <div>
                        <label className="form-label">
                            <span>Max Limit:</span>
                            <span>{formData.max_limit}</span>
                        </label>
                    </div>
                </div>
            </form>

            <style jsx>{`
              .card-preview {
                max-width: 90%;
                margin: 20px auto;
                padding: 20px;
                border: 2px solid #ddd;
                border-radius: 15px;
                color: white;
                position: relative;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
                background: #0b0b0b;
              }

              .image-container {
                text-align: center;
                margin-bottom: 20px;
              }

              .business-card-image {
                max-width: 100%;
                width: 50%;
              }

              .card-title, .card-number {
                color: white;
                text-align: center;
              }

              .card-title {
                font-weight: bold;
                padding-top: 15px;
              }

              .card-number {
                padding-bottom: 20px;
              }

              .form-container {
                display: flex;
                justify-content: center;
              }

              .form-grid {
                display: grid;
                grid-template-columns: 1fr;
                gap: 15px;
                width: 100%;
                max-width: 600px;
              }

              .form-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                color: black;
                border-radius: 10px;
                border: 1px solid #ccc;
                padding: 10px;
                background-color: white;
                font-size: 1rem;
                width: 100%;
              }

              .form-label span {
                font-size: 1rem;
              }

              .form-label span:last-child {
                font-weight: bold;
                font-size: 1.25rem;
              }

              @media (min-width: 600px) {
                .form-grid {
                  grid-template-columns: repeat(2, 1fr);
                }
              }

              @media (min-width: 768px) {
                .card-preview {
                  max-width: 50%;
                }

                .form-label span:last-child {
                  font-size: 1rem;
                }
              }
            `}</style>
        </div>
    );
};

export default BusinessCard;