    import React, { useState, useEffect } from 'react';
    import { Modal, Button, Form, Row, Col, Accordion, Card, useAccordionButton } from 'react-bootstrap';
    import ReactQuill from 'react-quill';
    import { FaTimes, FaPlus, FaMinus } from 'react-icons/fa';
    import Select from 'react-select';
    import axios from 'axios';

    const CustomToggle = ({ children, eventKey, onClick }) => {
        const decoratedOnClick = useAccordionButton(eventKey, onClick);

        return (
            <button
                type="button"
                onClick={decoratedOnClick}
                className="btn btn-primary"
            >
                {children}
            </button>
        );
    };

    const EmployeeCard = ({ employee, selectedEmployees, setSelectedEmployees }) => {
        const isSelected = selectedEmployees.some(e => e.value === employee.id);

        const handleCheckboxChange = () => {
            if (isSelected) {
                setSelectedEmployees(prev => prev.filter(emp => emp.value !== employee.id));
            } else {
                setSelectedEmployees(prev => [...prev, { value: employee.id, label: employee.name }]);
            }
        };

        return (
            <Col md={4} lg={3} className="mb-3 text-center">
                <div onClick={handleCheckboxChange} style={{ cursor: 'pointer' }}>
                    <img
                        src={employee.profile_image_url}
                        alt={employee.name}
                        className={`rounded-circle ${isSelected ? 'border border-primary' : ''}`}
                        style={{ width: '60px', height: '60px' }}
                    />
                    <p className="mt-2">{employee.name}</p>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleCheckboxChange}
                        style={{ display: 'block', margin: '0 auto' }}
                    />
                </div>
            </Col>
        );
    };

    const TaskModal = ({ show, onHide, projectId, handleSave, initialTaskState }) => {
        const [newTask, setNewTask] = useState({});
        const [milestones, setMilestones] = useState([]);
        const [employees, setEmployees] = useState([]);
        const [selectedEmployees, setSelectedEmployees] = useState([]);
        const [accordionOpen, setAccordionOpen] = useState(false);

        useEffect(() => {
            axios.get(`/projects/${projectId}/milestones`)
                .then(response => {
                    setMilestones(response.data.milestones.map(milestone => ({
                        value: milestone.id,
                        label: milestone.name
                    })));
                })
                .catch(error => {
                    console.error('Error fetching milestones:', error);
                });
        }, [projectId]);

        useEffect(() => {
            if (newTask.plannedStartDate && newTask.plannedDueDate) {
                const start = new Date(newTask.plannedStartDate);
                const end = new Date(newTask.plannedDueDate);
                const numOfDaysPlanned = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
                setNewTask(prevTask => ({ ...prevTask, numOfDaysPlanned }));
            }

            if (newTask.actualStartDate && newTask.actualDueDate) {
                const start = new Date(newTask.actualStartDate);
                const end = new Date(newTask.actualDueDate);
                const numOfDaysActual = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
                setNewTask(prevTask => ({ ...prevTask, numOfDaysActual }));
            }
        }, [newTask.plannedStartDate, newTask.plannedDueDate, newTask.actualStartDate, newTask.actualDueDate]);

        const fetchEmployees = () => {
            axios.get(`/projects/fetch_all_projects`, { params: { id: projectId } })
                .then(response => {
                    if (response.data.project && response.data.project.employees) {
                        setEmployees(response.data.project.employees);
                    } else {
                        console.error('No employees found for the project.');
                    }
                })
                .catch(error => {
                    console.error('Error fetching employees:', error);
                });
        };
        const handleModalClose = () => {
            setNewTask(initialTaskState); // Reset to initial state
            setSelectedEmployees([]); // Reset selected employees
            onHide();
        };

        const handleSaveTask = () => {
            const taskData = {
                ...newTask,
                selectedEmployees,
            };
            handleSave(taskData);
            setNewTask(initialTaskState); // Reset task data after saving
            setSelectedEmployees([]); // Reset selected employees
        };

        const formConfig = [
            { name: 'milestone', type: 'select', options: milestones, placeholder: 'Select a Milestone *', required: true },
            { name: 'taskType', type: 'select', options: [
                    { value: 'Bug', label: 'Bug' },
                    { value: 'Development', label: 'Development' },
                    { value: 'Update', label: 'Update' },
                    { value: 'Change Request', label: 'Change Request' },
                    { value: 'Data Extraction', label: 'Data Extraction' },
                    { value: 'Oracle SR', label: 'Oracle SR' },
                    { value: 'Support', label: 'Support' },
                    { value: 'Access Request', label: 'Access Request' },
                ], placeholder: 'Select Task Type *', required: true },
            { name: 'taskName', label: 'Task Name*', type: 'text', placeholder: 'Enter task name', required: true },
            { name: 'description', label: 'Description', type: 'rich-text', placeholder: 'Enter task description', required: false },
            { name: 'plannedStartDate', label: 'Planned Start Date *', type: 'date', required: true },
            { name: 'plannedDueDate', label: 'Planned Due Date *', type: 'date', required: true },
            { name: 'actualStartDate', label: 'Actual Start Date', type: 'date', required: false, readOnly: true },
            { name: 'actualDueDate', label: 'Actual Due Date', type: 'date', required: false, readOnly: true },
            { name: 'numOfDaysPlanned', label: 'Num of Days (Planned)', type: 'text', placeholder: 'Planned Start - End date', required: false, readOnly: true },
            { name: 'numOfDaysActual', label: 'Num of Days (Actual)', type: 'text', placeholder: 'Actual Start - End date', required: false, readOnly: true },
        ];

        const handleChange = (name, value) => {
            setNewTask({ ...newTask, [name]: value });
        };

        const renderField = (field) => {
            const commonStyle = {
                fontSize: '0.8rem',
                backgroundColor: '#fff'
            };

            if (field.type === 'rich-text') {
                return (
                    <div className="floating-label-group">
                        <ReactQuill
                            value={newTask[field.name] || ''}
                            onChange={(value) => handleChange(field.name, value)}
                            theme="snow"
                            placeholder={field.placeholder || ''}
                            style={{ ...commonStyle, height: '150px' }}
                        />
                    </div>
                );
            } else if (field.type === 'select') {
                return (
                    <div className="floating-label-group">
                        <Select
                            value={newTask[field.name]}
                            onChange={(selectedOption) => handleChange(field.name, selectedOption)}
                            options={field.options}
                            placeholder={field.placeholder}
                            isClearable
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    fontSize: '0.8rem',
                                    height: '38px',
                                }),
                            }}
                        />
                        <label style={commonStyle}>{field.label}</label>
                    </div>
                );
            } else {
                return (
                    <div className="floating-label-group">
                        <Form.Control
                            type={field.type}
                            name={field.name}
                            value={newTask[field.name] || ''}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            required={field.required}
                            placeholder=" "
                            style={commonStyle}
                            readOnly={field.readOnly || false}
                        />
                        <label style={commonStyle}>{field.label}</label>
                    </div>
                );
            }
        };

        const handleAccordionToggle = () => {
            setAccordionOpen(!accordionOpen);
            if (!accordionOpen) {
                fetchEmployees();
            }
        };

        return (
            <Modal show={show} onHide={onHide} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Create New Task</Modal.Title>
                </Modal.Header>
                <Button variant="link" onClick={onHide} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <FaTimes />
                </Button>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            {formConfig.slice(0, 2).map((field, idx) => (
                                <Col key={idx} md={6}>
                                    <Form.Group>
                                        {renderField(field)}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group>
                                    {renderField(formConfig[2])}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Group>
                                    {renderField(formConfig[3])}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3 mt-5">
                            {formConfig.slice(4, 6).map((field, idx) => (
                                <Col key={idx} md={6}>
                                    <Form.Group>
                                        {renderField(field)}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mb-3">
                            {formConfig.slice(6).map((field, idx) => (
                                <Col key={idx} md={6}>
                                    <Form.Group>
                                        {renderField(field)}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Form>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <CustomToggle eventKey="0" onClick={handleAccordionToggle}>
                                    Team {accordionOpen ? <FaMinus /> : <FaPlus />}
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row>
                                        {employees.length > 0 ? (
                                            employees.map(employee => (
                                                <EmployeeCard
                                                    key={employee.id}
                                                    employee={employee}
                                                    selectedEmployees={selectedEmployees}
                                                    setSelectedEmployees={setSelectedEmployees}
                                                />
                                            ))
                                        ) : (
                                            <p>No employees found.</p>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Cancel</Button>
                    <Button variant="primary" onClick={handleSaveTask}>Save Task</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    export default TaskModal;
