import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';

const PanelContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const CustomButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${props => (props.selected ? '#ddd !important' : '#ffffff !important')};
  color: ${props => (props.selected ? '#000 !important' : '#000 !important')};
  border: 1px solid ${props => (props.selected ? '#ddd !important' : '#ddd !important')};
  margin-right: 10px;
  &:hover {
    background-color: ${props => (props.selected ? '#ddd !important' : '#f0f0f0 !important')};
  }
`;

const ColorPalette = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ColorSwatchBase = ({ isSelected, ...props }) => <div {...props} />;

const ColorSwatch = styled(ColorSwatchBase)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
  cursor: pointer;
  border: 2px solid ${props => (props.isSelected ? '#007bff' : 'transparent')};
`;

const FormGroup = styled(Form.Group)`
  margin-top: 20px;
`;

const CustomizationPanel = ({ collections, onUpdateCustomization, onUploadProductImage, onRemoveCustomization, embroideryColor, setEmbroideryColor, currentView, currentCustomization }) => {
    const [selectedCollection, setSelectedCollection] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [products, setProducts] = useState([]);
    const [colorSet, setColorSet] = useState([]);
    const [designSet, setDesignSet] = useState([]);
    const [isBrowsingDesigns, setIsBrowsingDesigns] = useState(true);

    useEffect(() => {
        if (selectedCollection) {
            axios.get(`/sapphire_x_you/${selectedCollection}/collection_products`)
                .then(response => {
                    const data = response.data;
                    setProducts(data.products || []);
                })
                .catch(error => {
                    console.error("Error fetching related sets:", error);
                });

            axios.get(`/sapphire_x_you/${selectedCollection}/related_sets`)
                .then(response => {
                    const data = response.data;
                    setColorSet(data.color_sets.map(set => set.colors).flat() || []);
                    setDesignSet(data.design_sets.map(set => set.designs).flat() || []);
                })
                .catch(error => {
                    console.error("Error fetching related sets:", error);
                });
        }
    }, [selectedCollection]);


    const handleCollectionChange = (e) => {
        setSelectedCollection(e.target.value);
        setSelectedProduct(''); // Reset selected product when collection changes
    };

    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
        const product = products.find(p => p.id === parseInt(e.target.value));
        if (product) {
            onUploadProductImage(product.front_image_url, product.back_image_url);
        }
    };

    const handleEmbroideryColorChange = (color) => {
        if (embroideryColor === color) {
            setEmbroideryColor('');
        } else {
            setEmbroideryColor(color);
        }
    };

    const handleEmbroideryDesignClick = (design) => {
        onUpdateCustomization({
            content: { type: 'image', src: design.design_image_url, price_change: design.price_change, size: design.size, design_name: design.design_name },
            style: { left: 250, top: 250, scaleX: 0.5, scaleY: 0.5 }
        });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                onUpdateCustomization({
                    content: { type: 'image', src: event.target.result, price_change: 0 },
                    style: { left: 250, top: 250, scaleX: 0.5, scaleY: 0.5 }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <PanelContainer>
            <FormGroup controlId="formCollectionSelect">
                <Form.Label>Select Collection</Form.Label>
                <Form.Control as="select" value={selectedCollection} onChange={handleCollectionChange}>
                    <option value="">Select Collection</option>
                    {collections.map(collection => (
                        <option key={collection.id} value={collection.id}>{collection.name}</option>
                    ))}
                </Form.Control>
            </FormGroup>
            <FormGroup controlId="formProductSelect">
                <Form.Label>Select Product</Form.Label>
                <Form.Control as="select" value={selectedProduct} onChange={handleProductChange}>
                    <option value="">Select Product</option>
                    {products.map(product => (
                        <option key={product.id} value={product.id}>{product.name}</option>
                    ))}
                </Form.Control>
            </FormGroup>
            <FormGroup controlId="formEmbroideryColorPicker">
                <Form.Label>Embroidery Color</Form.Label>
                <ColorPalette>
                    {colorSet.map(color => (
                        <ColorSwatch
                            key={color.id}
                            color={color.color}
                            isSelected={color.color === embroideryColor}
                            onClick={() => handleEmbroideryColorChange(color.color)}
                        />
                    ))}
                </ColorPalette>
            </FormGroup>
            <FormGroup>
                <Form.Label>Design</Form.Label>
                <div>
                    <CustomButton selected={isBrowsingDesigns} onClick={() => setIsBrowsingDesigns(true)}>Browse Designs</CustomButton>
                    <CustomButton selected={!isBrowsingDesigns} onClick={() => setIsBrowsingDesigns(false)}>Upload Design</CustomButton>
                </div>
                {isBrowsingDesigns ? (
                    <div>
                        {designSet.map(design => (
                            <img
                                key={design.id}
                                src={design.design_image_url}
                                alt={design.design_name}
                                style={{ width: 50, height: 50, margin: '5px', cursor: 'pointer' }}
                                onClick={() => handleEmbroideryDesignClick(design)}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="custom-file mt-3">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customDesignUpload"
                            onChange={handleFileUpload}
                        />
                        <label className="custom-file-label" htmlFor="customDesignUpload">Choose file</label>
                    </div>
                )}
            </FormGroup>
            <div className="mt-5">
                <button className="btn btn-secondary" onClick={() => onRemoveCustomization('image')}>Remove Embroidery</button>
            </div>
        </PanelContainer>
    );
};

export default CustomizationPanel;
