import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Sidebar from "./Layout/Sidebar";
import TopNavBar from "./Layout/TopNavBar";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #f5f5f5;
`;

const PanelContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background: #f8f9fa;
  }
`;

const ActionButton = styled.button`
  background-color: rgba(48, 48, 48, 1);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
`;

const DesignSetPanel = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const AddRowButton = styled.button`
  background-color: rgba(48, 48, 48, 1);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
`;

const DesignPage = () => {
    const [creatingDesignSet, setCreatingDesignSet] = useState(false);
    const [designSetRows, setDesignSetRows] = useState([{ designName: '', size: '', priceChange: '0', designImage: null, designImagePreview: null }]);
    const [designSets, setDesignSets] = useState([]);
    const [designSetName, setDesignSetName] = useState("");
    const [editingDesignSet, setEditingDesignSet] = useState(null);

    useEffect(() => {
        fetchDesignSets();
    }, []);

    const fetchDesignSets = () => {
        axios.get('/sapphire_x_you/design_sets')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setDesignSets(response.data);
                } else {
                    setDesignSets([]);
                    console.error('Design sets response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the design sets!', error);
            });
    };

    const addRow = () => {
        setDesignSetRows([...designSetRows, { designName: '', size: '', priceChange: '0', designImage: null, designImagePreview: null }]);
    };

    const removeRow = (index) => {
        const newRows = designSetRows.filter((row, i) => i !== index);
        setDesignSetRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...designSetRows];
        newRows[index][field] = value;
        setDesignSetRows(newRows);
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        const newRows = [...designSetRows];
        newRows[index].designImage = file;
        newRows[index].designImagePreview = URL.createObjectURL(file);
        setDesignSetRows(newRows);
    };

    const handleCreateDesignSet = () => {
        setCreatingDesignSet(true);
        setEditingDesignSet(null);
    };

    const handleEditDesignSet = (designSet) => {
        setDesignSetName(designSet.name);
        axios.get(`/sapphire_x_you/${designSet.id}/show_design_set`)
            .then(response => {
                setDesignSetRows(response.data.design_set_designs.map(designSetDesign => ({
                    id: designSetDesign.id,
                    designName: designSetDesign.design_name,
                    size: designSetDesign.size,
                    priceChange: designSetDesign.price_change,
                    designImagePreview: designSetDesign.design_image_url,
                    designImage: null
                })));
                setEditingDesignSet(designSet.id);
                setCreatingDesignSet(true);
            })
            .catch(error => {
                console.error('There was an error fetching the design set!', error);
            });
    };

    const handleCancelCreateDesignSet = () => {
        setCreatingDesignSet(false);
        setDesignSetRows([{ designName: '', size: '', priceChange: '0', designImage: null, designImagePreview: null }]);
        setDesignSetName("");
    };

    const handleSubmitDesignSet = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('design_set[name]', designSetName);
        designSetRows.forEach((row, index) => {
            if (row.id) {
                formData.append(`design_set[design_set_designs_attributes][${index}][id]`, row.id);
            }
            formData.append(`design_set[design_set_designs_attributes][${index}][design_name]`, row.designName);
            formData.append(`design_set[design_set_designs_attributes][${index}][size]`, row.size);
            formData.append(`design_set[design_set_designs_attributes][${index}][price_change]`, row.priceChange);
            if (row.designImage) {
                formData.append(`design_set[design_set_designs_attributes][${index}][design_image]`, row.designImage);
            }
        });

        const url = editingDesignSet ? `/sapphire_x_you/${editingDesignSet}/update_design_set` : '/sapphire_x_you/create_design_set';
        const method = editingDesignSet ? 'put' : 'post';

        axios({
            method: method,
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                alert(`Design set ${editingDesignSet ? 'updated' : 'created'} successfully`);
                setCreatingDesignSet(false);
                fetchDesignSets();
            })
            .catch(error => {
                alert(`Failed to ${editingDesignSet ? 'update' : 'create'} design set`);
                console.error('There was an error submitting the design set!', error);
            });
    };

    return (
        <PageContainer>
            <TopNavBar />
            <MainContent>
                <Sidebar />
                <ContentArea>
                    {!creatingDesignSet ? (
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ActionButton onClick={handleCreateDesignSet}><FontAwesomeIcon icon={faPlus} /> Create design set</ActionButton>
                            </div>
                            <PanelContainer>
                                <SearchBar type="text" placeholder="Filter search" />
                                <TableContainer>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Set title</th>
                                            <th>Designs</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {designSets.map(designSet => (
                                            <tr key={designSet.id}>
                                                <td>{designSet.name}</td>
                                                <td>{designSet.designs_count}</td>
                                                <td>
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEditDesignSet(designSet)} style={{ cursor: 'pointer' }} />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            </PanelContainer>
                        </React.Fragment>
                    ) : (
                        <DesignSetPanel>
                            <form onSubmit={handleSubmitDesignSet}>
                                <div style={{ marginBottom: '20px' }}>
                                    <label>Design Set Name:</label>
                                    <input
                                        type="text"
                                        value={designSetName}
                                        onChange={(e) => setDesignSetName(e.target.value)}
                                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                    />
                                </div>
                                {designSetRows.map((row, index) => (
                                    <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Design Name:</label>
                                            <input
                                                type="text"
                                                value={row.designName}
                                                onChange={(e) => handleInputChange(index, 'designName', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Size:</label>
                                            <input
                                                type="text"
                                                value={row.size}
                                                onChange={(e) => handleInputChange(index, 'size', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Price Change:</label>
                                            <input
                                                type="text"
                                                value={row.priceChange}
                                                onChange={(e) => handleInputChange(index, 'priceChange', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Image:</label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(index, e)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                            {row.designImagePreview && (
                                                <img
                                                    src={row.designImagePreview}
                                                    alt="Design Preview"
                                                    style={{ width: '40px', height: '40px', marginTop: '-44px',float: "right" }}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => removeRow(index)}
                                                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} color="#dc3545" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <AddRowButton type="button" onClick={addRow}>+ Add Row</AddRowButton>
                                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        type="button"
                                        onClick={handleCancelCreateDesignSet}
                                        style={{ backgroundColor: '#6c757d', padding: '10px 20px', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </DesignSetPanel>
                    )}
                </ContentArea>
            </MainContent>
        </PageContainer>
    );
};

export default DesignPage;

