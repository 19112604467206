import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../images/03.png'
const ErrorPage = () => {
    return (
        <div className="container-fluid p-0">
            <div className="row no-gutters">
                <div className="col-sm-12 text-center">
                    <div className="iq-error error-500">
                        <img src={image} className="img-fluid iq-error-img" alt="Error 500" />
                        <h2 className="mb-0">Oops! This Page is Not Working.</h2>
                        <p>The requested page is an Internal Server Error.</p>
                        <Link className="btn btn-primary mt-3" to="/dashboard">
                            <i className="ri-home-4-line"></i>Back to Home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
