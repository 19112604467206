import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MenuFormModal from './MenuModal';

const MenuManagement = () => {
    const [menus, setMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [menuToDelete, setMenuToDelete] = useState(null);

    useEffect(() => {
        fetchMenus();
    }, []);

    const fetchMenus = async () => {
        try {
            const response = await axios.get('/menus.json');
            setMenus(response.data);
        } catch (error) {
            console.error('Error fetching menus:', error);
        }
    };

    const handleAddMenu = () => {
        setSelectedMenu(null);
        setAddModalOpen(true);
    };

    const handleEditMenu = (menu) => {
        setSelectedMenu(menu);
        setAddModalOpen(true);
    };

    const handleDeleteMenu = (menuId) => {
        setMenuToDelete(menuId);
        setDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setAddModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedMenu(null);
    };

    const handleSubmit = async (formData) => {
        try {
            if (selectedMenu) {
                await axios.put(`/update_menu/${selectedMenu.id}`, formData);
            } else {
                await axios.post('/menus.json', formData);
            }
            fetchMenus();
            handleCloseModal();
        } catch (error) {
            console.error('Error submitting menu data:', error);
        }
    };

    const confirmDeleteMenu = async () => {
        try {
            await axios.post(`/delete_menu/${menuToDelete}`);
            fetchMenus();
            setDeleteModalOpen(false);
            setMenuToDelete(null);
        } catch (error) {
            console.error('Error deleting menu:', error);
        }
    };

    const menuToDeleteData = menus.find(menu => menu.id === menuToDelete);

    return (
        <div>
            <div className="col-lg-12">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-header d-flex justify-content-between">
                        <button className="btn btn-primary mr-2" onClick={handleAddMenu}>
                            <i className="ri-add-fill"><span className="pl-1">Add New</span></i>
                        </button>
                        <div className="iq-header-title">
                            <h4 className="card-title">Menus</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="table-responsive">
                            <table className="table mb-0 mt-2 table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" className="font-weight-bold">Menu Name</th>
                                    <th scope="col" className="font-weight-bold">Menu Type</th>
                                    <th scope="col" className="font-weight-bold">Main Menu Name</th>
                                    <th scope="col" className="font-weight-bold">Is Active?</th>
                                    <th scope="col" className="font-weight-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {menus.map((menu, index) => (
                                    <tr key={menu.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <h6>{menu.name}</h6>
                                        </td>
                                        <td>
                                            <p>{menu.menu_type.replace('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                                        </td>
                                        <td>
                                            {menu.main_menu_id !== undefined && menu.main_menu_id !== null ? (
                                                menus.find(m => m.id === menu.main_menu_id) ? (
                                                    <span className="badge badge-info">{menus.find(m => m.id === menu.main_menu_id).name}</span>
                                                ) : (
                                                    <span className="badge badge-warning">Self Main Menu</span>
                                                )
                                            ) : (
                                                <span className="badge badge-warning">Self Main Menu</span>
                                            )}
                                        </td>

                                        <td>
                                            <span className={`badge rounded-pill ${menu.is_active ? 'badge-info' : 'badge-danger'}`}>
                                                {menu.is_active ? 'Active' : 'In Active'}
                                            </span>
                                        </td>
                                        <td>
                                            <a onClick={() => handleEditMenu(menu)} className="animate">
                                                <i className="ri-ball-pen-fill text-success pr-1 font-size-18"></i>
                                            </a>
                                            <a onClick={() => handleDeleteMenu(menu.id)} className="animate">
                                                <i className="ri-delete-bin-5-line text-danger font-size-18"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <MenuFormModal
                isOpen={isAddModalOpen}
                onClose={handleCloseModal}
                menu={selectedMenu}
                menus={menus}
                onMenuAdded={fetchMenus}
            />

            {/* Confirmation Modal */}
            <div className={`modal fade ${isDeleteModalOpen ? 'show d-block' : ''}`} id="confirmModal" tabIndex="-1" aria-labelledby="confirmModalLabel" aria-hidden={!isDeleteModalOpen}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete menu: {menuToDeleteData ? menuToDeleteData.name : ''}?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal}>Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={confirmDeleteMenu}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuManagement;
