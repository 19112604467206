import React, { useState } from 'react';

const operations = {
    "Hem Alteration": 10,
    "Garment Fitting": 15,
    "Length Adjustment": 12,
    "Sleeve Length short": 8
};

const products = [
    "PRET",
    "UN STITCH SUIT",
    "SCARF",
    "DUPATTA",
    "SHIRT",
    "TROUSER",
    "MEN’S SUIT",
    "HOME BEDDING",
    "CUSHION",
    "WEST PRODUCT",
    "KIDS PRODUCT",
    "OTHERS"
];

const FormComponent = () => {
    const [selectedOperation, setSelectedOperation] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [price, setPrice] = useState(null);

    const handleOperationChange = (event) => {
        setSelectedOperation(event.target.value);
        setPrice(operations[event.target.value]);
    };

    const handleProductChange = (event) => {
        setSelectedProduct(event.target.value);
    };

    const handleSave = () => {
        alert(`Product: ${selectedProduct}, Operation: ${selectedOperation}, Price: ${price}`);
    };

    const containerStyle = {
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
        marginTop: '50px'
    };

    const headingStyle = {
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px'
    };

    const formGroupStyle = {
        marginBottom: '1.5rem'
    };

    const labelStyle = {
        fontWeight: 'bold',
        color: '#555'
    };

    const formControlStyle = {
        borderRadius: '5px',
        border: '1px solid #ddd',
        padding: '10px',
        fontSize: '1rem',
        width: '100%'
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '1rem',
        borderRadius: '5px',
        backgroundColor: '#007bff',
        border: 'none',
        color: 'white',
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        marginTop: '20px'
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3'
    };

    const priceDisplayStyle = {
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '1.2rem',
        color: '#007bff'
    };

    return (
        <div style={containerStyle}>
            <h2 style={headingStyle}>Select Operation and Product</h2>
            <form>
                <div className="form-group" style={formGroupStyle}>
                    <label htmlFor="product" style={labelStyle}>Product:</label>
                    <select
                        id="product"
                        style={formControlStyle}
                        value={selectedProduct}
                        onChange={handleProductChange}
                    >
                        <option value="" disabled>Select a product</option>
                        {products.map(product => (
                            <option key={product} value={product}>{product}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group" style={formGroupStyle}>
                    <label htmlFor="operation" style={labelStyle}>Operation:</label>
                    <select
                        id="operation"
                        style={formControlStyle}
                        value={selectedOperation}
                        onChange={handleOperationChange}
                    >
                        <option value="" disabled>Select an operation</option>
                        {Object.keys(operations).map(operation => (
                            <option key={operation} value={operation}>{operation}</option>
                        ))}
                    </select>
                </div>
                {price !== null && (
                    <div style={priceDisplayStyle}>
                        <h4>Price: ${price}</h4>
                    </div>
                )}
                <button
                    type="button"
                    style={{ ...buttonStyle, ...(buttonStyle[':hover'] ? buttonHoverStyle : {}) }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                    onClick={handleSave}
                >
                    Save
                </button>
            </form>
        </div>
    );
};

export default FormComponent;
