import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Karachi'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

export const getPendingColumns = (handleAssignTaskClick, handleDeleteClick, handleReassignClick) => [
    {
        name: 'SR #',
        cell: row => <div>{row.ticket_number}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Type',
        cell: row =>
            <div>{row.task_type || (row.ticket_type && row.ticket_type.name ? row.ticket_type.name : 'N/A')}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Title',
        cell: row => <div style={{ fontWeight: 'bold', color: '#4973AB' }}>{row.request_title}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'SR Time',
        cell: row => <div>{formatDate(row.created_at)}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Location',
        cell: row =>
            <div>{row.reporter_location_name || 'N/A'}</div>
        ,
        sortable: true,
        wrap: true
    },
    {
        name: 'Requester',
        cell: row => <div style={{ fontWeight: 'bold', color: '#4973AB' }}>{row.reporter}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Assignee',
        cell: row => (
            <div>
                {row.assignees ? row.assignees.split(', ').map((name, index) => (
                    <div key={index}>{name}</div>
                )) : row.assignee}
            </div>
        ),
        sortable: true,
        wrap: true
    },
    {
        name: 'Status',
        cell: row => <div>{row.status}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Action',
        cell: (row) => (
            <div style={{ textAlign: 'center' }}>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-assign-task`}>Create Task</Tooltip>}
                >
                    <i className="ri-window-line" style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
                       onClick={() => handleAssignTaskClick(row)}></i>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-delete-sr`}>Cancel SR</Tooltip>}
                >
                    <i className="ri-close-circle-line"
                       style={{ fontSize: '20px', color: 'red', marginLeft: '7px', cursor: 'pointer' }}
                       onClick={() => handleDeleteClick(row)}></i>
                </OverlayTrigger>
            </div>
        ),
        ignoreRowClick: true,
        wrap: true,
    }
];

export const getGeneratedColumns = (handleIconClick) => [
    {
        name: 'Task #',
        cell: row => <div>{row.task_no || row.ticket_number || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Type',
        cell: row =>
            <div>{row.task_type || (row.ticket_type && row.ticket_type.name ? row.ticket_type.name : 'N/A')}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Title',
        cell: row => <div style={{ fontWeight: 'bold' }}>{row.request_title || row.task_name || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'SR Time',
        cell: row => <div>{formatDate(row.created_at || row.planned_start_date)}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Location',
        cell: row =>
            <div>{row.reporter_location_name || 'N/A'}</div>
        ,
        sortable: true,
        wrap: true
    },
    {
        name: 'Requester',
        cell: row => <div style={{ fontWeight: 'bold' }}>{row.reporter || row.created_by || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },

    {
        name: 'Assignee',
        cell: row => (
            <div>
                {row.request_assignments && row.request_assignments.length > 0 ? (
                    row.request_assignments.map((assignment, index) => (
                        <div key={index}>{assignment.employee.name}</div>
                    ))
                ) : row.assignees && Array.isArray(row.assignees) ? (
                    row.assignees.map((assignee, index) => (
                        <div key={index}>{assignee.name}</div>
                    ))
                ) : 'N/A'}
            </div>
        ),
        sortable: true,
        wrap: true
    },
    {
        name: 'Status',
        cell: row => <div>{row.status || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Action',
        cell: (row) => (
            <div style={{ textAlign: 'center' }}>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-view-task`}>View Task</Tooltip>}
                >
                    <i className="ri-eye-line" style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
                       onClick={() => handleIconClick(row)}></i>
                </OverlayTrigger>
            </div>
        ),
        ignoreRowClick: true,
        wrap: true,
    }
];

export const getCompletedColumns = (handleIconClick) => [
    {
        name: 'SR #',
        cell: row => <div>{row.task_no || row.ticket_number || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Type',
        cell: row =>
            <div>{row.task_type || (row.ticket_type && row.ticket_type.name ? row.ticket_type.name : 'N/A')}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Task Title',
        cell: row => <div style={{ fontWeight: 'bold' }}>{row.request_title || row.task_name || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'SR Time',
        cell: row => <div>{formatDate(row.created_at || row.planned_start_date)}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Location',
        cell: row =>
            <div>{row.reporter_location_name || 'N/A'}</div>
        ,
        sortable: true,
        wrap: true
    },
    {
        name: 'Requester',
        cell: row => <div style={{ fontWeight: 'bold' }}>{row.reporter || row.created_by || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Assignee',
        cell: row => (
            <div>
                {row.request_assignments && row.request_assignments.length > 0 ? (
                    row.request_assignments.map((assignment, index) => (
                        <div key={index}>{assignment.employee.name}</div>
                    ))
                ) : row.assignees && Array.isArray(row.assignees) ? (
                    row.assignees.map((assignee, index) => (
                        <div key={index}>{assignee.name}</div>
                    ))
                ) : 'N/A'}
            </div>
        ),
        sortable: true,
        wrap: true
    },
    {
        name: 'Status',
        cell: row => <div>{row.status || 'N/A'}</div>,
        sortable: true,
        wrap: true
    },
    {
        name: 'Action',
        cell: (row) => (
            <div style={{ textAlign: 'center' }}>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-view-task`}>View Task</Tooltip>}
                >
                    <i className="ri-eye-line" style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
                       onClick={() => handleIconClick(row)}></i>
                </OverlayTrigger>
            </div>
        ),
        ignoreRowClick: true,
        wrap: true,
    }
];
