import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import alteration from '../images/Alteration.webp';
import productRepairing from '../images/ProductRepairing.webp';
import monogramming from '../images/Monogrammimg.webp';
import embroidery from '../images/Embroidery.webp';

const Services = () => {
    const navigate = useNavigate();

    const handleEmbroideryClick = () => {
        navigate('/sapphire_x_you/embroidery');
    };

    const handleAlterationClick = () => {
        navigate('/sapphire_x_you/alteration');
    };

    const handleMonogrammingClick = () => {
        navigate('/sapphire_x_you/monogramming');
    };

    const handleSetupClick = () => {
        navigate('/sapphire_x_you/asset');
    };

    return (
        <div className="services-container">
            <div className="services-header">
                <h3 className="mb-3">SERVICES OFFER: <FontAwesomeIcon
                    icon={faTools}
                    className="tools-icon"
                    onClick={handleSetupClick}
                    style={{ cursor: 'pointer', marginLeft: '20px' }}
                /></h3>
            </div>
            <div className="services-grid">
                <div className="service-box" onClick={handleAlterationClick}>
                    <div className="service-box-image-wrapper">
                        <img className="service-box-blur" src={alteration} alt="Alteration"/>
                        <div className="service-box-image-overlay"></div>
                    </div>
                    <span className="text">ALTERATION</span>
                </div>
                <div className="service-box">
                    <div className="service-box-image-wrapper">
                        <img className="service-box-blur" src={productRepairing} alt="Product Repairing"/>
                        <div className="service-box-image-overlay"></div>
                    </div>
                    <span className="text">PRODUCT REPAIRING</span>
                </div>
                <div className="service-box" onClick={handleMonogrammingClick}>
                    <div className="service-box-image-wrapper">
                        <img className="service-box-blur" src={monogramming} alt="Monogramming"/>
                        <div className="service-box-image-overlay"></div>
                    </div>
                    <span className="text">MONOGRAMMING</span>
                </div>
                <div className="service-box" onClick={handleEmbroideryClick}>
                    <div className="service-box-image-wrapper">
                        <img className="service-box-blur" src={embroidery} alt="Embroidery"/>
                        <div className="service-box-image-overlay"></div>
                    </div>
                    <span className="text">EMBROIDERY</span>
                </div>
            </div>
        </div>
    );
};

export default Services;
