import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar, faEye, faTimes, faFileExcel} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

const Tickets = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [tickets, setTickets] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('Completed');
    const [selectedRating, setSelectedRating] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [searchTerm, setSearchTerm] = useState(""); // Add state for search term
    const handleCloseConfirmModal = () => {
        setSearchTerm('');
        setShowModal(false);
    };
    useEffect(() => {
        fetchTickets();
        fetchDepartments();
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [selectedDepartment]);

    const fetchTickets = async () => {
        try {
            const departmentId = selectedDepartment ? selectedDepartment.value : null;
            const response = await axios.get("/service_requests/fetch_dashboard_tickets", {
                params: { department_id: departmentId }
            });
            setTickets(response.data);
        } catch (error) {
            toast.error("Error fetching tickets");
        }
    };
    const handleDownloadExcel = async () => {
        try {
            const response = await axios.get('/sr_dashboards/download_excel', {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'service_requests.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            toast.error('Error downloading Excel');
        }
    };
    const fetchDepartments = async () => {
        try {
            const response = await axios.get("/departments/fetch_dept");
            setDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const commonColumns = [
        {
            name: 'Location',
            selector: row => row.location.name,
            sortable: true,
            wrap: true,
            minWidth: '120px'
        },
        {
            name: 'SR No.',
            selector: row => row.ticket_number,
            sortable: true,
            wrap: true,
            minWidth: '120px'
        },
        {
            name: 'Dept',
            selector: row => row.reporter_department,
            sortable: true,
            wrap: true,
            minWidth: '120px'
        },
        {
            name: 'Title',
            selector: row => row.request_title,
            sortable: true,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Created By',
            selector: row => row.reporter,
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },
        {
            name: 'Assignee',
            selector: row => {
                const assignees = row.request_assignments.map(assignment => assignment.employee.name).join(', ');
                return assignees || 'Unassigned';
            },
            sortable: true,
            wrap: true,
            minWidth: '180px'
        },
        {
            name: 'SR Type',
            selector: row => row.ticket_type ? row.ticket_type.name : 'N/A',
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },
        {
            name: 'Created Date',
            selector: row => {
                const date = new Date(row.created_at);
                return `${date.toLocaleDateString()} at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
            },
            sortable: true,
            wrap: true,
            minWidth: '180px'
        },
        {
            name: 'On Behalf Of',
            selector: row => row.on_behalf_of_employee,
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },
        {
            name: 'Rating',
            selector: row => row.rating || 0,
            cell: row => (
                <div>
                    {[...Array(5)].map((_, i) => (
                        <FontAwesomeIcon
                            key={i}
                            icon={faStar}
                            color={i < row.rating ? 'gold' : 'grey'}
                        />
                    ))}
                </div>
            ),
            sortable: true,
            minWidth: '100px'
        }
    ];
    const updatedAtColumn = {
        name: 'Completed At',
        selector: row => {
            const updatedDate = new Date(row.updated_at);
            return `${updatedDate.toLocaleDateString()} at ${updatedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        },
        sortable: true,
        wrap: true,
        minWidth: '180px'
    };
    const actionsColumn = {
        name: 'View',
        cell: row => (
            <FontAwesomeIcon
                icon={faEye}
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => navigate(`/service_requests/${row.id}/details`)}
            />
        ),
    };

    const columns = (selectedStatus === 'Not Started' || selectedStatus === 'Inprocess' || selectedStatus === 'Unassign'
        || selectedStatus === 'Overdue' || selectedStatus === 'Cancelled' || selectedStatus === 'Waiting for Purchase'
        || selectedStatus === 'Waiting for Approval')
        ? [actionsColumn, ...commonColumns]
        : selectedStatus === 'Completed'
            ? [actionsColumn,
                ...commonColumns.slice(0, 7),
                commonColumns[7],
                updatedAtColumn,
                ...commonColumns.slice(8)
            ]
            : commonColumns;



    const cardStyles = {
        border: 'none',
        padding: '20px',
        flex: 1,
        textAlign: 'center',
        fontSize: '16px',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '2px 4px 6px rgba(0.3, 0.3, 0.3, 0.3)',
        opacity: 0.5,
        transform: 'translateX(50px)',
        transition: 'opacity 1.5s ease, transform 0.5s ease',
    };

    const vibrateAnimation = {
        animation: 'vibrate 0.5s linear'
    };

    const onCardHover = (e, backgroundColor) => {
        e.currentTarget.style.backgroundColor = backgroundColor;
        e.currentTarget.style.boxShadow = '0px 8px 12px rgba(0, 0, 0, 0.5)';
        Object.assign(e.currentTarget.style, vibrateAnimation);
    };

    const onCardLeave = (e) => {
        e.currentTarget.style.backgroundColor = '';
        e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
        e.currentTarget.style.animation = '';
    };

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        const keyframes = `
            @keyframes vibrate {
                0% { transform: translateX(0); }
                20% { transform: translateX(-15px); }
                40% { transform: translateX(2px); }
                60% { transform: translateX(-2px); }
                80% { transform: translateX(2px); }
                100% { transform: translateX(0); }
            }
        `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

        // Startup animation
        const cards = document.querySelectorAll('.iq-card');
        cards.forEach((card, index) => {
            card.style.transitionDelay = `${index * 0.1}s`;
            card.style.opacity = 1;
            card.style.transform = 'translateY(0)';
        });
    }, []);

    const filterTicketsByStatus = (status) => {
        const now = new Date();
        if (status === 'Not Started') {
            return tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.employee_id && ra.status === "Not Started")
            );
        }
        if (status === 'Unassign') {
            return tickets.filter(ticket => ticket.status === "New" && ticket.is_assigned === false);
        }
        if (status === 'Unassign (1Day)') {
            const now = new Date();
            const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const startOfYesterday = new Date(startOfToday);
            startOfYesterday.setDate(startOfYesterday.getDate() - 1);

            return tickets.filter(ticket =>
                ticket.status === "New" &&
                ticket.is_assigned === false &&
                new Date(ticket.created_at) >= startOfYesterday &&
                new Date(ticket.created_at) < startOfToday
            );
        }

        if (status === 'Unassign (7Day)') {
            return tickets.filter(ticket =>
                ticket.assignee === "Unassigned" &&
                ticket.is_assigned === false &&
                (now - new Date(ticket.created_at)) / (1000 * 60 * 60 * 24) === 7
            );
        }
        if (status === 'Unassign (Above7Days)') {
            return tickets.filter(ticket =>
                ticket.assignee === "Unassigned" &&
                ticket.is_assigned === false &&
                (now - new Date(ticket.created_at)) / (1000 * 60 * 60 * 24) > 7
            );
        }
        if (status === 'Cancelled') {
            return tickets.filter(ticket => ticket.status === 'Cancelled');
        }

        if (status === 'Overdue') {
            const now = new Date();
            const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            return tickets.filter(ticket =>
                new Date(ticket.need_by_date) < startOfToday &&
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status !== "Completed")
            );
        }

        if (status === 'Completed') {
            let filteredTickets = tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status === 'Completed')
            );
            return filteredTickets.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        }
        if (status === 'Inprocess') {
            return tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status === 'In-Process' || ra.status === 'Started')
            );
        }
        if (status === 'Waiting for Purchase') {
            return tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status === 'Waiting for Purchase')
            );
        }
        if (status === 'Waiting for Approval') {
            return tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status === 'Waiting for Approval')
            );
        }
        if (status === 'On Hold') {
            return tickets.filter(ticket =>
                ticket.request_assignments &&
                ticket.request_assignments.some(ra => ra.status === 'On Hold')
            );
        }
        return tickets;
    };

    const filterTicketsByRating = (rating) => {
        return tickets.filter(ticket => ticket.rating === rating);
    };

    const filterTicketsBySearchTerm = (ticketList) => {
        if (!searchTerm) return ticketList;
        return ticketList.filter(ticket => {
            const searchableContent = [
                ticket && ticket.location && ticket.location.name,
                ticket.ticket_number,
                ticket.reporter_department,
                ticket.request_title,
                ticket.reporter,
                ticket.on_behalf_of_employee,
                ticket.rating
            ].join(" ").toLowerCase();

            return searchableContent.includes(searchTerm.toLowerCase());
        });
    };

    const handleCardClick = (status) => {
        setSelectedStatus(status);
        setSelectedRating(null);
        setShowModal(true);
    };

    const handleStarClick = (rating) => {
        setSelectedRating(rating);
        setSelectedStatus(null);
        setShowModal(true);
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: 'hsl(253deg 61% 55%)',
                color: '#fff',
                fontSize: '13px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
                textAlign: 'center',
            },
        },
    };

    const statusIcons = {
        'Not Started': <i className="ri-user-line" style={{ fontSize: '3rem', color: "blue" }}></i>,
        'Unassign': <i className="ri-user-line" style={{ fontSize: '3rem', color: "brown" }}></i>,
        'Inprocess': <i className="ri-settings-2-line" style={{ fontSize: '3rem', color: "plum" }}></i>,
        'On Hold': <i className="ri-loader-2-line" style={{ fontSize: '3rem', color: "rebeccapurple" }}></i>,
        'Completed': <i className="ri-checkbox-circle-fill" style={{ fontSize: '3rem', color: "darkgreen" }}></i>,
        'Overdue': <i className="ri-time-line" style={{ fontSize: '3rem', color: "rebeccapurple" }}></i>,
        'Unassign (1Day)': <i className="ri-user-line" style={{ fontSize: '3rem', color: "indianred" }}></i>,
        'Unassign (7Day)': <i className="ri-group-line" style={{ fontSize: '3rem', color: "orangered" }}></i>,
        'Unassign (Above7Days)': <i className="ri-group-2-line" style={{ fontSize: '3rem', color: "coral" }}></i>,
        'Cancelled': <i className="ri-close-circle-line" style={{ fontSize: '3rem', color: "red" }}></i>,
        'Waiting for Purchase': <i className="ri-shopping-cart-line" style={{ fontSize: '3rem', color: "darkorange" }}></i>,
        'Waiting for Approval': <i className="ri-check-double-line" style={{ fontSize: '3rem', color: "deepskyblue" }}></i>
    };

    const flexContainerStyle = {
        display: isMobile ? 'block' : 'flex',
        flexWrap: 'wrap',
        marginBottom: '20px'
    };

    return (
        <div style={{ padding: '20px', width: '100%' }}>
            <Toaster />
            <div style={{ marginBottom: '20px', marginLeft: "10px" }}>
                <h1 style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>SR Dashboard</h1>
            </div>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div style={{ flex: '0 0 30%', marginRight: '10px' }}>
                    <Select
                        options={departments.map(department => ({
                            value: department.id,
                            label: department.name
                        }))}
                        value={selectedDepartment}
                        onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
                        placeholder="Select Department"
                        isClearable
                    />
                </div>
                <div style={{ flex: '0 0 30%', marginRight: '10px' }}>
                    <Form.Control
                        as="select"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    >
                        <option value="">Select Year</option>
                        {[...Array(2).keys()].map(i => {
                            const yearOption = new Date().getFullYear() + i;
                            return <option key={yearOption} value={yearOption}>{yearOption}</option>
                        })}
                    </Form.Control>
                </div>
                    <Button className={'btn'}  color="primary" onClick={handleDownloadExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />| Excel
                    </Button>
            </div>

            <div style={flexContainerStyle}>
                {['Unassign', 'Not Started', 'Inprocess', 'On Hold', 'Completed', 'Overdue', 'Waiting for Purchase', 'Waiting for Approval'].map(status => (
                    <div
                        key={status}
                        className="iq-card"
                        style={cardStyles}
                        onClick={() => handleCardClick(status)}
                        onMouseOver={(e) => onCardHover(e, '#f0f0f0')}
                        onMouseOut={onCardLeave}
                    >
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                            <tr>
                                <td rowSpan="2" style={{ padding: '10px' }}>{statusIcons[status]}</td>
                                <td style={{ padding: '10px', fontSize: '14px', color: '#555' }}>{status}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>{filterTicketsByStatus(status).length}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>

            <div style={flexContainerStyle}>
                {['Unassign (1Day)', 'Unassign (7Day)', 'Unassign (Above7Days)'].map(status => (
                    <div
                        key={status}
                        className="iq-card"
                        style={cardStyles}
                        onClick={() => handleCardClick(status)}
                        onMouseOver={(e) => onCardHover(e, '#f0f0f0')}
                        onMouseOut={onCardLeave}
                    >
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                            <tr>
                                <td rowSpan="2" style={{ padding: '10px' }}>{statusIcons[status]}</td>
                                <td style={{ padding: '10px', fontSize: '14px', color: '#555' }}>{status}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>{filterTicketsByStatus(status).length}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
                <div
                    key="Cancelled"
                    className="iq-card"
                    style={cardStyles}
                    onClick={() => handleCardClick('Cancelled')}
                    onMouseOver={(e) => onCardHover(e, '#f0f0f0')}
                    onMouseOut={onCardLeave}
                >
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                        <tr>
                            <td rowSpan="2" style={{ padding: '10px' }}>{statusIcons['Cancelled']}</td>
                            <td style={{ padding: '10px', fontSize: '14px', color: '#555' }}>Cancelled</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '10px', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>{filterTicketsByStatus('Cancelled').length}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div style={flexContainerStyle}>
                {[1, 2, 3, 4, 5].map((rating, index) => (
                    <div
                        key={rating + index}
                        className="iq-card"
                        style={cardStyles}
                        onClick={() => handleStarClick(rating)}
                        onMouseOver={(e) => onCardHover(e, '#f0f0f0')}
                        onMouseOut={onCardLeave}
                    >
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                            <tr>
                                <td rowSpan="2" style={{ padding: '10px' }}>
                                    {[...Array(rating)].map((_, i) => (
                                        <FontAwesomeIcon
                                            key={i}
                                            icon={faStar}
                                            size="1x"
                                            color="gold"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ))}
                                    {[...Array(5 - rating)].map((_, i) => (
                                        <FontAwesomeIcon
                                            key={i + rating}
                                            icon={faStar}
                                            size="1x"
                                            color="grey"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ))}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
                                    {filterTicketsByRating(rating).length}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={handleCloseConfirmModal} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>{selectedStatus || `${selectedRating}-Star Rating`} Service Request</Modal.Title>
                    <Button variant="link" onClick={handleCloseConfirmModal} style={{ color: 'gray', padding: '0' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: '10px' }}>
                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div style={{ overflowX: 'auto' }}>
                        <DataTable
                            columns={columns}
                            data={filterTicketsBySearchTerm(selectedStatus ? filterTicketsByStatus(selectedStatus) : filterTicketsByRating(selectedRating))} // Filter data by search term
                            noHeader
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                            highlightOnHover
                            pointerOnHover
                            customStyles={customStyles}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default Tickets;
