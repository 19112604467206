import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import BackgroundImage from '../../images/Yasir Hashmi.jpeg';
import bg from '../../images/vc_card.jpg';
import QRCode from 'react-qr-code';
import logo from '../../images/srl-logo.png';
import { faPhone, faEnvelope, faLongArrowRight, faArrowRight, faQrcode, faUpload, faAdd, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { faFacebook, faInstagram, faXTwitter, faWhatsapp, faSnapchatGhost } from "@fortawesome/free-brands-svg-icons";

const ProfileCardDetail = () => {
    const { id } = useParams(); // Extract ID from URL
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isQRCodeModalOpen, setQRCodeModalOpen] = useState(false);
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [profileData, setProfileData] = useState(null); // State to store the profile data

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`/digital_profile/${id}/profile`);
                setProfileData(response.data);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [id]);

    const isMobile = windowWidth <= 768;

    const toggleQRCodeModal = () => setQRCodeModalOpen(!isQRCodeModalOpen);
    const toggleShareModal = () => setShareModalOpen(!isShareModalOpen);

    const handleShareClick = (platform) => {
        const shareURL = `https://your-website.com/profile/${id}`;
        switch (platform) {
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`, '_blank');
                break;
            case 'instagram':
                alert("Share on Instagram via your app.");
                break;
            case 'snapchat':
                window.open(`https://www.snapchat.com/scan?attachmentUrl=${encodeURIComponent(shareURL)}`, '_blank');
                break;
            case 'whatsapp':
                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(shareURL)}`, '_blank');
                break;
            case 'copy':
                navigator.clipboard.writeText(shareURL).then(() => {
                    alert('Link copied to clipboard');
                });
                break;
            default:
                break;
        }
    };

    const handleAddToContactClick = () => {
        const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${profileData.name}
ORG:${profileData.company}
TITLE:${profileData.title}
TEL;TYPE=WORK,VOICE:${profileData.phone}
EMAIL:${profileData.email}
ADR;TYPE=WORK:${profileData.address}
END:VCARD`;

        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf';
        a.click();
        URL.revokeObjectURL(url);
    };

    if (!profileData) {
        return <div>Loading...</div>; // Show a loading state while data is being fetched
    }

    const profileCardStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${bg})`,
        backgroundColor: '#f5f5f5',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        textAlign: 'center',
        padding: isMobile ? '10px' : '0',
        overflow: 'auto',
        paddingTop: '2pc',
        position: 'relative',
    };

    const centeredContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    };

    const profilePictureStyle = {
        width: isMobile ? '120px' : '150px',
        height: isMobile ? '120px' : '150px',
        borderRadius: '50%',
        backgroundImage: `url(${profileData.imageUrl || BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: isMobile ? '15px' : '20px',
    };

    const profileInfoStyle = {
        marginBottom: isMobile ? '10px' : '20px',
        marginRight: isMobile ? '0' : '20px',
        position: 'relative',
    };

    const h1AfterStyle = {
        width: isMobile ? '70px' : '74px',
        height: isMobile ? '4px' : '6px',
        backgroundColor: '#000000',
        display: 'block',
        marginLeft: '0px',
        marginTop: '10px',
        position: 'absolute',
        left: isMobile ? '13%' : '14%',
        transform: 'translateX(-50%)',
        bottom: isMobile ? '60px' : '64px',
    };

    const contactButtonsStyle = {
        marginTop: isMobile ? '0px' : '0px',
        marginRight: isMobile ? '0px' : '213px',
    };

    const contactButtonStyle = {
        fontSize: isMobile ? '18px' : '20px',
        margin: '0 10px',
        border: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        background: '#fff',
        borderRadius: '20px 0 20px 0',
    };

    const additionalInfoStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: isMobile ? '100%' : '30.5pc',
        height: isMobile ? 'auto' : '7pc',
        margin: '20px 0',
    };

    const titleStyle = {
        fontSize: '24px',
        fontWeight: '800',
        marginBottom: '10px',
        color: '#000',
    };

    const descriptionStyle = {
        fontSize: '16px',
        color: '#666',
    };

    const designation = {
        marginTop: isMobile ? '10px' : '20px',
        margin: '0',
        padding: '0 0 10px 0',
        position: 'relative',
        marginLeft: isMobile ? '0' : '-225px',
    };

    const company = {
        fontSize: isMobile ? '15px' : '17px',
        fontWeight: 'bold',
        marginLeft: isMobile ? '0' : '-100px',
        color: 'grey',
    };

    const cardStyle = {
        width: isMobile ? '100%' : '30.5pc',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        paddingBottom: '18px'
    };

    const floatingIconStyle = {
        position: 'fixed',
        bottom: isMobile ? '25px':'50px',
        left: isMobile ? '30px' : '80px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        zIndex:'1000'
    };
    const floatIconButtonStyle = {
        fontSize: '25px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '50%',
        width: '45px',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textDecoration: 'none'
    };
    const floatingIconStyles = {
        position: 'fixed',
        bottom: isMobile ? '30px':'50px',
        right: '40px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
    };

    // QR Code content (profile URL)
    const profileURL = `digital_profile/profile/${id}`;

    return (
        <div style={profileCardStyle}>
            <div style={centeredContentStyle}>
                <div style={profilePictureStyle}></div>
                <div style={profileInfoStyle}>
                    <h1 style={{
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                        color: 'black',
                        marginBottom: '10px',
                    }}>
                        {profileData.name}
                        <div style={h1AfterStyle}></div>
                    </h1>
                    <h6 style={designation}>
                        {profileData.title}
                    </h6>
                    <h3 style={company}>{profileData.company}</h3>
                </div>
                <div style={contactButtonsStyle}>
                    <button style={contactButtonStyle}>
                        <FontAwesomeIcon icon={faPhone} />
                    </button>
                    <button style={contactButtonStyle}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </button>
                </div>
                <div style={additionalInfoStyle}>
                    <h1 style={titleStyle}>About Me</h1>
                    <p style={descriptionStyle}>{profileData.description}</p>
                </div>
                <Card style={cardStyle} className="iq-card custom-iq">
                    <div className="iq-card-header d-flex justify-content-between pro-task-bg-card border-bottom">
                        <div className="iq-header-title d-flex align-items-center">
                            <button style={contactButtonStyle}>
                                <FontAwesomeIcon icon={faPhone} />
                            </button>
                            <h6 className="card-title text-dark font-size-22 ml-2">Contact Us</h6>
                        </div>
                    </div>
                    <CardBody style={{ textAlign: 'left' }} className="text-align-left">
                        <p className="font-size-22" style={{ fontSize: '20px', margin: '0 0 2px 0' }}>
                            <span style={{ color: 'black' }}>Call Us:</span>
                            <span style={{ display: 'block', fontSize: '14px', margin: '0' }}>
                                {profileData.phone}
                            </span>
                        </p>
                        <p className="font-size-22" style={{ fontSize: '20px', margin: '0 0 2px 0' }}>
                            <span style={{ color: 'black' }}>Email:</span>
                            <span style={{ display: 'block', fontSize: '14px', margin: '0 0 15px 0' }}>
                                {profileData.email}
                            </span>
                        </p>
                        <p className="font-size-22" style={{ fontSize: '20px', margin: '0 0 2px 0', color: 'black' }}>
                            {profileData.address}
                        </p>
                        <p className="font-size-12" style={{ fontSize: '16px', margin: '0 0 2px 0' }}>
                            Street: <br />
                            {profileData.city}, {profileData.state}, {profileData.zip}
                            <span className="font-size-12" style={{ display: 'block', fontSize: '16px', margin: '0' }}>
                                {profileData.country}
                            </span>
                        </p>
                        <a href="https://your-link.com" target="_blank" rel="noopener noreferrer" style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '10px 15px',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            textDecoration: 'none',
                            paddingTop: '5px'
                        }}>
                            <span style={{ marginRight: '5px' }}>
                                <FontAwesomeIcon icon={faLongArrowRight} /></span>
                            Direction
                        </a>
                    </CardBody>
                </Card>

                {/*social*/}

                <Card style={cardStyle} className="iq-card custom-iq">
                    <div className="iq-card-header justify-content-between pro-task-bg-card border-bottom">
                        <div className="text-center align-items-center">
                            <h1 style={titleStyle}>Social Links</h1>
                            <p style={descriptionStyle}>Description</p>
                        </div>
                    </div>
                    <CardBody style={{ textAlign: 'left', position: 'relative' }} className="text-align-left">

                        <a href="https://your-link.com" target="_blank" rel="noopener noreferrer" style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px',
                            padding: '10px 15px',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            width: '100%',
                            flexDirection: 'row',
                            '@media (max-width: 768px)': {
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }
                        }}>
                            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '55px', marginRight: '10px'}} />
                            <div style={{ lineHeight: '1.2', flexGrow: 1 }}>
                                <span className="text-dark font-size-24" style={{ fontSize: '20px', display: 'block', marginBottom: '2px' }}>Facebook</span>
                                <span style={{ fontSize: '16px', color: '#666' }}>Follow Us on Facebook</span>
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                right: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <span className="icon-right_arrow"><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '25px', color: 'black' }} /></span>
                            </div>
                        </a>

                    </CardBody>
                </Card>

                <Card style={cardStyle} className="iq-card custom-iq">
                    <CardBody style={{ textAlign: 'left', position: 'relative' }} className="text-align-left">
                        <a href="https://your-link.com" target="_blank" rel="noopener noreferrer" style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px',
                            padding: '10px 15px',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            width: '100%',
                            flexDirection: 'row',
                            '@media (max-width: 768px)': {
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }
                        }}>
                            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '55px', marginRight: '10px', color: '#E1306C' }} />
                            <div style={{ lineHeight: '1.2', flexGrow: 1 }}>
                                <span className="text-dark font-size-24" style={{ fontSize: '20px', display: 'block', marginBottom: '2px' }}>Instagram</span>
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                right: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <span className="icon-right_arrow"><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '25px', color: 'black' }} /></span>
                            </div>
                        </a>

                    </CardBody>
                </Card>

                <Card style={cardStyle} className="iq-card custom-iq">
                    <CardBody style={{ textAlign: 'left', position: 'relative' }} className="text-align-left">
                        <a href="https://your-link.com" target="_blank" rel="noopener noreferrer" style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px',
                            padding: '10px 15px',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            width: '100%',
                            flexDirection: 'row',
                            '@media (max-width: 768px)': {
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }
                        }}>
                            <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: '55px', marginRight: '10px', color: 'black' }} />
                            <div style={{ lineHeight: '1.2', flexGrow: 1 }}>
                                <span className="text-dark font-size-24" style={{ fontSize: '20px', display: 'block', marginBottom: '2px' }}>Twitter</span>
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                right: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <span className="icon-right_arrow"><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '25px', color: 'black' }} /></span>
                            </div>
                        </a>
                    </CardBody>
                </Card>
                {/* Floating Icons */}
                <div style={floatingIconStyle}>
                    <a onClick={toggleQRCodeModal} style={floatIconButtonStyle}>
                        <FontAwesomeIcon icon={faQrcode} />
                    </a>
                    <a onClick={toggleShareModal} style={floatIconButtonStyle}>
                        <FontAwesomeIcon icon={faUpload} />
                    </a>
                </div>
                <div style={floatingIconStyles}>
                    <a onClick={handleAddToContactClick} style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#000',
                        color: '#fff',
                        borderRadius: '20px',
                        padding: '5px 10px',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '16px',
                        textDecoration: 'none',
                        paddingTop: '5px'
                    }}>
                            <span style={{ marginRight: '5px' }}>
                                <FontAwesomeIcon icon={faAdd} /> </span>
                        Add to  <span>Contact</span>
                    </a>
                </div>
            </div>

            <Modal isOpen={isQRCodeModalOpen} toggle={toggleQRCodeModal}>
                <ModalHeader toggle={toggleQRCodeModal}>Digital Business Card</ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                        <div style={{ ...profilePictureStyle, margin: '0 auto' }}></div>
                        <h1 style={{ fontSize: '24px', margin: '10px 0' }}>{profileData.name}</h1>
                        <h6 style={{ fontSize: '18px', color: 'gray', marginBottom: '10px' }}>{profileData.title}</h6>
                    </div>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <QRCode value={profileURL} size={256} renderAs="svg" />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '64px',
                            height: '64px',
                            backgroundImage: `url(${logo})`,
                            backgroundSize: 'cover',
                            borderRadius: '50%'
                        }}></div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Button color="primary" style={{ marginRight: '10px' }}>Add to Google Wallet</Button>
                        <Button color="secondary">Add to Apple Wallet</Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={isShareModalOpen} toggle={toggleShareModal}>
                <ModalHeader toggle={toggleShareModal}>Share Digital Business Card</ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                    <Button color="primary" style={{ margin: '10px' }} onClick={() => handleShareClick('facebook')}>
                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                    </Button>
                    <Button color="secondary" style={{ margin: '10px' }} onClick={() => handleShareClick('instagram')}>
                        <FontAwesomeIcon icon={faInstagram} /> Instagram
                    </Button>
                    <Button color="warning" style={{ margin: '10px' }} onClick={() => handleShareClick('snapchat')}>
                        <FontAwesomeIcon icon={faSnapchatGhost} /> Snapchat
                    </Button>
                    <Button color="success" style={{ margin: '10px' }} onClick={() => handleShareClick('whatsapp')}>
                        <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
                    </Button>
                    <Button color="info" style={{ margin: '10px' }} onClick={() => handleShareClick('copy')}>
                        <FontAwesomeIcon icon={faCopy} /> Copy Link
                    </Button>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ProfileCardDetail;
