import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LinkedInSkeleton = () => {
    return (
        <SkeletonTheme baseColor="#ddd" highlightColor="#eee">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
                    <Skeleton height={15} width="90%" />
                    <Skeleton height={15} width="95%" />
                    <Skeleton height={15} width="85%" />
                    <Skeleton height={15} width="80%" />
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Skeleton height={35} width="20%" />
                    <Skeleton height={35} width="20%" />
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default LinkedInSkeleton;
