import React from 'react';

const EmployeeDetails = ({ employee }) => {
console.log(employee)
    return (
        <div className="employee-details">
            <div className="employee-photo">
                <img src={employee.photoUrl || '/system/profile-placeholder.jpg'} alt="Employee Avatar" />
            </div>
            <div className="employee-info">
                <p><span className="label">Company:</span> <span className="value">{employee.company_name}</span></p>
                <p><span className="label">Reporter:</span> <span className="value">{employee.name}</span></p>
                <p><span className="label">Emp Code:</span> <span className="value">{employee.employeeCode}</span></p>
                {employee.srNo && <p><span className="label">SR #:</span> <span className="value">{employee.srNo}</span></p>}
                <p><span className="label">Request Status:</span> <span className="value">{employee.requestStatus}</span></p>
                <p><span className="label">Date & Time Created:</span> <span className="value">{employee.dateCreated}</span></p>
            </div>
        </div>

    );
};

export default EmployeeDetails;
