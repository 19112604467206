// SpeechHandler.js
import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import nlp from 'compromise';
import { RiMicLine, RiStopFill } from 'react-icons/ri';

const languages = [
    { code: 'en-US', name: 'English (United States)' },
    { code: 'en-GB', name: 'English (United Kingdom)' },
    { code: 'en-AU', name: 'English (Australia)' },
    { code: 'en-CA', name: 'English (Canada)' },
    { code: 'en-IN', name: 'English (India)' },
    { code: 'en-NG', name: 'English (Nigeria)' },
    { code: 'en-ZA', name: 'English (South Africa)' }
];

const soundex = (s) => {
    const a = s.toLowerCase().split('');
    const f = a.shift();
    const r = a
        .map((c) => {
            switch (c) {
                case 'b': case 'f': case 'p': case 'v': return '1';
                case 'c': case 'g': case 'j': case 'k': case 'q': case 's': case 'x': case 'z': return '2';
                case 'd': case 't': return '3';
                case 'l': return '4';
                case 'm': case 'n': return '5';
                case 'r': return '6';
                default: return '';
            }
        })
        .filter((v, i, a) => i === 0 ? v !== a[i - 1] : v)
        .join('');

    return (f + r + '000').slice(0, 4).toUpperCase();
};

const processTranscript = (transcript) => {
    const normalizedTranscript = nlp(transcript).normalize().out('text');
    const words = normalizedTranscript.split(' ');
    const soundexCodes = words.map(word => soundex(word));
    return soundexCodes.join(' ');
};

const SpeechHandler = ({ setCurrentMessage, setProcessedTranscript }) => {
    const [currentLangCode, setCurrentLangCode] = useState('en-US');
    const [isCycling, setIsCycling] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.log('Speech Recognition not supported in this browser.');
        }

        const userLang = navigator.language || navigator.userLanguage;
        const matchedLang = languages.find(lang => lang.code === userLang) ? userLang : 'en-US';
        setCurrentLangCode(matchedLang);
    }, [browserSupportsSpeechRecognition]);

    useEffect(() => {
        if (isCycling && listening) {
            SpeechRecognition.startListening({ continuous: true, language: currentLangCode });
        }
    }, [currentLangCode, isCycling, listening]);

    useEffect(() => {
        if (transcript) {
            setCurrentMessage(transcript);
            const processed = processTranscript(transcript);
            setProcessedTranscript(processed);
        }
    }, [transcript]);

    useEffect(() => {
        if (isCycling && listening && !transcript) {
            const timeout = setTimeout(() => {
                const newLangCode = languages[(languages.findIndex(lang => lang.code === currentLangCode) + 1) % languages.length].code;
                setCurrentLangCode(newLangCode);
                SpeechRecognition.startListening({ continuous: true, language: newLangCode });
            }, 5000); // Retry after 5 seconds if no transcript
            return () => clearTimeout(timeout);
        }
    }, [isCycling, listening, transcript, currentLangCode]);

    const handleListen = () => {
        if (listening) {
            SpeechRecognition.stopListening();
            setIsCycling(false);
            resetTranscript();
        } else {
            resetTranscript();
            setIsCycling(true);
            SpeechRecognition.startListening({ continuous: true, language: currentLangCode });
        }
    };

    return (
        <button className="voice-button" onClick={handleListen} title={listening ? 'Stop Listening' : 'Start Listening'}>
            {listening ? <RiStopFill /> : <RiMicLine />}
        </button>
    );
};

export default SpeechHandler;
