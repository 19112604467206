    import React, {useState, useEffect, useCallback} from 'react';
    import axios from 'axios';
    import DataTable from 'react-data-table-component';
    import { Button, InputGroup, FormControl, Modal, Form, Row, Col } from 'react-bootstrap';
    import Select from 'react-select';
    import toast, { Toaster } from 'react-hot-toast';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
    import InputMask from 'react-input-mask';
    import _ from 'lodash';
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: 'hsl(253deg 61% 55%)',
                color: '#fff',
                fontSize: '13px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                padding: '10px',
            },
        },
    };

    const Employee = () => {
        const [employees, setEmployees] = useState([]);
        const [locations, setLocations] = useState([]);
        const [departments, setDepartments] = useState([]);
        const [showModal, setShowModal] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const [employeeToDelete, setEmployeeToDelete] = useState(null);
        const [formData, setFormData] = useState({
            name: '',
            location: null,
            department: null,
            designation: '',
            dateOfJoining: '',
            mobNo: '',
            address: '',
            ebsLoginRemarks: '',
            mysapphireLoginRemarks: '',
            emailAddressRemarks: '',
            suggestedUserName: '',
            jobTitle: '',
            officialEmail: '',
            ebsLogin: false,
            mysapphireLogin: false,
        });
        const [editingEmployee, setEditingEmployee] = useState(null);
        const [filterText, setFilterText] = useState('');
        const [emailError, setEmailError] = useState('');

        const [currentPage, setCurrentPage] = useState(1);
        const [totalRows, setTotalRows] = useState(0);

        const designationOptions = [
            { value: 'Deputy General Manager', label: 'Deputy General Manager' },
            { value: 'Assistant Manager', label: 'Assistant Manager' },
            { value: 'Officer', label: 'Officer' },
            { value: 'Executive', label: 'Executive' },
            { value: 'Deputy Manager', label: 'Deputy Manager' },
            { value: 'Chief', label: 'Chief' },
            { value: 'Senior Executive', label: 'Senior Executive' },
            { value: 'Senior Officer', label: 'Senior Officer' },
            { value: 'Manager', label: 'Manager' },
            { value: 'Worker', label: 'Worker' },
            { value: 'Supervisor', label: 'Supervisor' },
            { value: 'General Manager', label: 'General Manager' },
            { value: 'Senior Manager', label: 'Senior Manager' },
            { value: 'Assistant Worker', label: 'Assistant Worker' },
        ];

        const phoneRegex = /^((\+92)|(0092))\d{3}-\d{7}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        useEffect(() => {
            fetchEmployees(currentPage);
            fetchDropdownData();
        }, [currentPage]);

        const fetchDropdownData = async () => {
            try {
                const locationResponse = await axios.get('/locations/fetch_location');
                setLocations(locationResponse.data.map(location => ({ value: location.id, label: location.name, company_id: location.company_id })));

                if (locationResponse.data.length > 0) {
                    const companyId = locationResponse.data[0].company_id;
                    const departmentResponse = await axios.get('/departments/fetch_dept', { params: { company_id: companyId } });
                    setDepartments(departmentResponse.data.map(department => ({ value: department.id, label: department.name })));
                }
            } catch (error) {
                console.error('Error fetching dropdown data', error);
                toast.error('Error fetching dropdown data');
            }
        };

        const fetchEmployees = async (page, searchQuery = '') => {
            try {
                const response = await axios.get('/employees/fetch_employees', {
                    params: {
                        page: page,
                        per_page: 10,
                        search: searchQuery
                    }
                });
                setEmployees(response.data.employees);
                setTotalRows(response.data.total);
            } catch (error) {
                console.error('Error fetching employees', error);
                toast.error('Error fetching employees');
                setEmployees([]);
            }
        };


        const handlePageChange = (page) => {
            setCurrentPage(page);
        };
        const debouncedSearch = useCallback(
            _.debounce((searchTerm) => {
                fetchEmployees(1, searchTerm);
            }, 500),
            [fetchEmployees]
        );


        const handleSearchChange = (e) => {
            const value = e.target.value;
            setFilterText(value);
            if (value.trim() === "") {
                fetchEmployees(1, "");
            } else {
                debouncedSearch(value);
            }
        };
        const handleModalClose = () => {
            setShowModal(false);
            setFormData({
                name: '',
                location: null,
                department: null,
                designation: '',
                dateOfJoining: '',
                mobNo: '',
                address: '',
                ebsLoginRemarks: '',
                mysapphireLoginRemarks: '',
                emailAddressRemarks: '',
                suggestedUserName: '',
                jobTitle: '',
                officialEmail: '',
                ebsLogin: false,
                mysapphireLogin: false,
            });
        };
        const handleInputChange = (e) => {
            const { name, value } = e.target;
            if (name === 'officialEmail') {
                if (!emailRegex.test(value)) {
                    setEmailError('Invalid email address format');
                } else {
                    setEmailError('');
                }
            }
            setFormData(prevData => ({
                ...prevData,
                [name]: value,
            }));
        };

        const handleSelectChange = (selectedOption, { name }) => {
            setFormData(prevData => ({
                ...prevData,
                [name]: selectedOption,
            }));
        };

        const handleCheckboxChange = (e) => {
            const { name, checked } = e.target;
            setFormData(prevData => ({
                ...prevData,
                [name]: checked,
            }));
        };

        const handleLocationChange = (selectedOption) => {
            setFormData(prevData => ({
                ...prevData,
                location: selectedOption,
            }));

            if (selectedOption) {
                axios.get('/departments/fetch_dept', { params: { company_id: selectedOption.company_id } })
                    .then(response => {
                        setDepartments(response.data.map(department => ({ value: department.id, label: department.name })));
                    })
                    .catch(error => {
                        console.error('Error fetching departments', error);
                        toast.error('Error fetching departments');
                    });
            } else {
                setDepartments([]);
            }
        };

        const handleSave = async () => {


            if (!emailRegex.test(formData.officialEmail)) {
                toast.error("Invalid email address format");
                return;
            }

            try {
                const url = editingEmployee ? `/employees/${editingEmployee.id}` : '/employees';
                const method = editingEmployee ? 'put' : 'post';
                await axios({
                    method,
                    url,
                    data: {
                        employee: {
                            name: formData.name,
                            location_id: formData.location && formData.location.value,
                            department_id: formData.department && formData.department.value,
                            designation: formData.designation && formData.designation.value,
                            date_of_joining: formData.dateOfJoining,
                            mob_no: formData.mobNo,
                            address: formData.address,
                            ebs_login_remarks: formData.ebsLoginRemarks,
                            mysapphire_login_remarks: formData.mysapphireLoginRemarks,
                            email_address_remarks: formData.emailAddressRemarks,
                            suggested_user_name: formData.suggestedUserName,
                            job_title: formData.jobTitle,
                            official_email: formData.officialEmail,
                            ebs_login: formData.ebsLogin,
                            mysapphire_login: formData.mysapphireLogin,
                        },
                    },
                });

                toast.success(`Employee ${editingEmployee ? 'updated' : 'created'} successfully`);

                fetchEmployees(currentPage);
                setShowModal(false);
                setFormData({
                    name: '',
                    location: null,
                    department: null,
                    designation: '',
                    dateOfJoining: '',
                    mobNo: '',
                    address: '',
                    ebsLoginRemarks: '',
                    mysapphireLoginRemarks: '',
                    emailAddressRemarks: '',
                    suggestedUserName: '',
                    jobTitle: '',
                    officialEmail: '',
                    ebsLogin: false,
                    mysapphireLogin: false,
                });
                setEditingEmployee(null);
            } catch (error) {
                console.error('Error saving employee', error);
                toast.error('Error saving employee');
            }
        };

        const handleEdit = (employee) => {
            setFormData({
                name: employee.name,
                location: locations.find(location => location.value === employee.location_id),
                department: departments.find(department => department.value === employee.department_id),
                designation: designationOptions.find(designation => designation.value === employee.designation),
                dateOfJoining: employee.date_of_joining,
                mobNo: employee.mob_no,
                address: employee.address,
                ebsLoginRemarks: employee.ebs_login_remarks,
                mysapphireLoginRemarks: employee.mysapphire_login_remarks,
                emailAddressRemarks: employee.email_address_remarks,
                suggestedUserName: employee.suggested_user_name,
                jobTitle: employee.job_title,
                officialEmail: employee.official_email,
                ebsLogin: employee.ebs_login,
                mysapphireLogin: employee.mysapphire_login,
            });
            setEditingEmployee(employee);
            setShowModal(true);
        };

        const handleDelete = (employee) => {
            setEmployeeToDelete(employee);
            setShowDeleteModal(true);
        };

        const confirmDelete = async () => {
            if (employeeToDelete) {
                try {
                    await axios.delete(`/employees/${employeeToDelete.id}`);
                    toast.success('Employee deleted successfully');
                    fetchEmployees(currentPage);
                    setShowDeleteModal(false);
                    setEmployeeToDelete(null);
                } catch (error) {
                    console.error('Error deleting employee', error);
                    toast.error('Error deleting employee');
                    setShowDeleteModal(false);
                }
            }
        };

        const columns = [
            { name: 'Employee Code', selector: row => row.employee_code, sortable: true },
            { name: 'Name', selector: row => row.name, sortable: true },
            { name: 'Location', selector: row => row.location && row.location.name, sortable: true },
            { name: 'Department', selector: row => row.department && row.department.name, sortable: true },
            { name: 'Designation', selector: row => row.designation, sortable: true },
            { name: 'Date Of Joining', selector: row => row.date_of_joining, sortable: true, format: row => new Date(row.date_of_joining).toLocaleDateString() },
            { name: 'Official Email', selector: row => row.official_email, sortable: true },
            {
                name: 'Actions',
                cell: (row) => (
                    <div>
                        <Button variant="warning" size="sm" onClick={() => handleEdit(row)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => handleDelete(row)} className="ml-2">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
            }
        ];

        const filteredEmployees = employees.filter(
            (item) =>
                (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.official_email && item.official_email.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.designation && item.designation.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.employee_code && item.employee_code.toLowerCase().includes(filterText.toLowerCase()))
        );


        const isSaveDisabled = !formData.name || !formData.location || !formData.department || !formData.designation || !formData.suggestedUserName || !formData.jobTitle;

        return (
            <div className="col-lg-12">
                <Toaster position="top-center" reverseOrder={false} />
                <div className="card-header d-flex justify-content-between align-items-center mb-3">
                    <Button variant="primary" onClick={() => setShowModal(true)}>New Employee</Button>
                    <h4 className="card-title">Employees Management</h4>
                    <InputGroup style={{ maxWidth: '300px' }}>
                        <FormControl
                            className="custom-height"
                            placeholder="Search..."
                            aria-label="Search"
                            value={filterText}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </div>
                {filteredEmployees.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={filteredEmployees}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        paginationPerPage={10}
                        highlightOnHover
                        responsive
                        striped
                        customStyles={customStyles}
                        noHeader
                    />
                ) : (
                    <div style={{
                        backgroundColor: '#eaf6ea',
                        borderLeft: '3px solid #5cb85c',
                        padding: '10px 15px',
                        color: 'rgb(22, 160, 133)',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        borderRadius: '0',
                    }}>
                        No Record Found!
                    </div>
                )}
                <Modal show={showModal} onHide={handleModalClose} size="lg">
                    <Modal.Header>
                        <Modal.Title>New Employee</Modal.Title>
                        <Button variant="link" onClick={() => setShowModal(false)} style={{ position: 'absolute', right: '15px', top: '15px' }}>
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Employee Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name || ""}
                                            onChange={handleInputChange}
                                            placeholder="Enter employee name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="location">
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <Select
                                            name="location"
                                            options={locations}
                                            value={formData.location}
                                            onChange={handleLocationChange}
                                            placeholder="Select location"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="department">
                                        <Form.Label>Department<span className="text-danger">*</span></Form.Label>
                                        <Select
                                            name="department"
                                            options={departments}
                                            value={formData.department}
                                            onChange={handleSelectChange}
                                            placeholder="Select department"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="designation">
                                        <Form.Label>Designation<span className="text-danger">*</span></Form.Label>
                                        <Select
                                            name="designation"
                                            options={designationOptions}
                                            value={formData.designation}
                                            onChange={handleSelectChange}
                                            placeholder="Select designation"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="dateOfJoining">
                                        <Form.Label>Date Of Joining</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="dateOfJoining"
                                            value={formData.dateOfJoining}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="mobNo">
                                        <Form.Label>Mob No<span className="text-danger">*</span></Form.Label>
                                        <InputMask
                                            mask="+\92999-9999999"
                                            value={formData.mobNo}
                                            onChange={handleInputChange}
                                        >
                                            {(inputProps) => <Form.Control
                                                {...inputProps}
                                                type="text"
                                                name="mobNo"
                                                placeholder="(e.g., +92XXX-XXXXXXX or 0092XXX-XXXXXXX)"
                                            />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="suggestedUserName">
                                        <Form.Label>Suggested User Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="suggestedUserName"
                                            value={formData.suggestedUserName}
                                            onChange={handleInputChange}
                                            placeholder="Enter suggested user name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="jobTitle">
                                        <Form.Label>Job Title<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="jobTitle"
                                            value={formData.jobTitle}
                                            onChange={handleInputChange}
                                            placeholder="Enter job title"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="officialEmail">
                                        <Form.Label>Official Email<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="officialEmail"
                                            value={formData.officialEmail}
                                            onChange={handleInputChange}
                                            placeholder="Enter official email address"
                                        />
                                        {emailError && <div className="text-danger">{emailError}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    placeholder="Enter address"
                                />
                            </Form.Group>
                            <Form.Group controlId="mysapphireLoginRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="mysapphireLoginRemarks"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Enter mysapphire.co login remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="emailAdress">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="mysapphireLogin"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Email Address Remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="mysapphireLoginRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="mysapphireLoginRemarks"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Active Directory (AD) User Login Remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="erpLoginRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="erpLoginRemarks"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="ERP Login Remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="fuelCardRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="fuelcard"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Fuel Card Remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="businessCardRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="businesscard"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Business Card Remarks"
                                />
                            </Form.Group>
                            <Form.Group controlId="discountCardRemarks">
                                <Form.Control
                                    type="text"
                                    className={'mt-2'}
                                    name="discountCardRemarks"
                                    value={formData.mysapphireLoginRemarks}
                                    onChange={handleInputChange}
                                    placeholder="Discount Card Remarks"
                                />
                            </Form.Group>
                            <Row>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="mysapphire.co Login"
                                        name="mysapphireLogin"
                                        checked={formData.mysapphireLogin}
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Email Address"
                                        name="emailaddress"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="AD User Login"
                                        name="adUser"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="ERP Login"
                                        name="erp"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Fuel Card"
                                        name="fuel"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Business Card"
                                        name="businesscard"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Discount Card"
                                        name="discountcard"
                                        onChange={handleCheckboxChange}
                                        className={'mt-3'}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button variant="primary" onClick={handleSave} disabled={isSaveDisabled}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                    <Modal.Header>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this employee? This action cannot be undone.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDelete}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };

    export default Employee;
