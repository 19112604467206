import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductDisplay from './ProductDisplay';
import CustomizationPanel from './CustomizationPanel';
import styled from 'styled-components';
import './Xyou.css';
import x_you from '../images/SAPPHIRE_X.png';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
`;

const Xyou = () => {
    const [customizations, setCustomizations] = useState({ front: [], back: [] });
    const [baseImage, setBaseImage] = useState({ front: null, back: null });
    const [collections, setCollections] = useState([]);
    const [currentView, setCurrentView] = useState('front');
    const [embroideryColor, setEmbroideryColor] = useState({ front: '', back: '' });

    useEffect(() => {
        axios.get('/sapphire_x_you/show_collection')
            .then(response => {
                setCollections(response.data);
            })
            .catch(error => {
                console.error("Error fetching collections:", error);
            });
    }, []);

    const handleUpdateCustomization = (custom) => {
        setCustomizations(prevCustomizations => {
            const updatedCustomizations = { ...prevCustomizations };
            updatedCustomizations[currentView] = prevCustomizations[currentView].filter(c => c.content.type !== custom.content.type);
            updatedCustomizations[currentView].push(custom);
            return updatedCustomizations;
        });
    };

    const handleUploadProductImage = (frontImage, backImage) => {
        setBaseImage({
            front: frontImage,
            back: backImage,
        });
    };

    const handleRemoveCustomization = (type) => {
        setCustomizations(prevCustomizations => ({
            ...prevCustomizations,
            [currentView]: prevCustomizations[currentView].filter(c => c.content.type !== type)
        }));
    };

    const handleEmbroideryColorChange = (color) => {
        setEmbroideryColor(prevColors => ({
            ...prevColors,
            [currentView]: color
        }));
    };

    return (
        <AppContainer>
            <img src={x_you} className="img-fluid you-style mb-4" alt="" />
            <PanelContainer>
                <CustomizationPanel
                    collections={collections}
                    onUpdateCustomization={handleUpdateCustomization}
                    onUploadProductImage={handleUploadProductImage}
                    onRemoveCustomization={handleRemoveCustomization}
                    embroideryColor={embroideryColor[currentView]}
                    setEmbroideryColor={handleEmbroideryColorChange}
                    currentView={currentView}
                    currentCustomization={customizations[currentView].find(c => c.content.type === 'image')}
                />
                <ProductDisplay
                    baseImage={baseImage}
                    customizations={customizations}
                    onRemoveCustomization={handleRemoveCustomization}
                    embroideryColor={embroideryColor[currentView]}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                />
            </PanelContainer>
        </AppContainer>
    );
};

export default Xyou;
