import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CreateCollectionModal = ({ onClose, existingCollection }) => {
    const [collectionName, setCollectionName] = useState(existingCollection ? existingCollection.name : '');
    const [products, setProducts] = useState(existingCollection ? existingCollection.products.map(p => ({ ...p, _destroy: false })) : [{ name: '', sku: '', frontImage: null, backImage: null, _destroy: false }]);

    const handleInputChange = (index, field, value) => {
        const newProducts = [...products];
        newProducts[index][field] = value;
        setProducts(newProducts);
    };

    const handleFileChange = (index, field, event) => {
        const file = event.target.files[0];
        const newProducts = [...products];
        newProducts[index][field] = file;
        setProducts(newProducts);
    };

    const addRow = () => {
        setProducts([...products, { name: '', sku: '', frontImage: null, backImage: null, _destroy: false }]);
    };

    const removeRow = (index) => {
        const newProducts = [...products];
        if (newProducts[index].id) {
            newProducts[index]._destroy = true; // Mark existing product for destruction
        } else {
            newProducts.splice(index, 1); // Remove new product from list
        }
        setProducts(newProducts);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const formData = new FormData();
            formData.append('collection[name]', collectionName);
            products.forEach((product, index) => {
                if (product._destroy) {
                    formData.append(`collection[products_attributes][${index}][id]`, product.id);
                    formData.append(`collection[products_attributes][${index}][_destroy]`, '1');
                } else {
                    if (product.id) formData.append(`collection[products_attributes][${index}][id]`, product.id);
                    formData.append(`collection[products_attributes][${index}][name]`, product.name);
                    formData.append(`collection[products_attributes][${index}][sku]`, product.sku);
                    if (product.frontImage) {
                        formData.append(`collection[products_attributes][${index}][front_image]`, product.frontImage);
                    }
                    if (product.backImage) {
                        formData.append(`collection[products_attributes][${index}][back_image]`, product.backImage);
                    }
                }
            });

            const url = existingCollection ? `/sapphire_x_you/${existingCollection.id}/update_collection` : '/sapphire_x_you/create_collection';
            const method = existingCollection ? 'put' : 'post';

            const response = await axios[method](url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alert('Collection saved successfully');
            onClose();
        } catch (error) {
            alert('Failed to save collection');
            console.error('Error saving collection:', error);
        }
    };

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{existingCollection ? 'Edit Collection' : 'Create Collection'}</h5>
                        <button type="button" className="close" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="iq-card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                            <label className="col-form-label">Collection Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={collectionName}
                                                onChange={(e) => setCollectionName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {products.map((product, index) => (
                                    !product._destroy && (
                                        <div className="row" key={index}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="col-form-label">Product Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={product.name}
                                                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="col-form-label">SKU</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={product.sku}
                                                        onChange={(e) => handleInputChange(index, 'sku', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 mt-5">
                                                <div className="form-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id={`validatedCustomFileFront-${index}`}
                                                            name="avatar"
                                                            accept=".jpg, .jpeg, .png, .webp"
                                                            onChange={(e) => handleFileChange(index, 'frontImage', e)}
                                                        />
                                                        <label className="custom-file-label" htmlFor={`validatedCustomFileFront-${index}`}>Front Image..</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mt-5">
                                                <div className="form-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id={`validatedCustomFileBack-${index}`}
                                                            name="avatar"
                                                            accept=".jpg, .jpeg, .png, .webp"
                                                            onChange={(e) => handleFileChange(index, 'backImage', e)}
                                                        />
                                                        <label className="custom-file-label" htmlFor={`validatedCustomFileBack-${index}`}>Back Image..</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 d-flex align-items-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => removeRow(index)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                ))}
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-secondary" onClick={addRow}>+ Add Product</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <button type="submit" className="btn btn-primary">{existingCollection ? 'Save Changes' : 'Save Collection'}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCollectionModal;
