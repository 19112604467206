import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faFileAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavBarContainer = styled(Navbar)`
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
`;

const StyledNavLink = styled(NavLink)`
  margin-left: 20px;
  color: black;
  text-decoration: none;

  &.active {
    color: #007bff;
    font-weight: bold;
  }
`;

const TopNavBar = () => {
    return (
        <NavBarContainer>
            <Navbar.Brand href="#">Sapphire X You</Navbar.Brand>
            <Nav className="ml-auto">
                <StyledNavLink
                    to="/sapphire_x_you/product"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faBoxOpen} /> Products
                </StyledNavLink>
                <StyledNavLink
                    to="/sapphire_x_you/asset"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faFileAlt} /> Assets
                </StyledNavLink>
                <StyledNavLink
                    to="/sapphire_x_you/orders"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faShoppingCart} /> Orders
                </StyledNavLink>
            </Nav>
        </NavBarContainer>
    );
};

export default TopNavBar;
