import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';

const ApplySettingsModal = ({ onClose }) => {
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [fontSets, setFontSets] = useState([]);
    const [selectedFontSets, setSelectedFontSets] = useState([]);
    const [colorSets, setColorSets] = useState([]);
    const [selectedColorSets, setSelectedColorSets] = useState([]);
    const [designSets, setDesignSets] = useState([]);
    const [selectedDesignSets, setSelectedDesignSets] = useState([]);

    useEffect(() => {
        fetchCollections();
        fetchFontSets();
        fetchColorSets();
        fetchDesignSets();
    }, []);

    const fetchCollections = () => {
        axios.get('/sapphire_x_you/show_collection')
            .then(response => setCollections(response.data))
            .catch(error => console.error('Error fetching collections:', error));
    };

    const fetchFontSets = () => {
        axios.get('/sapphire_x_you/font_sets')
            .then(response => setFontSets(response.data))
            .catch(error => console.error('Error fetching font sets:', error));
    };

    const fetchColorSets = () => {
        axios.get('/sapphire_x_you/color_sets')
            .then(response => setColorSets(response.data))
            .catch(error => console.error('Error fetching color sets:', error));
    };

    const fetchDesignSets = () => {
        axios.get('/sapphire_x_you/design_sets')
            .then(response => setDesignSets(response.data))
            .catch(error => console.error('Error fetching design sets:', error));
    };

    const handleCollectionChange = (e) => {
        const collectionId = e.target.value;
        setSelectedCollection(collectionId);
    };

    const handleApplySettings = (event) => {
        event.preventDefault();

        const data = {
            collection_id: selectedCollection,
            font_set_ids: selectedFontSets.map(set => set.value),
            color_set_ids: selectedColorSets.map(set => set.value),
            design_set_ids: selectedDesignSets.map(set => set.value),
        };

        axios.post(`/sapphire_x_you/${selectedCollection}/apply_settings`, data)
            .then(response => {
                alert('Settings applied successfully');
                onClose();
            })
            .catch(error => {
                alert('Failed to apply settings');
                console.error('Error applying settings:', error);
            });
    };

    const fontSetOptions = fontSets.map(set => ({ value: set.id, label: set.name }));
    const colorSetOptions = colorSets.map(set => ({ value: set.id, label: set.name }));
    const designSetOptions = designSets.map(set => ({ value: set.id, label: set.name }));

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Apply Settings</h5>
                        <button type="button" className="close" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleApplySettings}>
                            <div className="iq-card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label">Select Collection</label>
                                            <select
                                                className="form-control"
                                                value={selectedCollection}
                                                onChange={handleCollectionChange}
                                            >
                                                <option value="" disabled>Select a collection</option>
                                                {collections.map(collection => (
                                                    <option key={collection.id} value={collection.id}>{collection.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label">Select Font Sets</label>
                                            <Select
                                                isMulti
                                                options={fontSetOptions}
                                                value={selectedFontSets}
                                                onChange={setSelectedFontSets}
                                                placeholder="Select Font Sets"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label">Select Color Sets</label>
                                            <Select
                                                isMulti
                                                options={colorSetOptions}
                                                value={selectedColorSets}
                                                onChange={setSelectedColorSets}
                                                placeholder="Select Color Sets"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label">Select Design Sets</label>
                                            <Select
                                                isMulti
                                                options={designSetOptions}
                                                value={selectedDesignSets}
                                                onChange={setSelectedDesignSets}
                                                placeholder="Select Design Sets"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <Button type="submit" variant="primary">Apply Settings</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplySettingsModal;
