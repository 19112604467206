import React, { useState } from 'react';
import styled from 'styled-components';
import TopNavBar from './Layout/TopNavBar';
import Sidebar from './Layout/Sidebar';
import FontPage from './FontPage';
import ColorPage from './ColorPage';
import setting from '../../images/assets.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredImage = styled.img`
  width: 200px;
  height: 200px;
`;

const Assets = () => {
    const [selectedSection, setSelectedSection] = useState('default');

    const renderContent = () => {
        switch (selectedSection) {
            case 'fonts':
                return <FontPage />;
            case 'colors':
                return <ColorPage />;
            default:
                return (
                    <div>
                        <CenteredImage src={setting} alt="Settings Icon" />
                        <p style={{ textAlign: 'center' }}>Nothing Is Selected</p>
                    </div>
                );
        }
    };

    return (
        <PageContainer>
            <TopNavBar />
            <MainContent>
                <Sidebar onSelectSection={setSelectedSection} />
                <ContentArea>
                    {renderContent()}
                </ContentArea>
            </MainContent>
        </PageContainer>
    );
};

export default Assets;
