import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useTable, useExpanded } from 'react-table';
import { ClipLoader } from 'react-spinners';
import dayjs from 'dayjs';

const ChartComponent = () => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear;
    const [pieChartData, setPieChartData] = useState([]);
    const [pieChartLabels, setPieChartLabels] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [barChartCategories, setBarChartCategories] = useState([]);
    const [verticalBarChartData, setVerticalBarChartData] = useState([]);
    const [verticalBarChartCategories, setVerticalBarChartCategories] = useState([]);
    const [lineChartSeries, setLineChartSeries] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [selectedYear, setSelectedYear] = useState(lastYear.toString());
    const [selectedContinent, setSelectedContinent] = useState(null);
    const [loading, setLoading] = useState(true);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const perPage = 5;

    const convertToReadableFormat = (value) => {
        if (isNaN(value)) {
            return value;
        }

        value = parseFloat(value);

        if (value >= 1000000000) {
            return `${(value / 1000000000).toFixed(1)}bn`;
        } else if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}m`;
        } else {
            return value.toFixed(1);
        }
    };

    const fetchData = useCallback(async (page, continent = null) => {
        setLoading(true);
        try {
            axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
            const response = await axios.get(`/clean_beirholm/bi_data`, {
                params: {
                    page,
                    per_page: perPage,
                    year: selectedYear,
                    continent
                }
            });
            const data = response.data;

            if (page === 1) {
                if (data.pie_chart && Array.isArray(data.pie_chart)) {
                    const pieDataValues = data.pie_chart.map(item => parseFloat(item.total_usd));
                    const pieDataLabels = data.pie_chart.map(item => item.continent);

                    setPieChartData(pieDataValues);
                    setPieChartLabels(pieDataLabels);
                } else {
                    console.error('Unexpected pie chart data format:', data.pie_chart);
                }

                if (data.bar_chart && Array.isArray(data.bar_chart)) {
                    const barData = data.bar_chart.reduce((acc, item) => {
                        const continentIndex = acc.labels.indexOf(item.continent);
                        if (continentIndex === -1) {
                            acc.labels.push(item.continent);
                            acc.series.forEach(series => series.data.push(0));
                        }
                        const leagueIndex = acc.series.findIndex(series => series.name === item.league);
                        if (leagueIndex === -1) {
                            const newSeries = {
                                name: item.league,
                                data: new Array(acc.labels.length).fill(0)
                            };
                            newSeries.data[acc.labels.indexOf(item.continent)] = parseFloat(item.total_usd);
                            acc.series.push(newSeries);
                        } else {
                            acc.series[leagueIndex].data[acc.labels.indexOf(item.continent)] = parseFloat(item.total_usd);
                        }
                        return acc;
                    }, { labels: [], series: [] });

                    barData.series.forEach(series => {
                        if (series.data.length < barData.labels.length) {
                            series.data = [...series.data, ...new Array(barData.labels.length - series.data.length).fill(0)];
                        }
                    });

                    setBarChartCategories(barData.labels);
                    setBarChartData(barData.series);
                } else {
                    console.error('Unexpected bar chart data format:', data.bar_chart);
                }

                if (data.vertical_bar_chart && Array.isArray(data.vertical_bar_chart)) {
                    const verticalBarDataValues = data.vertical_bar_chart.map(item => parseFloat(item.total_usd));
                    const verticalBarDataLabels = data.vertical_bar_chart.map(item => item.importer);

                    setVerticalBarChartData(verticalBarDataValues);
                    setVerticalBarChartCategories(verticalBarDataLabels);
                } else {
                    console.error('Unexpected vertical bar chart data format:', data.vertical_bar_chart);
                }

                if (data.line_chart && Array.isArray(data.line_chart)) {
                    const lineDataSeries = data.line_chart.reduce((acc, item) => {
                        const league = item.league || 'Unknown';
                        const existingSeries = acc.find(series => series.name === league);
                        if (existingSeries) {
                            existingSeries.data.push({ x: item.date, y: parseFloat(item.total_usd), continent: item.continent });
                        } else {
                            acc.push({ name: league, data: [{ x: item.date, y: parseFloat(item.total_usd), continent: item.continent }] });
                        }
                        return acc;
                    }, []);

                    setLineChartSeries(lineDataSeries);
                } else {
                    console.error('Unexpected line chart data format:', data.line_chart);
                }

                if (data.table_data && Array.isArray(data.table_data)) {
                    setTableData(data.table_data);
                    setHasMore(data.table_data.length >= perPage);
                } else {
                    console.error('Unexpected table data format:', data.table_data);
                    setHasMore(false);
                }
            } else {
                if (data.table_data && Array.isArray(data.table_data)) {
                    setTableData(prevTableData => [...prevTableData, ...data.table_data]);
                    setHasMore(data.table_data.length >= perPage);
                } else {
                    console.error('Unexpected table data format:', data.table_data);
                    setHasMore(false);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [csrfToken, selectedYear]);

    useEffect(() => {
        fetchData(page);
    }, [fetchData, page, selectedYear]);

    const handleLegendClick = (chartContext, seriesIndex, config) => {
        const continent = config.globals.labels[seriesIndex];
        setSelectedContinent(continent === selectedContinent ? null : continent);
        setPage(1);
        fetchData(1, continent === selectedContinent ? null : continent);
    };

    const pieChartOptions = useMemo(() => ({
        chart: {
            type: 'pie',
            foreColor: '#333',
            events: {
                legendClick: handleLegendClick
            }
        },
        labels: pieChartLabels,
        legend: {
            position: 'right',
            labels: {
                colors: '#333'
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
        tooltip: {
            y: {
                formatter: (value) => convertToReadableFormat(value)
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }), [pieChartLabels, selectedContinent]);

    const barChartOptions = useMemo(() => ({
        chart: {
            type: 'bar',
            stacked: true,
            foreColor: '#333'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => convertToReadableFormat(value),
            style: {
                colors: ['#000']
            },
            offsetX: 10,
            textAnchor: 'start'
        },
        xaxis: {
            categories: barChartCategories,
            labels: {
                formatter: (value) => convertToReadableFormat(value)
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => convertToReadableFormat(value)
            }
        },
        legend: {
            position: 'top',
            labels: {
                colors: '#333'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                    const league = w.globals.seriesNames[seriesIndex];
                    const continent = barChartCategories[dataPointIndex];
                    return `${league} in ${continent}: ${convertToReadableFormat(value)}`;
                }
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
    }), [barChartCategories, selectedContinent]);

    const verticalBarChartOptions = useMemo(() => ({
        chart: {
            type: 'bar',
            foreColor: '#333'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => convertToReadableFormat(value),
            style: {
                colors: ['#000']
            }
        },
        xaxis: {
            categories: verticalBarChartCategories,
            labels: {
                style: {
                    colors: '#333'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => convertToReadableFormat(value),
                style: {
                    colors: '#333'
                }
            }
        },
        legend: {
            position: 'top',
            labels: {
                colors: '#333'
            }
        },
        tooltip: {
            y: {
                formatter: (value) => convertToReadableFormat(value)
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
    }), [verticalBarChartCategories, selectedContinent]);

    const lineChartOptions = useMemo(() => ({
        chart: {
            type: 'line',
            foreColor: '#333'
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'MMM dd',
                style: {
                    colors: '#333'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => convertToReadableFormat(value),
                style: {
                    colors: '#333'
                }
            }
        },
        legend: {
            position: 'top',
            labels: {
                colors: '#333'
            }
        },
        tooltip: {
            x: {
                formatter: (value) => dayjs(value).format('MMM DD, YYYY')
            },
            y: {
                formatter: (value) => convertToReadableFormat(value)
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
        stroke: {
            curve: 'smooth'
        }
    }), [lineChartSeries, selectedContinent]);

    const columns = useMemo(() => [
        {
            Header: 'Exporter',
            accessor: 'exporter',
        },
        {
            Header: 'Value',
            accessor: 'total_usd',
            Cell: ({ value }) => convertToReadableFormat(value)
        },
        {
            id: 'expander',
            Header: () => null,
            Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? '🔽' : '▶️'}
                </span>
            ),
        },
    ], []);

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <div style={{ padding: '10px' }}>
                <table>
                    <thead>
                    <tr>
                        <th>Importer</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {row.original.importers.map(importer => (
                        <tr key={importer.importer}>
                            <td>{importer.importer}</td>
                            <td>{convertToReadableFormat(importer.total_usd)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        ),
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data: tableData,
        },
        useExpanded
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="year-select">Year</label>
                    <select
                        id="year-select"
                        className="form-control"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        <option value="All">All</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <div className="col-12 text-center">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <React.Fragment>
                    <div className="col-sm-8 col-lg-6">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Pakistan's Textile Exports (Region Wise)</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <Chart options={pieChartOptions} series={pieChartData} type="pie" height="200"/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-lg-6">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Export Distribution by League & Continent (By Volume)</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <Chart options={barChartOptions} series={barChartData} type="bar" height="200" />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-lg-6">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Top 10 Importers (Europe)</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <Chart options={verticalBarChartOptions} series={[{ data: verticalBarChartData }]} type="bar" height="200" />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-lg-6">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Institutional Product wise League A & B Imports (Europe)</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <Chart options={lineChartOptions} series={lineChartSeries} type="line" height="200" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Top Exporters and Importers</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <table {...getTableProps()} style={{ width: '100%', border: '1px solid black' }}>
                                    <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()} style={{ borderBottom: '1px solid black', background: 'aliceblue', color: 'black', fontWeight: 'bold' }}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row)
                                        return (
                                            <React.Fragment key={row.id}>
                                                <tr {...row.getRowProps()} style={{ background: 'white' }}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()} style={{ padding: '10px', border: '1px solid gray' }}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                                {row.isExpanded ? (
                                                    <tr>
                                                        <td colSpan={columns.length}>
                                                            {renderRowSubComponent({ row })}
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                {hasMore && (
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <button onClick={() => setPage(prevPage => prevPage + 1)} className="btn btn-primary">See More</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default ChartComponent;
