import React, { useEffect, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import QRCode from "qrcode";

const OrgChartComponent = () => {
    const chartRef = useRef();
    const chartInstance = useRef(null);
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [selectedQuality, setSelectedQuality] = useState("631A");

    const orgData = {
        "631A": [
            { id: "631A", name: "Quality 631A", color: "blue", parentId: null },
            { id: "fiber", name: "Fiber", color: "blue", parentId: "631A" },
            { id: "BCI Cotton", name: "BCI Cotton", color: "red", parentId: "fiber" },
            { id: "Shanharshah SSD", name: "Shanharshah SSD Cotton Ginning", color: "red", parentId: "BCI Cotton" },
            { id: "Decent Cotton", name: "Decent Cotton Ginning Pressing", color: "red", parentId: "BCI Cotton" },
            { id: "Dad Cotton Industries", name: "Dad Cotton Industries", color: "red", parentId: "BCI Cotton" },
            { id: "Lodhran Fibers", name: "Lodhran Fibers", color: "red", parentId: "BCI Cotton" },
            { id: "Khan Ginners Pvt. Ltd.", name: "Khan Ginners Pvt. Ltd.", color: "red", parentId: "BCI Cotton" },
            { id: "Rec Polyester", name: "Rec Polyester", color: "red", parentId: "fiber" },
            { id: "spinning", name: "Spinning", color: "blue", parentId: "631A" },
            { id: "STM-5", name: "STM-5", color: "blue", parentId: "spinning" },
            { id: "yarnDyeing", name: "Yarn Dyeing", color: "blue", parentId: "631A" },
            { id: "STM-10", name: "STM-10", color: "blue", parentId: "yarnDyeing" },
            { id: "weaving", name: "Weaving", color: "blue", parentId: "631A" },
            { id: "STM-6", name: "STM-6", color: "blue", parentId: "weaving" },
            { id: "processing", name: "Processing", color: "blue", parentId: "631A" },
            { id: "STM-9", name: "STM-9", color: "blue", parentId: "processing" },
            { id: "stitching", name: "Stitching", color: "blue", parentId: "631A" },
            { id: "STM-7", name: "STM-7", color: "blue", parentId: "stitching" },
            { id: "accessories", name: "Accessories", color: "blue", parentId: "631A" },
            { id: "Labels", name: "Labels", color: "red", parentId: "accessories" },
            { id: "Sitara Labels", name: "Sitara Labels", color: "red", parentId: "Labels" },
            { id: "Karim Labels", name: "Karim Labels", color: "red", parentId: "Labels" },
            { id: "Euro Labels", name: "Euro Labels", color: "red", parentId: "Labels" },
            { id: "Sewing Threads", name: "Sewing Threads", color: "blue", parentId: "accessories" },
            { id: "STM-10 Sewing", name: "STM-10", color: "blue", parentId: "Sewing Threads" },
            { id: "packaging", name: "Packaging", color: "blue", parentId: "631A" },
            { id: "Carton", name: "Carton", color: "red", parentId: "packaging" },
            { id: "JM Corporation", name: "JM Corporation", color: "red", parentId: "packaging" },
        ],
        "528(W)": [
            { id: "528W", name: "Quality 528(W)", color: "blue", parentId: null },
            { id: "fiber", name: "Fiber", color: "blue", parentId: "528W" },
            { id: "BCI Cotton", name: "BCI Cotton", color: "red", parentId: "fiber" },
            { id: "Shanharshah SSD", name: "Shanharshah SSD Cotton Ginning", color: "red", parentId: "BCI Cotton" },
            { id: "Decent Cotton", name: "Decent Cotton Ginning Pressing", color: "red", parentId: "BCI Cotton" },
            { id: "Dad Cotton Industries", name: "Dad Cotton Industries", color: "red", parentId: "BCI Cotton" },
            { id: "Lodhran Fibers", name: "Lodhran Fibers", color: "red", parentId: "BCI Cotton" },
            { id: "Khan Ginners Pvt. Ltd.", name: "Khan Ginners Pvt. Ltd.", color: "red", parentId: "BCI Cotton" },
            { id: "Polyester", name: "Polyester", color: "red", parentId: "fiber" },
            { id: "LCI Polyester Pakistan", name: "LCI Polyester Pakistan", color: "red", parentId: "Polyester" },
            { id: "spinning", name: "Spinning", color: "blue", parentId: "528W" },
            { id: "STM-4", name: "STM-4", color: "blue", parentId: "spinning" },
            { id: "weaving", name: "Weaving", color: "blue", parentId: "528W" },
            { id: "STM-6", name: "STM-6", color: "blue", parentId: "weaving" },
            { id: "processing", name: "Processing", color: "blue", parentId: "528W" },
            { id: "STM-9", name: "STM-9", color: "blue", parentId: "processing" },
            { id: "stitching", name: "Stitching", color: "blue", parentId: "528W" },
            { id: "STM-7", name: "STM-7", color: "blue", parentId: "stitching" },
            { id: "accessories", name: "Accessories", color: "blue", parentId: "528W" },
            { id: "Labels", name: "Labels", color: "red", parentId: "accessories" },
            { id: "Sewing Threads", name: "Sewing Threads", color: "blue", parentId: "accessories" },
            { id: "STM-10", name: "STM-10", color: "blue", parentId: "Sewing Threads" },
            { id: "packaging", name: "Packaging", color: "blue", parentId: "528W" },
            { id: "Carton", name: "Carton", color: "red", parentId: "packaging" },
            { id: "RecPolyBags", name: "Rec Poly Bags", color: "red", parentId: "packaging" },
        ],
    };

    const thumbnailImages = {
        "631A": [
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/34/original/EU_Ecolabel.png?1683014247",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/42/original/Global.png?1709286219",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/20/original/BCI_Cotton.png?1683004106",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/21/original/Oeko_Tex_MiG.png?1683004094",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/19/original/Oeko_Tex.png?1683004086"
        ],
        "528(W)": [
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/34/original/EU_Ecolabel.png?1683014247",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/19/original/Oeko_Tex.png?1683004086",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/20/original/BCI_Cotton.png?1683004106",
            "https://roadmap.sapphiretextiles.com.pk/system/certificates/avatars/21/original/Oeko_Tex_MiG.png?1683004094"
        ]
    };

    useEffect(() => {
        // Clear the chart container
        chartRef.current.innerHTML = '';

        // Initialize and render the org chart with the selected data
        const chart = new OrgChart()
            .container(chartRef.current)
            .nodeWidth(() => 200)
            .nodeHeight(() => 100)
            .childrenMargin(() => 40)
            .compactMarginBetween(() => 15)
            .compactMarginPair(() => 80)
            .nodeContent((d) => `
                <div style="border: 2px solid ${d.data.color === 'red' ? '#cd5c5c' : '#6aa0d7'};
                background-color: ${d.data.color === 'red' ? '#fdd' : '#d7e9f7'};
                border-radius: 8px; padding: 8px; text-align: center;">
                    <strong>${d.data.name}</strong>
                </div>
            `)
            .data(orgData[selectedQuality])
            .render();

        chartInstance.current = chart;

        // Generate the QR code for the current page URL
        const currentPageUrl = window.location.href + `?quality=${selectedQuality}`;
        generateQRCode(currentPageUrl);
    }, [selectedQuality]);

    const generateQRCode = (url) => {
        QRCode.toDataURL(url, { width: 200, margin: 2 }, (err, qrCodeDataUrl) => {
            if (err) {
                console.error(err);
            } else {
                setQrCodeUrl(qrCodeDataUrl);
            }
        });
    };

    const handleQualityChange = (e) => {
        setSelectedQuality(e.target.value);
    };

    const handleExpandAll = () => {
        if (chartInstance.current) {
            chartInstance.current.expandAll();
        }
    };

    const handleCollapseAll = () => {
        if (chartInstance.current) {
            chartInstance.current.collapseAll();
        }
    };
    const downloadQRCode = () => {
        if (qrCodeUrl) {
            const downloadLink = document.createElement("a");
            downloadLink.href = qrCodeUrl;
            downloadLink.download = `${selectedQuality}-org-chart-qr-code.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-2'} style={{ marginBottom: '20px' }}>
                    <select
                        className="form-control"
                        id="qualityDropdown"
                        value={selectedQuality}
                        onChange={handleQualityChange}
                    >
                        <option value="" disabled>Select quality</option>
                        <option value="631A">631A</option>
                        <option value="528(W)">528(W)</option>
                    </select>
                </div>

                <div style={{ marginBottom: '20px' }}>
                <button onClick={handleExpandAll} className="btn btn-primary">Expand All</button>
                <button onClick={handleCollapseAll} className="btn btn-primary" style={{ marginLeft: '10px' }}>Collapse All</button>
                {qrCodeUrl ? (
                    <button onClick={downloadQRCode} className="btn btn-success" style={{ marginLeft: '10px' }}>
                        Download QR Code
                    </button>
                ) : (
                    <p>Loading QR code...</p>
                )}
            </div>
            </div>
            <div style={styles.thumbnailContainer}>
                {thumbnailImages[selectedQuality].map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Certificate ${index}`}
                        style={styles.thumbnailImage}
                    />
                ))}
            </div>

            <div ref={chartRef} ></div>
        </div>
    );
};

// Inline styles
const styles = {
    thumbnailContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: '20px',
        flexWrap: "nowrap",
    },
    thumbnailImage: {
        width: "60px",
        height: "auto",
        margin: "0 10px",
        borderRadius: '8px',
        transition: "width 0.3s",
    },
    '@media (max-width: 768px)': {
        thumbnailImage: {
            width: "60px",
        }
    }
};

export default OrgChartComponent;
