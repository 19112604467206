import React from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont, faPalette } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const SidebarContainer = styled.div`
  background-color: #f8f9fa;
  height: 100%;
  padding: 20px;
  width: 250px;
  border-right: 1px solid #ddd;
`;

const StyledNavLink = styled(NavLink)`
  margin-left: 20px;
  margin-bottom: 15px;
  text-decoration: none;
  color: rgba(48, 48, 48, 1);
  &.active {
    color: #007bff;
    font-weight: bold;
  }
`;

const Sidebar = () => {
    return (
        <SidebarContainer>
            <Nav className="flex-column">
                <StyledNavLink
                    to="/sapphire_x_you/fonts"
                    className={({ isActive }) => `mt-5 ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faFont} /> Fonts
                </StyledNavLink>
            </Nav>
            <Nav className="flex-column">
                <StyledNavLink
                    to="/sapphire_x_you/colors"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <FontAwesomeIcon icon={faPalette} /> Colors
                </StyledNavLink>
            </Nav>
            <Nav className="flex-column">
                <StyledNavLink
                    to="/sapphire_x_you/designs"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                >
                    <i className="ri-pencil-ruler-2-fill"></i> Designs
                </StyledNavLink>
            </Nav>
        </SidebarContainer>
    );
};

export default Sidebar;
