import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Sidebar from "./Layout/Sidebar";
import TopNavBar from "./Layout/TopNavBar";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #f5f5f5;
`;

const PanelContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background: #f8f9fa;
  }
`;

const ActionButton = styled.button`
  background-color: rgba(48, 48, 48, 1);;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
`;

const ColorSetPanel = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  max-height: 600px;
  overflow-y: auto;
`;

const AddRowButton = styled.button`
  background-color: rgba(48, 48, 48, 1);;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
`;

const ColorPage = () => {
    const [creatingColorSet, setCreatingColorSet] = useState(false);
    const [colorSetRows, setColorSetRows] = useState([{ id: null, colorName: '', color: '#000000', color_type: 'Color', priceChange: '0', _destroy: false }]);
    const [colorSets, setColorSets] = useState([]);
    const [colorSetName, setColorSetName] = useState("");
    const [editingColorSet, setEditingColorSet] = useState(null);

    useEffect(() => {
        fetchColorSets();
    }, []);

    const fetchColorSets = () => {
        axios.get('/sapphire_x_you/color_sets')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setColorSets(response.data);
                } else {
                    setColorSets([]);
                    console.error('Color sets response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the color sets!', error);
            });
    };

    const addRow = () => {
        setColorSetRows([...colorSetRows, { id: null, colorName: '', color: '#000000', color_type: 'Color', priceChange: '0', _destroy: false }]);
    };

    const removeRow = (index) => {
        const newRows = [...colorSetRows];
        if (newRows[index].id) {
            newRows[index]._destroy = true; // Mark the existing color for deletion
        } else {
            newRows.splice(index, 1); // Remove new colors directly
        }
        setColorSetRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...colorSetRows];
        newRows[index][field] = value;
        setColorSetRows(newRows);
    };

    const handleCreateColorSet = () => {
        setCreatingColorSet(true);
        setEditingColorSet(null);
    };

    const handleEditColorSet = (colorSet) => {
        setColorSetName(colorSet.name);
        axios.get(`/sapphire_x_you/${colorSet.id}/show_color_set`)
            .then(response => {
                setColorSetRows(response.data.color_set_colors.map(colorSetColor => ({
                    id: colorSetColor.id,
                    colorName: colorSetColor.color_name,
                    color: colorSetColor.color,
                    color_type: colorSetColor.color_type,
                    priceChange: colorSetColor.price_change,
                    _destroy: false
                })));
                setEditingColorSet(colorSet.id);
                setCreatingColorSet(true);
            })
            .catch(error => {
                console.error('There was an error fetching the color set!', error);
            });
    };

    const handleCancelCreateColorSet = () => {
        setCreatingColorSet(false);
        setColorSetRows([{ id: null, colorName: '', color: '#000000', color_type: 'Color', priceChange: '0', _destroy: false }]);
        setColorSetName("");
    };

    const handleSubmitColorSet = (event) => {
        event.preventDefault();

        const colorSetData = {
            color_set: {
                name: colorSetName,
                color_set_colors_attributes: colorSetRows.map(row => ({
                    id: row.id,
                    color_name: row.colorName,
                    color: row.color,
                    color_type: row.color_type,
                    price_change: row.priceChange,
                    _destroy: row._destroy
                }))
            }
        };

        if (editingColorSet) {
            axios.put(`/sapphire_x_you/${editingColorSet}/update_color_set`, colorSetData)
                .then(response => {
                    alert('Color set updated successfully');
                    setCreatingColorSet(false);
                    fetchColorSets();
                })
                .catch(error => {
                    alert('Failed to update color set');
                    console.error('There was an error updating the color set!', error);
                });
        } else {
            axios.post('/sapphire_x_you/create_color_set', colorSetData)
                .then(response => {
                    alert('Color set created successfully');
                    setCreatingColorSet(false);
                    fetchColorSets();
                })
                .catch(error => {
                    alert('Failed to create color set');
                    console.error('There was an error creating the color set!', error);
                });
        }
    };

    return (
        <PageContainer>
            <TopNavBar />
            <MainContent>
                <Sidebar />
                <ContentArea>
                    {!creatingColorSet ? (
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ActionButton onClick={handleCreateColorSet}><FontAwesomeIcon icon={faPlus} /> Create color set</ActionButton>
                            </div>
                            <PanelContainer>
                                <SearchBar type="text" placeholder="Filter search" />
                                <TableContainer>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Set title</th>
                                            <th>Colors</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {colorSets.map(colorSet => (
                                            <tr key={colorSet.id}>
                                                <td>{colorSet.name}</td>
                                                <td>{colorSet.colors_count}</td>
                                                <td>
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEditColorSet(colorSet)} style={{ cursor: 'pointer' }} />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            </PanelContainer>
                        </React.Fragment>
                    ) : (
                        <ColorSetPanel>
                            <form onSubmit={handleSubmitColorSet}>
                                <div style={{ marginBottom: '20px' }}>
                                    <label>Color Set Name:</label>
                                    <input
                                        type="text"
                                        value={colorSetName}
                                        onChange={(e) => setColorSetName(e.target.value)}
                                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                    />
                                </div>
                                {colorSetRows.filter(row => !row._destroy).map((row, index) => (
                                    <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Color Name:</label>
                                            <input
                                                type="text"
                                                value={row.colorName}
                                                onChange={(e) => handleInputChange(index, 'colorName', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Type:</label>
                                            <select
                                                value={row.color_type}
                                                onChange={(e) => handleInputChange(index, 'color_type', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            >
                                                <option value="Color">Color</option>
                                                <option value="Pattern">Pattern</option>
                                            </select>
                                        </div>
                                        <div style={{ flex: 1, marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '10px' }}>Color:</label>
                                            <input
                                                type="color"
                                                value={row.color}
                                                onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                                style={{ width: '40px', height: '40px', padding: '0', border: '1px solid #ddd' }}
                                            />
                                            <input
                                                type="text"
                                                value={row.color}
                                                onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginLeft: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>

                                        <div style={{ flex: 1, marginRight: '10px' }}>
                                            <label>Price Change:</label>
                                            <input
                                                type="text"
                                                value={row.priceChange}
                                                onChange={(e) => handleInputChange(index, 'priceChange', e.target.value)}
                                                style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => removeRow(index)}
                                                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} color="#dc3545" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <AddRowButton type="button" onClick={addRow}>+ Add Row</AddRowButton>
                                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        type="button"
                                        onClick={handleCancelCreateColorSet}
                                        style={{ backgroundColor: '#6c757d', padding: '10px 20px', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </ColorSetPanel>
                    )}
                </ContentArea>
            </MainContent>
        </PageContainer>
    );
};

export default ColorPage;
