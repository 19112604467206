import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import CreateCollectionModal from './CreateCollectionModal';
import ApplySettingsModal from './ApplySettingsModal';
import empty from '../../images/10981633.jpg';
import TopNavBar from "./Layout/TopNavBar";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #f5f5f5;
`;

const PanelContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background: #f8f9fa;
  }
`;

const ActionButton = styled.button`
  background-color: rgba(48, 48, 48, 1);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
`;

const NoProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  color: #888;
`;

const ProductPage = () => {
    const [collections, setCollections] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isApplySettingsModalOpen, setIsApplySettingsModalOpen] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(null);

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = () => {
        axios.get('/sapphire_x_you/show_collection')
            .then(response => setCollections(response.data))
            .catch(error => console.error('Error fetching collections:', error));
    };

    const openCreateModal = () => {
        setSelectedCollection(null);
        setIsCreateModalOpen(true);
    };

    const openEditModal = (collection) => {
        setSelectedCollection(collection);
        setIsCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
        fetchCollections(); // Refresh the collection list after creating or editing a collection
    };

    const openApplySettingsModal = () => {
        setIsApplySettingsModalOpen(true);
    };

    const closeApplySettingsModal = () => {
        setIsApplySettingsModalOpen(false);
        fetchCollections(); // Refresh the collection list after applying settings
    };

    return (
        <PageContainer>
            <TopNavBar />
            <MainContent>
                <ContentArea>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ActionButton onClick={openCreateModal}><FontAwesomeIcon icon={faPlus} /> Create Collection</ActionButton>
                        <ActionButton onClick={openApplySettingsModal}><FontAwesomeIcon icon={faEdit} /> Apply Settings</ActionButton>
                    </div>
                    <PanelContainer>
                        <SearchBar type="text" placeholder="Product Title" />
                        <TableContainer>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Collection Name</th>
                                    <th>Products Count</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {collections.length === 0 ? (
                                    <tr>
                                        <td colSpan="3">
                                            <NoProducts>
                                                <img src={empty} alt="No collections found" style={{ width: "10%" }} />
                                                <p>No collections found</p>
                                            </NoProducts>
                                        </td>
                                    </tr>
                                ) : (
                                    collections.map(collection => (
                                        <tr key={collection.id}>
                                            <td>{collection.name}</td>
                                            <td>{collection.products.length}</td>
                                            <td>
                                                <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} onClick={() => openEditModal(collection)} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </Table>
                        </TableContainer>
                    </PanelContainer>
                    {isCreateModalOpen && <CreateCollectionModal onClose={closeCreateModal} existingCollection={selectedCollection} />}
                    {isApplySettingsModalOpen && <ApplySettingsModal onClose={closeApplySettingsModal} />}
                </ContentArea>
            </MainContent>
        </PageContainer>
    );
};

export default ProductPage;
