import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt, faTimes, faEdit, faShare } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import background from '../../images/gold.jpg';

// ProfileCard Component
const colors = ['#5a94fb', '#17eb76', '#3c2894', '#a38cf8', '#17c613'];

const ProfileCard = ({ id, name, position, phone, email, address, organization, imageUrl, onDelete, onEdit, onShare, showVCard }) => {
    const generateVCard = () => {
        return `BEGIN:VCARD
VERSION:3.0
FN:${name}
ORG:${organization}
TITLE:${position}
TEL;TYPE=CELL:${phone}
EMAIL:${email}
ADR:;;${address};;;
END:VCARD`;
    };

    const generateProfileUrl = () => {
        return `https://7e9a-72-255-39-15.ngrok-free.app/digital_profile/profile/${id}`;
    };

    const styles = {
        card: {
            width: '555px',
            height: '300px',
            borderRadius: '30px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            backgroundImage: `url(${background})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            fontFamily: 'Arial, sans-serif',
            padding: '20px',
            position: 'relative',
        },
        cardHover: {
            transform: 'scale(1.05)',
        },
        leftSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '10px',
        },
        rightSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px 20px',
        },
        photo: {
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginBottom: '10px',
        },
        heading: {
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '5px',
            color: '#555770',
        },
        position: {
            fontSize: '16px',
            marginBottom: '15px',
        },
        contactInfo: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
        },
        contactItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
        },
        icon: {
            marginRight: '10px',
            color: '#555770',
        },
        qrCode: {
            alignSelf: 'flex-start',
            marginBottom: '10px',
        },
        buttonGroup: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: '10px',
        },
        button: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#000',
            cursor: 'pointer',
        },
        deleteButton: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#ff4d4d',
            cursor: 'pointer',
        },
    };

    return (
        <div style={styles.card} className="profile-card" onMouseOver={(e) => e.currentTarget.style.transform = styles.cardHover.transform}
             onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>

            <div style={styles.leftSection}>
                <img src={imageUrl} alt="Profile" style={styles.photo} />
                <QRCode value={showVCard ? generateVCard() : generateProfileUrl()} size={100} style={styles.qrCode} />
            </div>
            <div style={styles.rightSection}>
                <h2 style={styles.heading}>{name}</h2>
                <p style={styles.position}>{organization}</p>
                <p style={styles.position}>{position}</p>
                <div style={styles.contactInfo}>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faPhone} style={styles.icon} />
                        {phone}
                    </div>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
                        {email}
                    </div>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} style={styles.icon} />
                        {address}
                    </div>
                </div>
            </div>

            <div style={styles.buttonGroup}>
                <button style={styles.button} onClick={() => onEdit(id)}>
                    <FontAwesomeIcon icon={faEdit} />
                </button>
                <button style={styles.button} onClick={() => onShare(id)}>
                    <FontAwesomeIcon icon={faShare} />
                </button>
                <button style={styles.deleteButton} onClick={() => onDelete(id)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        </div>
    );
};

// ProfileModal Component
const ProfileModal = ({ showModal, setShowModal, addProfile, profileToEdit, setProfileToEdit }) => {
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        organization: '',
        phone: '',
        email: '',
        address: '',
        photo: null,
    });

    useEffect(() => {
        if (profileToEdit) {
            setFormData(profileToEdit);
        }
    }, [profileToEdit]);

    const [emailError, setEmailError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, photo: file });
    };

    const handleSave = async () => {
        if (!formData.email.includes('@')) {
            setEmailError('Invalid email address');
            return;
        }
        setEmailError('');

        const profileData = new FormData();
        for (let key in formData) {
            profileData.append(`profile[${key}]`, formData[key]);
        }

        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const response = await fetch(`/digital_profile${profileToEdit ? `/${profileToEdit.id}` : ''}`, {
                method: profileToEdit ? 'PATCH' : 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                body: profileData,
            });

            if (response.ok) {
                const data = await response.json();
                addProfile(data.profile);
                setShowModal(false);
                setProfileToEdit(null);
            } else {
                console.error('Error creating profile:', await response.json());
            }
        } catch (error) {
            console.error('Error creating profile:', error);
        }
    };

    const isSaveDisabled = !formData.name || !formData.email;

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header>
                <Modal.Title>{profileToEdit ? 'Edit Profile' : 'Add New Profile'}</Modal.Title>
                <Button variant="link" onClick={() => { setShowModal(false); setProfileToEdit(null); }} style={{ position: 'absolute', right: '15px', top: '15px' }}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="name">
                                <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter name"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="position">
                                <Form.Label>Position<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="position"
                                    value={formData.position}
                                    onChange={handleInputChange}
                                    placeholder="Enter position"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="organization">
                                <Form.Label>Organization<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="organization"
                                    value={formData.organization}
                                    onChange={handleInputChange}
                                    placeholder="Enter organization"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="phone">
                                <Form.Label>Phone<span className="text-danger">*</span></Form.Label>
                                <InputMask
                                    mask="+\92999-9999999"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                >
                                    {(inputProps) => <Form.Control
                                        {...inputProps}
                                        type="text"
                                        name="phone"
                                        placeholder="(e.g., +92XXX-XXXXXXX or 0092XXX-XXXXXXX)"
                                    />}
                                </InputMask>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Enter email address"
                                />
                                {emailError && <div className="text-danger">{emailError}</div>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    placeholder="Enter address"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="photo">
                        <Form.Label>Profile Photo</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowModal(false); setProfileToEdit(null); }}>Close</Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaveDisabled}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

// ConfirmationModal Component
const ConfirmationModal = ({ show, handleClose, handleConfirm }) => {
    return (
        <Modal show={show} onHide={handleClose} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this profile?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="danger" onClick={handleConfirm}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
};

// ProfileApp Component
const ProfileApp = () => {
    const [profiles, setProfiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [profileToDelete, setProfileToDelete] = useState(null);
    const [profileToEdit, setProfileToEdit] = useState(null);
    const [showVCard, setShowVCard] = useState(true); // State to toggle QR code type

    const addProfile = (profile) => {
        setProfiles((prevProfiles) => [...prevProfiles, profile]);
    };

    const editProfile = (profile) => {
        setProfiles((prevProfiles) => prevProfiles.map(p => p.id === profile.id ? profile : p));
    };

    const deleteProfile = async (id) => {
        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const response = await fetch(`/digital_profile/${id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
            });

            if (response.ok) {
                setProfiles((prevProfiles) => prevProfiles.filter(profile => profile.id !== id));
            } else {
                console.error('Error deleting profile:', await response.json());
            }
        } catch (error) {
            console.error('Error deleting profile:', error);
        }
    };

    const confirmDelete = (id) => {
        setProfileToDelete(id);
        setShowConfirmationModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const response = await fetch(`/digital_profile/${profileToDelete}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
            });

            if (response.ok) {
                setProfiles((prevProfiles) => prevProfiles.filter(profile => profile.id !== profileToDelete));
                setProfileToDelete(null);
            } else {
                console.error('Error deleting profile:', await response.json());
            }
        } catch (error) {
            console.error('Error deleting profile:', error);
        } finally {
            setShowConfirmationModal(false);
        }
    };


    const handleEdit = (id) => {
        const profile = profiles.find(p => p.id === id);
        setProfileToEdit(profile);
        setShowModal(true);
    };

    const handleShare = async (id) => {
        const profile = profiles.find(p => p.id === id);
        const qrCodeValue = showVCard
            ? `BEGIN:VCARD\nVERSION:3.0\nFN:${profile.name}\nORG:${profile.organization}\nTITLE:${profile.position}\nTEL;TYPE=CELL:${profile.phone}\nEMAIL:${profile.email}\nADR:;;${profile.address};;;\nEND:VCARD`
            : `/digital_profile/profile/${id}`;

        const qrCodeSvgString = ReactDOMServer.renderToString(<QRCode value={qrCodeValue} />);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const img = new Image();

        img.onload = () => {
            canvas.width = img.width * 4;
            canvas.height = img.height * 4;
            context.drawImage(img, 0, 0, canvas.width, canvas.height);

            const qrCodeLink = document.createElement('a');
            qrCodeLink.href = canvas.toDataURL('image/png');
            qrCodeLink.download = `${profile.name}_QRCode.png`;
            qrCodeLink.click();
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(qrCodeSvgString);
    };

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const response = await fetch('/digital_profile/profile_details');
                if (response.ok) {
                    const data = await response.json();
                    setProfiles(data.profiles);
                } else {
                    console.error('Error fetching profiles:', await response.json());
                }
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        fetchProfiles();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginBottom: '20px' }}>Add New Profile</Button>
            <Button variant="secondary" onClick={() => setShowVCard(prev => !prev)} style={{ marginBottom: '20px', marginLeft: '10px' }}>
                {showVCard ? 'Show Profile QR Code' : 'Show vCard QR Code'}
            </Button>
            <ProfileModal showModal={showModal} setShowModal={setShowModal} addProfile={profileToEdit ? editProfile : addProfile} profileToEdit={profileToEdit} setProfileToEdit={setProfileToEdit} />
            <ConfirmationModal
                show={showConfirmationModal}
                handleClose={() => setShowConfirmationModal(false)}
                handleConfirm={handleConfirmDelete}
            />
            <div className="profile-card-container">
                {profiles.map((profile, index) => (
                    <ProfileCard key={index} {...profile} onDelete={confirmDelete} onEdit={handleEdit} onShare={handleShare} showVCard={showVCard} />
                ))}
            </div>
        </div>
    );
};

export default ProfileApp;