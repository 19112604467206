import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import TopNavBar from "../../Organization/Layout/TopNavBar";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import toast, { Toaster } from 'react-hot-toast';

const Departments = () => {
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentDepartment, setCurrentDepartment] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [departmentToDelete, setDepartmentToDelete] = useState(null);

    useEffect(() => {
        fetchCompanies();
        fetchDepartments();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get("/companies/fetch_company");
            setCompanies(response.data);
        } catch (error) {
            toast.error("Error fetching companies");
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get("/departments/fetch_dept");
            setDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const handleSave = async (department) => {
        try {
            if (currentDepartment) {
                await toast.promise(
                    axios.put(`/departments/${currentDepartment.id}`, department),
                    {
                        loading: 'Saving...',
                        success: 'Department updated successfully!',
                        error: 'Error saving department',
                    },
                    { position: 'top-center' }
                );
            } else {
                await toast.promise(
                    axios.post("/departments", department),
                    {
                        loading: 'Saving...',
                        success: 'Department created successfully!',
                        error: 'Error saving department',
                    },
                    { position: 'top-center' }
                );
            }
            setShowModal(false);
            setCurrentDepartment(null);
            fetchDepartments();
        } catch (error) {
            toast.error("Error saving department");
        }
    };

    const handleCreate = () => {
        setCurrentDepartment(null);
        setShowModal(true);
    };

    const handleEdit = (department) => {
        setCurrentDepartment(department);
        setShowModal(true);
    };

    const confirmDelete = (departmentId) => {
        setDepartmentToDelete(departmentId);
        setShowConfirmModal(true);
    };

    const handleDelete = async () => {
        try {
            await toast.promise(
                axios.delete(`/departments/${departmentToDelete}`),
                {
                    loading: 'Deleting...',
                    success: 'Department deleted successfully!',
                    error: 'Error deleting department',
                },
                { position: 'top-center' }
            );
            setShowConfirmModal(false);
            fetchDepartments();
        } catch (error) {
            toast.error("Error deleting department");
        }
    };

    return (
        <div className="col-lg-12">
            <TopNavBar />
            <Toaster position="top-center" reverseOrder={false} />
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                    <button className="btn btn-primary mr-2" onClick={handleCreate}>
                        <FontAwesomeIcon icon={faPlus} /><span className="pl-1">Create Department</span>
                    </button>
                    <div className="iq-header-title">
                        <h4 className="card-title">Department Management</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    {departments.length === 0 ? (
                        <div style={{
                            backgroundColor: '#eaf6ea',
                            borderLeft: '3px solid #5cb85c',
                            padding: '10px 15px',
                            color: 'rgb(22, 160, 133)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '0',
                        }}>
                            No Record Found!
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <Table className="table mb-0 mt-2 table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" className="font-weight-bold">Sr. no.</th>
                                    <th scope="col" className="font-weight-bold">Name</th>
                                    <th scope="col" className="font-weight-bold">Short Name</th>
                                    <th scope="col" className="font-weight-bold">Company</th>
                                    <th scope="col" className="font-weight-bold">Status</th>
                                    <th scope="col" className="font-weight-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {departments.map((department, index) => (
                                    <tr key={department.id}>
                                        <td>{index + 1}</td>
                                        <td>{department.name}</td>
                                        <td>{department.short_name}</td>
                                        <td>{department.company ? department.company.name : ''}</td>
                                        <td>
                                                <span className={`badge rounded-pill ${department.status ? 'badge-info' : 'badge-danger'}`}>
                                                    {department.status ? 'Active' : 'Inactive'}
                                                </span>
                                        </td>
                                        <td>
                                            <a onClick={() => handleEdit(department)} className="animate">
                                                <FontAwesomeIcon icon={faEdit} className="text-success pr-1 font-size-18" />
                                            </a>
                                            <a onClick={() => confirmDelete(department.id)} className="animate">
                                                <FontAwesomeIcon icon={faTrash} className="text-danger font-size-18" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>

            <DepartmentModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onSave={handleSave}
                department={currentDepartment}
                companies={companies}
            />

            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={handleDelete}
                message="Are you sure you want to delete this department?"
            />
        </div>
    );
};

const DepartmentModal = ({ show, onHide, onSave, department, companies }) => {
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (department) {
            setName(department.name);
            setShortName(department.short_name);
            setCompanyId(department.company_id);
            setStatus(department.status);
        } else {
            setName("");
            setShortName("");
            setCompanyId("");
            setStatus(false);
        }
    }, [department]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ name, short_name: shortName, company_id: companyId, status });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header style={{ backgroundColor: 'rgb(22, 160, 133)', color: 'white' }}>
                <Modal.Title style={{ color: 'white' }}>{department ? "Edit Department" : "Create Department"}</Modal.Title>
                <Button variant="link" onClick={onHide} style={{ color: 'white', padding: '0' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body style={{ padding: '20px', borderRadius: '0 0 15px 15px' }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            as="select"
                            value={companyId}
                            onChange={(e) => setCompanyId(e.target.value)}
                            required
                        >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Short Name (Enter 2 letters only)</Form.Label>
                        <Form.Control
                            type="text"
                            value={shortName}
                            onChange={(e) => setShortName(e.target.value)}
                            required
                            maxLength={2}
                            placeholder="Enter short name"
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customDept"
                                checked={status}
                                onChange={(e) => setStatus(e.target.checked)}
                            />
                            <label className="custom-control-label" htmlFor="customDept">
                                Active/InActive
                            </label>
                        </div>
                    </Form.Group>
                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="secondary" onClick={onHide} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', borderRadius: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" style={{ backgroundColor: '#007aff', borderColor: '#007aff', borderRadius: '10px' }}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Departments;
