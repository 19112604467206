import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import './Xyou.css';
import x_you from '../images/SAPPHIRE_X.png';
import round from '../images/zU7tIX01.svg';
import tail from '../images/1ngRmc01.svg';
import diamond from '../images/jRhzTG01.svg';
import Inline from '../images/na5mO801.svg';
import Script from '../images/r9tmo801.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 20%;
`;

const ProductContainer = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
`;

const ZoomControls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const ZoomButton = styled.button`
  background-color: #6546D2FF;
  color: white;
  width: 35px;
  border: none;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 27px;

  &:hover {
    background-color: #CCC1F0FF;
  }
`;

const PanelFormGroup = styled(Form.Group)`
  margin-top: 15px;
`;

const PanelContainerStyled = styled.div`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  flex: 1;
`;

const ColorPalette = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 10px;
  margin-top: 5px;
`;

const ColorSwatchBase = ({ $isselected, ...props }) => <div {...props} />;

const ColorSwatch = styled(ColorSwatchBase)`
  width: 30px;
  height: 30px;
  background-color: ${props => props.color};
  cursor: pointer;
  border: 2px solid ${props => (props.$isselected ? '#007bff' : 'transparent')};
  box-shadow: ${props => (props.$isselected ? '0 0 5px 2px rgba(0, 123, 255, 0.5)' : 'none')};
`;

const MonogramStyleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const MonogramStyle = styled.div`
  width: 60px;
  height: 60px;
  margin: 5px;
  border: 2px solid ${props => (props.$isselected ? '#007bff' : '#ddd')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
`;

const EngraveStyleButton = styled.button`
  margin-right: 10px;
  padding: 5px 10px;
  background-color: ${props => (props.$isselected ? '#007bff' : '#fff')};
  color: ${props => (props.$isselected ? '#fff' : '#007bff')};
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.$isselected ? '#0056b3' : '#e0e0e0')};
  }
`;

const FontResizeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const FontResizeButton = styled.button`
  background-color: #6546D2FF;
  color: white;
  width: 35px;
  border: none;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 27px;

  &:hover {
    background-color: #CCC1F0FF;
  }
`;

const monogramStyles = [
    { id: 1, style: 'style1', image: round },
    { id: 2, style: 'style2', image: diamond },
    { id: 3, style: 'style3', fontFamily: 'WitchesCrow', image: tail },
    { id: 4, style: 'style4', fontFamily: 'LagniappeInlineNF', image: Inline },
    { id: 5, style: 'style5', fontFamily: 'AguafinaScript', image: Script },
];

const createMonogramSVG = (text, fontFamily, color) => {
    if (text.length === 0) return ''; // Return an empty string if there is no text
    if (fontFamily === 'style1') {
        return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <text x="10" y="50" font-size="40" fill="${color}" font-family="LeftCircleMonogram">${text[0]}</text>
        <text x="21" y="53.1" font-size="50" fill="${color}" font-family="MiddleCircleMonogram">${text[1]}</text>
        <text x="33.5" y="50" font-size="40" fill="${color}" font-family="RightCircleMonogram">${text[2]}</text>
      </svg>`;
    } else if (fontFamily === 'style2') {
        return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <text x="38" y="61" font-size="51" fill="${color}" font-family="LeftCircleMonogram" text-anchor="middle">${text[0]}</text>
        <text x="50" y="55" font-size="40" fill="${color}" font-family="Round_Monogram_Center" text-anchor="middle">${text[1]}</text>
        <text x="61.4" y="55" font-size="40" fill="${color}" font-family="Round_Monogram_Right" text-anchor="middle">${text[2]}</text>
      </svg>`;
    } else {
        return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <text x="10" y="50" font-size="40" fill="${color}" font-family="${fontFamily}">${text}</text>
      </svg>`;
    }
};

const Xyou = () => {
    const [customizations, setCustomizations] = useState([]);
    const [baseImage, setBaseImage] = useState(null);
    const [text, setText] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [selectedMonogramStyle, setSelectedMonogramStyle] = useState(monogramStyles[0]);
    const [engraveStyle, setEngraveStyle] = useState('initial');
    const [fontSize, setFontSize] = useState(40);
    const [selectedFont, setSelectedFont] = useState('Arial');
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [colorSet, setColorSet] = useState([]);
    const [fontSet, setFontSet] = useState([]);

    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);

    useEffect(() => {
        if (selectedCollection) {
            axios.get(`/sapphire_x_you/${selectedCollection}/collection_products`)
                .then(response => {
                    const data = response.data;
                    setProducts(data.products || []);
                })
                .catch(error => {
                    console.error("Error fetching collection products:", error);
                });

            axios.get(`/sapphire_x_you/${selectedCollection}/related_sets`)
                .then(response => {
                    const data = response.data;
                    setColorSet(data.color_sets.map(set => set.colors).flat() || []);
                    setFontSet(data.font_sets.map(set => set.fonts).flat() || []);
                })
                .catch(error => {
                    console.error("Error fetching related sets:", error);
                });
        }
    }, [selectedCollection]);


    useEffect(() => {
        axios.get('/sapphire_x_you/show_collection')
            .then(response => {
                setCollections(response.data);
            })
            .catch(error => {
                console.error("Error fetching collections:", error);
            });
    }, []);

    useEffect(() => {
        if (canvasRef.current) {
            const canvasInstance = new fabric.Canvas(canvasRef.current, {
                width: 500,
                height: 500,
            });
            setCanvas(canvasInstance);
        }
    }, []);

    useEffect(() => {
        if (canvas && baseImage) {
            fabric.Image.fromURL(baseImage, (img) => {
                img.scaleToWidth(500);
                img.scaleToHeight(500);
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            });
        }
    }, [canvas, baseImage]);

    useEffect(() => {
        if (canvas) {
            canvas.clear();
            if (baseImage) {
                fabric.Image.fromURL(baseImage, (img) => {
                    img.scaleToWidth(500);
                    img.scaleToHeight(500);
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
                });
            }

            customizations.forEach((custom) => {
                if (custom.content.type === 'text') {
                    const text = new fabric.Text(custom.content.text, {
                        left: custom.style.left,
                        top: custom.style.top,
                        fill: custom.content.color,
                        fontFamily: custom.content.font,
                        fontSize: custom.content.fontSize,
                        originX: 'center',
                        originY: 'center',
                    });
                    canvas.add(text);
                } else if (custom.content.type === 'svg') {
                    fabric.loadSVGFromString(custom.content.svg, (objects, options) => {
                        const svg = fabric.util.groupSVGElements(objects, options);
                        svg.set({
                            left: custom.style.left,
                            top: custom.style.top,
                            originX: 'center',
                            originY: 'center',
                            scaleX: custom.style.scaleX,
                            scaleY: custom.style.scaleY,
                        });
                        canvas.add(svg);
                        svg.on('modified', () => {
                            custom.style.left = svg.left;
                            custom.style.top = svg.top;
                            custom.style.scaleX = svg.scaleX;
                            custom.style.scaleY = svg.scaleY;
                        });
                    });
                }
            });
            canvas.renderAll();
        }
    }, [canvas, customizations, baseImage]);

    const exportToPDF = () => {
        if (canvas) {
            const pdf = new jsPDF('landscape', 'px', 'a4');
            const imgData = canvas.toDataURL({
                format: 'png',
                multiplier: 2
            });
            pdf.addImage(imgData, 'PNG', 20, 20, 400, 400);
            pdf.save('customization.pdf');
        }
    };

    const zoomIn = () => {
        if (canvas) {
            const newZoom = canvas.getZoom() * 1.1;
            canvas.setZoom(newZoom);
        }
    };

    const zoomOut = () => {
        if (canvas) {
            const newZoom = canvas.getZoom() * 0.9;
            canvas.setZoom(newZoom);
        }
    };

    const handleUpdateCustomization = (custom) => {
        setCustomizations([custom]);
    };

    const handleUploadProductImage = (image) => {
        setBaseImage(image);
    };

    const handleTextChange = (e) => {
        const newText = e.target.value.slice(0, engraveStyle === 'monogram' ? 3 : 100);
        setText(newText);
    };

    const handleTextColorChange = (color) => {
        setTextColor(color);
    };

    const handleMonogramStyleChange = (style) => {
        setSelectedMonogramStyle(style);
        if (text.length > 0) {
            handleUpdateCustomization({
                content: {
                    type: 'svg',
                    svg: createMonogramSVG(text, style.fontFamily || style.style, textColor),
                    color: textColor
                },
                style: { left: 250, top: 250, scaleX: 1.5, scaleY: 1.5 },
            });
        }
    };

    const handleEngraveStyleChange = (style) => {
        setEngraveStyle(style);
        setText(''); // Clear the text input when changing styles
        if (style === 'monogram') {
            handleMonogramStyleChange(selectedMonogramStyle);
        } else {
            handleUpdateCustomization({
                content: {
                    type: 'text',
                    text: '',
                    color: textColor,
                    font: selectedFont,
                    fontSize: fontSize
                },
                style: { left: 250, top: 250, scaleX: 1.5, scaleY: 1.5 },
            });
        }
    };

    const increaseFontSize = () => {
        setFontSize(prevSize => prevSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize(prevSize => Math.max(prevSize - 2, 10));
    };

    const handleFontChange = (e) => {
        setSelectedFont(e.target.value);
    };

    useEffect(() => {
        if (text.length > 0 || engraveStyle !== 'monogram') {
            handleUpdateCustomization({
                content: {
                    type: engraveStyle === 'monogram' ? 'svg' : 'text',
                    text: text,
                    color: textColor,
                    font: selectedFont,
                    fontSize: fontSize,
                    svg: engraveStyle === 'monogram' ? createMonogramSVG(text, selectedMonogramStyle.fontFamily || selectedMonogramStyle.style, textColor) : undefined
                },
                style: { left: 250, top: 250, scaleX: 1.5, scaleY: 1.5 },
            });
        }
    }, [text, textColor, selectedMonogramStyle, engraveStyle, fontSize, selectedFont]);

    const handleCollectionChange = (e) => {
        setSelectedCollection(e.target.value);
        setSelectedProduct(''); // Reset selected product when collection changes
    };

    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
        const product = products.find(p => p.id === parseInt(e.target.value));
        if (product) {
            handleUploadProductImage(product.front_image_url);
        }
    };

    return (
        <AppContainer>
            <img src={x_you} className="img-fluid you-style mb-4" alt="" />

            <PanelContainer>
                <PanelContainerStyled>
                    <PanelFormGroup controlId="formCollectionSelect">
                        <Form.Label>Select Collection</Form.Label>
                        <Form.Control as="select" value={selectedCollection} onChange={handleCollectionChange}>
                            <option value="">Select Collection</option>
                            {collections.map(collection => (
                                <option key={collection.id} value={collection.id}>{collection.name}</option>
                            ))}
                        </Form.Control>
                    </PanelFormGroup>
                    <PanelFormGroup controlId="formProductSelect">
                        <Form.Label>Select Product</Form.Label>
                        <Form.Control as="select" value={selectedProduct} onChange={handleProductChange}>
                            <option value="">Select Product</option>
                            {products.map(product => (
                                <option key={product.id} value={product.id}>{product.name}</option>
                            ))}
                        </Form.Control>
                    </PanelFormGroup>

                    <Form.Label>Engrave Style</Form.Label>
                    <div>
                        <EngraveStyleButton $isselected={engraveStyle === 'initial'} onClick={() => handleEngraveStyleChange('initial')}>Initial</EngraveStyleButton>
                        <EngraveStyleButton $isselected={engraveStyle === 'name'} onClick={() => handleEngraveStyleChange('name')}>Name</EngraveStyleButton>
                        <EngraveStyleButton $isselected={engraveStyle === 'monogram'} onClick={() => handleEngraveStyleChange('monogram')}>Monogram</EngraveStyleButton>
                    </div>

                    {(engraveStyle === 'initial' || engraveStyle === 'name') && (
                        <React.Fragment>
                            <PanelFormGroup controlId="formText">
                                <Form.Label>{engraveStyle === 'initial' ? 'Initial' : 'Name'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={engraveStyle === 'initial' ? 'Enter initial' : 'Enter name'}
                                    value={text}
                                    maxLength={engraveStyle === 'initial' ? 1 : undefined}
                                    onChange={handleTextChange}
                                />
                            </PanelFormGroup>
                            <PanelFormGroup controlId="formFont">
                                <Form.Label>Choose Font</Form.Label>
                                <Form.Control as="select" value={selectedFont} onChange={handleFontChange}>
                                    <option value="">Select Font</option>
                                    {fontSet.map(font => (
                                        <option key={font.id} value={font.font_name}>{font.font_name}</option>
                                    ))}
                                </Form.Control>
                            </PanelFormGroup>
                            <PanelFormGroup controlId="formFontSize">
                                <Form.Label>Font Size</Form.Label>
                                <FontResizeContainer>
                                    <FontResizeButton onClick={decreaseFontSize}>-</FontResizeButton>
                                    <span>{fontSize}</span>
                                    <FontResizeButton onClick={increaseFontSize}>+</FontResizeButton>
                                </FontResizeContainer>
                            </PanelFormGroup>
                        </React.Fragment>
                    )}

                    {engraveStyle === 'monogram' && (
                        <React.Fragment>
                            <PanelFormGroup controlId="formText">
                                <Form.Label>Monogram</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter monogram"
                                    value={text}
                                    maxLength={3}
                                    onChange={handleTextChange}
                                />
                            </PanelFormGroup>
                            <Form.Label className="mt-3">Monogram Style</Form.Label>
                            <MonogramStyleContainer>
                                {monogramStyles.map(style => (
                                    <MonogramStyle
                                        key={style.id}
                                        $isselected={selectedMonogramStyle.style === style.style}
                                        onClick={() => handleMonogramStyleChange(style)}
                                    >
                                        <img className="style-custom" src={style.image} alt={style.style} />
                                    </MonogramStyle>
                                ))}
                            </MonogramStyleContainer>
                        </React.Fragment>
                    )}

                    <PanelFormGroup controlId="formMonogramColor">
                        <Form.Label>Color</Form.Label>
                        <ColorPalette>
                            {colorSet.map(color => (
                                <ColorSwatch
                                    key={color.id}
                                    color={color.color}
                                    $isselected={color.color === textColor}
                                    onClick={() => handleTextColorChange(color.color)}
                                />
                            ))}
                        </ColorPalette>
                    </PanelFormGroup>
                </PanelContainerStyled>

                <ProductContainer>
                    <ZoomControls>
                        <ZoomButton onClick={zoomIn}><i className="ri-zoom-in-line"></i></ZoomButton>
                        <ZoomButton onClick={zoomOut}><i className="ri-zoom-out-line"></i></ZoomButton>
                    </ZoomControls>
                    <CanvasContainer>
                        <canvas ref={canvasRef} />
                    </CanvasContainer>
                    <button onClick={exportToPDF} className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faFilePdf} />
                    </button>
                </ProductContainer>
            </PanelContainer>
        </AppContainer>
    );
};

export default Xyou;
