import React, {useState, useEffect} from 'react';
import axios from 'axios';

const ActivityStreams = () => {
    const [activityStreams, setActivityStreams] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchActivityStreams();
    }, []);

    const fetchActivityStreams = async () => {
        try {
            const response = await axios.get('/activity_streams');
            setActivityStreams(response.data.activity_stream);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching activity streams:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="col-lg-12">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-header d-flex justify-content-between">
                        <button className="btn btn-primary mr-2" data-toggle="modal" data-target="#userModal">
                            <i className="ri-search-2-line"><span className="pl-1">Filter</span></i>
                        </button>
                        <div className="iq-header-title">
                            <h4 className="card-title">Activity Streams</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="table-responsive">
                            <table className="table mb-2 mt-2 table-borderless" id="activityStreamsTable">
                                <thead>
                                <tr>
                                    <th scope="col">Sr #</th>
                                    <th scope="col" className="font-weight-bold">User Name</th>
                                    <th scope="col" className="font-weight-bold">User Email</th>
                                    <th scope="col" className="font-weight-bold">Action Performed</th>
                                    <th scope="col" className="font-weight-bold">Action Date Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loading ? (
                                    <tr id="loadingRow">
                                        <td colSpan="5" style={{textAlign: 'center', fontWeight: 'bold'}}>
                                            <div className="loader"></div>
                                            <span id="loadingText">Loading</span>
                                        </td>
                                    </tr>
                                ) : activityStreams.map((activityStream, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{activityStream.user_name || ''}</td>
                                        <td>{activityStream.email || ''}</td>
                                        <td>{activityStream.action_name || ''}</td>
                                        <td>{activityStream.action_datetime || ''}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="userModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Menu</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="iq-card-body">
                            {/* Your form content goes here */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityStreams;
