import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import TopNavBar from "../../Organization/Layout/TopNavBar";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import toast, { Toaster } from 'react-hot-toast';

const TicketTypes = () => {
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [mainTypes, setMainTypes] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentTicketType, setCurrentTicketType] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [ticketTypeToDelete, setTicketTypeToDelete] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

    useEffect(() => {
        fetchCompanies();
        fetchTicketTypes();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get("/companies/fetch_company");
            setCompanies(response.data);
        } catch (error) {
            toast.error("Error fetching companies");
        }
    };

    const fetchDepartments = async (companyId) => {
        try {
            const response = await axios.get("/departments/fetch_dept", { params: { company_id: companyId } });
            setDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const fetchLocations = async (companyId) => {
        try {
            const response = await axios.get("/locations/fetch_location", { params: { company_id: companyId } });
            setLocations(response.data);
        } catch (error) {
            toast.error("Error fetching locations");
        }
    };

    const fetchMainTypes = async (departmentId) => {
        try {
            const response = await axios.get("/main_types/fetch_main_types", { params: { department_id: departmentId } });
            setMainTypes(response.data);
        } catch (error) {
            toast.error("Error fetching main types");
        }
    };

    const fetchTicketTypes = async () => {
        try {
            const response = await axios.get("/ticket_types/fetch_ticket_types");
            setTicketTypes(response.data);
        } catch (error) {
            toast.error("Error fetching ticket types");
        }
    };

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        setSelectedCompanyId(companyId);
        if (companyId) {
            fetchDepartments(companyId);
            fetchLocations(companyId);
        } else {
            setDepartments([]);
            setLocations([]);
            setMainTypes([]);
        }
    };

    const handleDepartmentChange = (e) => {
        const departmentId = e.target.value;
        setSelectedDepartmentId(departmentId);
        if (departmentId) {
            fetchMainTypes(departmentId);
        } else {
            setMainTypes([]);
        }
    };

    const handleSave = async (ticketType) => {
        try {
            if (currentTicketType) {
                await toast.promise(
                    axios.put(`/ticket_types/${currentTicketType.id}`, ticketType),
                    {
                        loading: 'Saving...',
                        success: 'Ticket Type updated successfully!',
                        error: 'Error saving ticket type',
                    },
                    { position: 'top-center' }
                );
            } else {
                await toast.promise(
                    axios.post("/ticket_types", ticketType),
                    {
                        loading: 'Saving...',
                        success: 'Ticket Type created successfully!',
                        error: 'Error saving ticket type',
                    },
                    { position: 'top-center' }
                );
            }
            setShowModal(false);
            setCurrentTicketType(null);
            fetchTicketTypes();
        } catch (error) {
            toast.error("Error saving ticket type");
        }
    };

    const handleCreate = () => {
        setCurrentTicketType(null);
        setShowModal(true);
    };

    const handleEdit = (ticketType) => {
        setCurrentTicketType(ticketType);
        setShowModal(true);
    };

    const confirmDelete = (ticketTypeId) => {
        setTicketTypeToDelete(ticketTypeId);
        setShowConfirmModal(true);
    };

    const handleDelete = async () => {
        try {
            await toast.promise(
                axios.delete(`/ticket_types/${ticketTypeToDelete}`),
                {
                    loading: 'Deleting...',
                    success: 'Ticket Type deleted successfully!',
                    error: 'Error deleting ticket type',
                },
                { position: 'top-center' }
            );
            setShowConfirmModal(false);
            fetchTicketTypes();
        } catch (error) {
            toast.error("Error deleting ticket type");
        }
    };

    return (
        <div className="col-lg-12">
            <TopNavBar />
            <Toaster position="top-right" reverseOrder={false} />
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                    <button className="btn btn-primary mr-2" onClick={handleCreate}>
                        <FontAwesomeIcon icon={faPlus} /><span className="pl-1">Create SR Type</span>
                    </button>
                    <div className="iq-header-title">
                        <h4 className="card-title">SR Type Management</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    {ticketTypes.length === 0 ? (
                        <div style={{
                            backgroundColor: '#eaf6ea',
                            borderLeft: '3px solid #5cb85c',
                            padding: '10px 15px',
                            color: 'rgb(22, 160, 133)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '0',
                        }}>
                            No Record Found!
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <Table className="table mb-0 mt-2 table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" className="font-weight-bold">Sr. no.</th>
                                    <th scope="col" className="font-weight-bold">SR Name</th>
                                    {/*<th scope="col" className="font-weight-bold">Short Name</th>*/}
                                    <th scope="col" className="font-weight-bold">Company</th>
                                    <th scope="col" className="font-weight-bold">Location</th>
                                    <th scope="col" className="font-weight-bold">Department</th>
                                    <th scope="col" className="font-weight-bold">Sub Department</th>
                                    <th scope="col" className="font-weight-bold">Status</th>
                                    <th scope="col" className="font-weight-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ticketTypes.map((ticketType, index) => (
                                    <tr key={ticketType.id}>
                                        <td>{index + 1}</td>
                                        <td>{ticketType.name}</td>
                                        {/*<td>{ticketType.short_name}</td>*/}
                                        <td>{ticketType.company ? ticketType.company.name : ''}</td>
                                        <td>{ticketType.location ? ticketType.location.name : ''}</td>
                                        <td>{ticketType.department ? ticketType.department.name : ''}</td>
                                        <td>{ticketType.main_type ? ticketType.main_type.name : ''}</td>
                                        <td>
                                            <span className={`badge rounded-pill ${ticketType.status ? 'badge-info' : 'badge-danger'}`}>
                                                {ticketType.status ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td>
                                            <a onClick={() => handleEdit(ticketType)} className="animate">
                                                <FontAwesomeIcon icon={faEdit} className="text-success pr-1 font-size-18" />
                                            </a>
                                            <a onClick={() => confirmDelete(ticketType.id)} className="animate">
                                                <FontAwesomeIcon icon={faTrash} className="text-danger font-size-18" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>

            <TicketTypeModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onSave={handleSave}
                ticketType={currentTicketType}
                companies={companies}
                departments={departments}
                locations={locations}
                mainTypes={mainTypes}
                onCompanyChange={handleCompanyChange}
                onDepartmentChange={handleDepartmentChange}
                selectedCompanyId={selectedCompanyId}
                selectedDepartmentId={selectedDepartmentId}
            />

            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={handleDelete}
                message="Are you sure you want to delete this ticket type?"
            />
        </div>
    );
};

const TicketTypeModal = ({ show, onHide, onSave, ticketType, companies, departments, locations, mainTypes, onCompanyChange, onDepartmentChange, selectedCompanyId, selectedDepartmentId }) => {
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [departmentId, setDepartmentId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [mainTypeId, setMainTypeId] = useState("");
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (ticketType) {
            setName(ticketType.name);
            setShortName(ticketType.short_name);
            setCompanyId(ticketType.company_id);
            setDepartmentId(ticketType.department_id);
            setLocationId(ticketType.location_id);
            setMainTypeId(ticketType.main_type_id);
            setStatus(ticketType.status);
        } else {
            setName("");
            setShortName("");
            setCompanyId("");
            setDepartmentId("");
            setLocationId("");
            setMainTypeId("");
            setStatus(false);
        }
    }, [ticketType]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ name, short_name: shortName, company_id: companyId, department_id: departmentId, location_id: locationId, main_type_id: mainTypeId, status });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header style={{ backgroundColor: 'rgb(22, 160, 133)', color: 'white' }}>
                <Modal.Title style={{ color: 'white' }}>{ticketType ? "Edit Service Request Type" : "Create Service Request Type"}</Modal.Title>
                <Button variant="link" onClick={onHide} style={{ color: 'white', padding: '0' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body style={{ padding: '20px', borderRadius: '0 0 15px 15px' }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            as="select"
                            value={companyId}
                            onChange={(e) => {
                                setCompanyId(e.target.value);
                                onCompanyChange(e);
                            }}
                            required
                        >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            as="select"
                            value={locationId}
                            onChange={(e) => setLocationId(e.target.value)}
                            required
                            disabled={!selectedCompanyId}
                        >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                                <option key={location.id} value={location.id}>
                                    {location.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Department</Form.Label>
                        <Form.Control
                            as="select"
                            value={departmentId}
                            onChange={(e) => {
                                setDepartmentId(e.target.value);
                                onDepartmentChange(e);
                            }}
                            required
                            disabled={!selectedCompanyId}
                        >
                            <option value="">Select Department</option>
                            {departments.map((department) => (
                                <option key={department.id} value={department.id}>
                                    {department.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Sub Dept</Form.Label>
                        <Form.Control
                            as="select"
                            value={mainTypeId}
                            onChange={(e) => setMainTypeId(e.target.value)}
                            required
                            disabled={!selectedDepartmentId}
                        >
                            <option value="">Select Main Type</option>
                            {mainTypes.map((mainType) => (
                                <option key={mainType.id} value={mainType.id}>
                                    {mainType.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Short Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={shortName}
                            onChange={(e) => setShortName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customTicketType"
                                checked={status}
                                onChange={(e) => setStatus(e.target.checked)}
                            />
                            <label className="custom-control-label" htmlFor="customTicketType">
                                Active/InActive
                            </label>
                        </div>
                    </Form.Group>
                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="secondary" onClick={onHide} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f', borderRadius: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" style={{ backgroundColor: '#007aff', borderColor: '#007aff', borderRadius: '10px' }}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default TicketTypes;
