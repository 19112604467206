import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { RiSendPlane2Fill, RiRobotLine, RiSunLine, RiTextDirectionR } from 'react-icons/ri';
import './FloatMenu.css';
import SpeechHandler from './SpeechHandler';

const FloatMenu = () => {
    const [chatOpen, setChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [processedTranscript, setProcessedTranscript] = useState('');

    const chatWindowRef = useRef(null);

    const handleChatOpen = () => {
        setChatOpen(!chatOpen);
    };

    return (
        <div>
            <input type="checkbox" className="iq-float-menu-open" name="menu-open" id="menu-open" />
            <label className="iq-float-menu-open-button" htmlFor="menu-open">
                <span className="lines line-1"></span>
                <span className="lines line-2"></span>
                <span className="lines line-3"></span>
            </label>
            <button className="iq-float-menu-item bg-primary" data-toggle="tooltip" data-placement="top" title="Chat Bot" onClick={handleChatOpen}>
                <RiRobotLine />
            </button>
            <button className="iq-float-menu-item bg-info" data-toggle="tooltip" data-placement="top" title="Direction Mode" data-mode="rtl">
                <RiTextDirectionR />
            </button>
            <button className="iq-float-menu-item bg-danger" data-toggle="tooltip" data-placement="top" title="Color Mode" id="dark-mode" data-active="true">
                <RiSunLine />
            </button>

            <CSSTransition
                in={chatOpen}
                timeout={300}
                classNames="chat"
                unmountOnExit
                nodeRef={chatWindowRef}
            >
                <div ref={chatWindowRef} className="chat-window">
                    <div className="chat-header">
                        <h4 className="text-white">AI Chat Bot</h4>
                        <button onClick={handleChatOpen} className="close-chat">X</button>
                    </div>
                    <div className="chat-body">
                        {messages.map((message, index) => (
                            <div key={index} className={`chat-message ${message.type}`}>
                                {message.text}
                            </div>
                        ))}
                        {currentMessage && (
                            <div className="chat-message received">
                                {currentMessage}
                            </div>
                        )}
                    </div>
                    <div className="chat-footer">
                        <input type="text" placeholder="Type a message..." className="chat-input" />
                        <button className="send-button">
                            <RiSendPlane2Fill />
                        </button>
                        <SpeechHandler
                            setCurrentMessage={setCurrentMessage}
                            setProcessedTranscript={setProcessedTranscript}
                        />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default FloatMenu;
