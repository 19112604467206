import React, { useState, useEffect } from 'react';
import '../Users/UserModal.css';
import axios from 'axios';

const MenuModal = ({ isOpen, onClose, menu, menus, onMenuAdded }) => {
    const [formData, setFormData] = useState({
        name: '',
        menu_type: '',
        main_menu_id: '',
        is_active: true,
    });

    useEffect(() => {
        if (menu) {
            setFormData({
                name: menu.name || '',
                menu_type: menu.menu_type || '',
                main_menu_id: menu.main_menu_id || '',
                is_active: menu.is_active || true,
            });
        } else {
            setFormData({
                name: '',
                menu_type: '',
                main_menu_id: '',
                is_active: true,
            });
        }
    }, [menu]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleMenuTypeChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            menu_type: value,
            main_menu_id: value === 'sub_menu' ? formData.main_menu_id : '', // Reset main_menu_id if not sub_menu
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            if (menu) {
                await axios.post(`/update_menu/${menu.id}`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': csrfToken,
                    },
                });
                onMenuAdded();
            } else {
                await axios.post('/new_menu', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': csrfToken,
                    },
                });
                onMenuAdded();
            }

            console.log('Menu saved successfully:');
            onClose();
        } catch (error) {
            console.error('Error saving menu:', error);
        }
    };

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{menu ? 'Edit Menu' : 'Add New Menu'}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="iq-card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Menu Type <span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            className="form-control"
                                            name="menu_type"
                                            onChange={handleMenuTypeChange}
                                            value={formData.menu_type}
                                            required
                                        >
                                            <option value="" disabled>Select Menu Type</option>
                                            <option value="main_menu">Main Menu</option>
                                            <option value="sub_menu">Sub Menu</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="MenuName">Menu Name <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="MenuName"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Enter Menu Name"
                                        />
                                    </div>
                                </div>
                                {formData.menu_type === 'sub_menu' && (
                                    <div className="col-md-6" id="main_menu_id">
                                        <div className="form-group">
                                            <label className="col-form-label">Main Menu<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control"
                                                name="main_menu_id"
                                                value={formData.main_menu_id}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled>Select Main Menu</option>
                                                {menus
                                                    .filter(menu => menu.menu_type === 'main_menu')
                                                    .map(menu => (
                                                        <option key={menu.id} value={menu.id}>{menu.name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                )}
                                <div className="col-md-6 mt-5">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="is_active"
                                                id="Active"
                                                checked={formData.is_active}
                                                onChange={handleChange}
                                            />
                                            <label className="custom-control-label" htmlFor="Active">Inactive / Active</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={onClose}>Close
                                </button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuModal;
