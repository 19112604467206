import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ConfirmationModal = ({ show, onHide, onConfirm, message }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Modal.Title>Confirmation</Modal.Title>
                <Button variant="link" onClick={onHide} style={{ color: 'black', padding: '0' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
