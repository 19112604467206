import React from 'react';
import logo1 from '../images/logo.gif';
import user1 from '../images/user/02.jpg';
import {Link} from "react-router-dom";
import dashboard from "../Dashboard/dashboard";
import '..//..//js/lottie'

const TopNavbar = ({ currentUser ,avatarUrl}) => {
    return (
        <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
                <div className="iq-sidebar-logo">
                    <div className="top-logo">
                        <a href="/" className="logo">
                            <div className="iq-light-logo">
                                <img src={logo1} className="img-fluid" alt="" />
                            </div>
                            <div className="iq-dark-logo">
                                <img src={logo1} className="img-fluid" alt="" />
                            </div>
                            <span>Sapphire</span>
                        </a>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="navbar-left">
                        <ul id="topbar-data-icon" className="d-flex p-0 topbar-menu-icon">
                            <li className="nav-item">
                                <Link to='/dashboard' className="nav-link font-weight-bold search-box-toggle">
                                    <i className="ri-home-4-line"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to='/pms' className="nav-link router-link-exact-active router-link-active">
                                    <i className="ri-chat-check-line"></i>
                                </Link>
                            </li>
                            <li>
                                <a href="/activity" className="nav-link">
                                    <i className="ri-list-check-2"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="nav-link">
                                    <i className="ri-file-list-line"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/user-profile" className="nav-link">
                                    <i className="ri-question-answer-line"></i>
                                </a>
                            </li>
                        </ul>
                        <div className="iq-search-bar d-none d-md-block">
                            <form action="#" className="searchbox">
                                <input type="text" className="text search-input" placeholder="Type here to search..." />
                                <a className="search-link" href="#">
                                    <i className="ri-search-line"></i>
                                </a>
                                <div className="searchbox-datalink">
                                    <h6 className="pl-3 pt-3 pb-3">Pages</h6>
                                    <ul className="m-0 pl-3 pr-3 pb-3">
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="/dashboard" className="nav-link router-link-exact-active router-link-active pr-2">
                                                <i className="ri-home-4-line pr-2"></i>Dashboard
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="/activity" className="nav-link">
                                                <i className="ri-message-line pr-2"></i>Activity
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-calendar-2-line pr-2"></i>Calendar
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-profile-line pr-2"></i>Profile
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-chat-check-line pr-2"></i>Todo
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-mail-line pr-2"></i>Email
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-message-line pr-2"></i>Product Listing
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-file-list-line pr-2"></i>Product Details
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-compasses-line pr-2"></i>Faq
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-clockwise-line pr-2"></i>Form-wizard
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                        <i className="ri-menu-3-line"></i>
                    </button>
                    <div className="iq-menu-bt align-self-center">
                        <div className="wrapper-menu">
                            <div className="main-circle">
                                <i className="ri-arrow-left-s-line"></i>
                            </div>
                            <div className="hover-circle">
                                <i className="ri-arrow-right-s-line"></i>
                            </div>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-list">
                            <li className="nav-item">
                                <a href="#" className="search-toggle iq-waves-effect">
                                    <div id="lottie-mail">
                                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.35419 21C10.0593 21.6224 10.9856 22 12 22C13.0145 22 13.9407 21.6224 14.6458 21M18 8C18 6.4087 17.3679 4.88258 16.2427 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.8826 2.63214 7.75738 3.75736C6.63216 4.88258 6.00002 6.4087 6.00002 8C6.00002 11.0902 5.22049 13.206 4.34968 14.6054C3.61515 15.7859 3.24788 16.3761 3.26134 16.5408C3.27626 16.7231 3.31488 16.7926 3.46179 16.9016C3.59448 17 4.19261 17 5.38887 17H18.6112C19.8074 17 20.4056 17 20.5382 16.9016C20.6852 16.7926 20.7238 16.7231 20.7387 16.5408C20.7522 16.3761 20.3849 15.7859 19.6504 14.6054C18.7795 13.206 18 11.0902 18 8Z"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <span className="bg-primary count-mail"></span>
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="search-toggle iq-waves-effect">
                                    <div id="lottie-mail">
                                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <span className="bg-primary count-mail"></span>
                                </a>
                                <div className="iq-sub-dropdown">
                                    <div className="iq-card shadow-none m-0">
                                        <div className="iq-card-body p-0 ">
                                            <div className="bg-primary p-3">
                                                <h5 className="mb-0 text-white">All Messages<small className="badge badge-light float-right pt-1">0</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul className="navbar-list">
                        <li>
                            <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
                                <img src={avatarUrl || user1} className="img-fluid rounded mr-3" alt="user" />
                                <div className="caption">
                                    <h6 className="mb-0 line-height text-white">{currentUser.full_name}</h6>
                                    <span className="font-size-12 text-white">Available</span>
                                </div>
                            </a>
                            <div className="iq-sub-dropdown iq-user-dropdown">
                                <div className="iq-card shadow-none m-0">
                                    <div className="iq-card-body p-0 ">
                                        <div className="bg-primary p-3">
                                            <h5 className="mb-0 text-white line-height">Hello {currentUser.full_name}</h5>
                                            <span className="text-white font-size-12">Available</span>
                                        </div>
                                        <Link to='/user_profile' className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-file-user-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0">My Profile</h6>
                                                    <p className="mb-0 font-size-12">View personal profile details.</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="d-inline-block w-100 text-center p-3">
                                            <a className="btn btn-primary dark-btn-primary" href="/logout" role="button">
                                                Sign out<i className="ri-login-box-line ml-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default TopNavbar;
