import React, { useState, useEffect } from 'react';
import {
    Button as BootstrapButton,
    Modal as BootstrapModal,
    Container,
    Form,
    Col,
    Row,
    Button,
    Modal
} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

function DiscountDetails() {
    const [cardNumber, setCardNumber] = useState('');
    const [showCard, setShowCard] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://paperform.co/__embed.min.js';
        document.body.appendChild(script);
    }, []);

    const handleSearch = async () => {
        if (cardNumber.trim()) {
            try {
                const response = await fetch(`/discount_details/fetch_data?card_number=${cardNumber}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.error) {
                    setShowModal(true);
                } else {
                    // Handle the data (e.g., update state to show card details)
                    setShowCard(true);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                setShowModal(true);
            }
        } else {
            setShowModal(true);
        }
    };


    const renderCard = () => {
        return (
            <div className="card-body p-md-5">
                <div className="row justify-content-center rounded white-background">
                    <p className="text-center h1 fw-bold mb-2 mt-2 text">Discount Card</p>
                </div>
                <div className="row mb-3 mt-5 justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                        <Form>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Label>Employee Code</Form.Label>
                                </div>
                                <div className="col-md-8">
                                    <Form.Control placeholder="Employee Code" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Label>Card #</Form.Label>
                                </div>
                                <div className="col-md-8">
                                    <Form.Control placeholder="Card #" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Label>Total Balance</Form.Label>
                                </div>
                                <div className="col-md-8">
                                    <Form.Control placeholder="Total Balance" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Label>Remaining Balance</Form.Label>
                                </div>
                                <div className="col-md-8">
                                    <Form.Control placeholder="Remaining Balance" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Label>Cap Limit</Form.Label>
                                </div>
                                <div className="col-md-8">
                                    <Form.Control placeholder="Cap Limit" />
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <Container className="mt-5 p-4 bg-gradient rounded shadow image-center">
                <Form>
                    <Row className="align-items-center">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="form-label font-text image-center">Enter Card Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="image-center"
                                    value={cardNumber}
                                    onChange={(e) => setCardNumber(e.target.value)}
                                    placeholder="SGC-000-000"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <BootstrapButton variant="primary" onClick={handleSearch}>Search</BootstrapButton>
                        </Col>
                    </Row>
                </Form>
            </Container>

            {showCard && renderCard()}

            <BootstrapModal show={showModal} onHide={() => setShowModal(false)}>
                              <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Modal.Title>Invalid Input</Modal.Title>
                    <Button variant="link" onClick={() => setShowModal(false)} style={{ color: 'black', padding: '0' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </Modal.Header>
                <BootstrapModal.Body>Please enter a valid card number.</BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <BootstrapButton variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </BootstrapButton>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </div>
    );
}

export default DiscountDetails;

// Styling
const style = document.createElement('style');
style.innerHTML = `
.bg-gradient {
    background: linear-gradient(to right, var(--iq-primary));
}

.mut {
    color: #ffffff;
}

.header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.discount-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 10px;
}

.font-text {
    font-size: medium;
    font-weight: 400;
}

.gradient-custom {
    background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
    linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
    linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
    radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
    radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
    background-blend-mode: screen, color-dodge, overlay, difference, normal;
}

.header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iq-header-title {
    font-size: 2.5rem;
    color: #333;
}

.discount-image {
    width: 200px;
    height: auto;
    margin-top: 20px;
}

.form-label {
    font-size: 0.8rem;
}

.font-text {
    font-size: 1.1rem;
}

.mut {
    font-size: 0.8rem;
}

.tagline {
    color: black;
}

.discount-image2 {
    max-width: 50%;
}

.white-background {
    background-color: #6546D2;
}

.text {
    color: white;
}

.image-center {
    text-align: center;
}
`;
document.head.appendChild(style);
