import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import axios from 'axios';
import styled from 'styled-components';

const ProductContainer = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
`;

const ZoomControls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const ZoomButton = styled.button`
  background-color: #6546D2FF;
  color: white;
  width: 35px;
  border: none;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 27px;
  &:hover {
    background-color: #CCC1F0FF;
  }
`;

const ViewControls = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

const ViewButton = styled.button`
  background-color: #ffffff;
  color: #000;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ProductDisplay = ({ baseImage, customizations, onRemoveCustomization, embroideryColor, currentView, setCurrentView }) => {
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);

    useEffect(() => {
        if (canvasRef.current) {
            const canvasInstance = new fabric.Canvas(canvasRef.current, {
                width: 500,
                height: 500,
            });
            setCanvas(canvasInstance);
        }
    }, []);

    useEffect(() => {
        if (canvas && baseImage[currentView]) {
            fabric.Image.fromURL(baseImage[currentView], (img) => {
                img.scaleToWidth(500);
                img.scaleToHeight(500);
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            });
        }
    }, [canvas, baseImage, currentView]);

    useEffect(() => {
        if (canvas) {
            canvas.clear();
            if (baseImage[currentView]) {
                fabric.Image.fromURL(baseImage[currentView], (img) => {
                    img.scaleToWidth(500);
                    img.scaleToHeight(500);
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
                });
            }

            (customizations[currentView] || []).forEach((custom) => {
                if (custom.content.type === 'text') {
                    const text = new fabric.Text(custom.content.text, {
                        left: custom.style.left,
                        top: custom.style.top,
                        fill: custom.content.color,
                        fontFamily: custom.content.font,
                        originX: 'center',
                        originY: 'center',
                    });
                    canvas.add(text);
                } else if (custom.content.type === 'image') {
                    fabric.Image.fromURL(custom.content.src, (img) => {
                        img.set({
                            left: custom.style.left,
                            top: custom.style.top,
                            originX: 'center',
                            originY: 'center',
                            scaleX: custom.style.scaleX,
                            scaleY: custom.style.scaleY,
                        });
                        canvas.add(img);
                        img.on('modified', () => {
                            custom.style.left = img.left;
                            custom.style.top = img.top;
                            custom.style.scaleX = img.scaleX;
                            custom.style.scaleY = img.scaleY;
                        });

                        if (embroideryColor) {
                            const tintCanvas = document.createElement('canvas');
                            tintCanvas.width = img.width;
                            tintCanvas.height = img.height;
                            const ctx = tintCanvas.getContext('2d');

                            ctx.drawImage(img.getElement(), 0, 0);
                            ctx.globalCompositeOperation = 'source-atop';
                            ctx.fillStyle = embroideryColor;
                            ctx.fillRect(0, 0, img.width, img.height);

                            img.setSrc(tintCanvas.toDataURL(), () => {
                                canvas.renderAll();
                            });
                        }
                    });
                }
            });
            canvas.renderAll();
        }
    }, [canvas, customizations, baseImage, embroideryColor, currentView]);

    const captureCanvasImage = () => {
        if (canvas) {
            return canvas.toDataURL('image/png');
        }
        return null;
    };

    const exportToPDF = async () =>     {
        try {
            const frontCanvasImage = captureCanvasImage();
            setCurrentView('back');
            const backCanvasImage = captureCanvasImage();

            const customizationsData = {
                front: (customizations.front || []).map(custom => ({
                    content: {
                        src: custom.content.src,
                        type: custom.content.type,
                        price_change: custom.content.price_change,
                        size: custom.content.size,
                        design_name: custom.content.design_name,
                    },
                })),
                back: (customizations.back || []).map(custom => ({
                    content: {
                        src: custom.content.src,
                        type: custom.content.type,
                        price_change: custom.content.price_change,
                        size: custom.content.size,
                        design_name: custom.content.design_name,
                    },
                }))
            };

            const response = await fetch('/sapphire_x_you/customization_report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    sapphire_x_you: {
                        front_image: baseImage.front,
                        back_image: baseImage.back,
                        front_canvas_image: frontCanvasImage,
                        back_canvas_image: backCanvasImage,
                        customizations: customizationsData,
                        total_price: customizationsData.front.reduce((total, custom) => total + parseFloat(custom.content.price_change), 0) +
                            customizationsData.back.reduce((total, custom) => total + parseFloat(custom.content.price_change), 0)
                    }
                })
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'customization_report.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to generate PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const zoomIn = () => {
        if (canvas) {
            const newZoom = canvas.getZoom() * 1.1;
            canvas.setZoom(newZoom);
        }
    };

    const zoomOut = () => {
        if (canvas) {
            const newZoom = canvas.getZoom() * 0.9;
            canvas.setZoom(newZoom);
        }
    };

    return (
        <ProductContainer>
            <ZoomControls>
                <ZoomButton onClick={zoomIn}>
                    <i className="ri-zoom-in-line"></i>
                </ZoomButton>
                <ZoomButton onClick={zoomOut}>
                    <i className="ri-zoom-out-line"></i>
                </ZoomButton>
            </ZoomControls>
            <CanvasContainer>
                <canvas ref={canvasRef} />
            </CanvasContainer>
            <ViewControls>
                <ViewButton onClick={() => setCurrentView('front')}>Front View</ViewButton>
                <ViewButton onClick={() => setCurrentView('back')}>Back View</ViewButton>
            </ViewControls>
            <button onClick={exportToPDF} className="btn btn-outline-primary">
                Export <i className="ri-file-pdf-line"></i>{' '}
            </button>
        </ProductContainer>
    );
};

export default ProductDisplay;
