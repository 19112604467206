import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import TopNavBar from "../../Organization/Layout/TopNavBar";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import toast, { Toaster } from 'react-hot-toast';

const Locations = () => {
    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [locationToDelete, setLocationToDelete] = useState(null);
    const [showNoRecordFound, setShowNoRecordFound] = useState(false);

    useEffect(() => {
        fetchCompanies();
        fetchLocations();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get("/companies/fetch_company");
            setCompanies(response.data);
        } catch (error) {
            toast.error("Error fetching companies");
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get("/locations/fetch_location");
            const dataWithCompanyNames = response.data.map(location => ({
                ...location,
                company_name: location.company ? location.company.name : ""
            }));
            setLocations(dataWithCompanyNames);
            setShowNoRecordFound(dataWithCompanyNames.length === 0);
        } catch (error) {
            toast.error("Error fetching locations");
        }
    };

    const handleSave = async (location) => {
        try {
            if (currentLocation) {
                await toast.promise(
                    axios.put(`/locations/${currentLocation.id}`, location),
                    {
                        loading: 'Saving...',
                        success: 'Location updated successfully!',
                        error: 'Error saving location',
                    },
                    { position: 'top-center' }
                );
            } else {
                await toast.promise(
                    axios.post("/locations", location),
                    {
                        loading: 'Saving...',
                        success: 'Location created successfully!',
                        error: 'Error saving location',
                    },
                    { position: 'top-center' }
                );
            }
            fetchLocations();
            setShowModal(false);
            setCurrentLocation(null);
        } catch (error) {
            toast.error("Error saving location");
        }
    };

    const handleDelete = async (locationId) => {
        try {
            await toast.promise(
                axios.delete(`/locations/${locationId}`),
                {
                    loading: 'Deleting...',
                    success: 'Location deleted successfully!',
                    error: 'Error deleting location',
                },
                { position: 'top-center' }
            );
            fetchLocations();
            setShowConfirmModal(false);
        } catch (error) {
            toast.error("Error deleting location");
        }
    };

    const confirmDelete = (locationId) => {
        setLocationToDelete(locationId);
        setShowConfirmModal(true);
    };

    const handleEdit = (location) => {
        setCurrentLocation(location);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentLocation(null);
        setShowModal(true);
    };

    return (
        <div className="col-lg-12">
            <TopNavBar />
            <Toaster position="top-center" reverseOrder={false} />
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                    <button className="btn btn-primary mr-2" onClick={handleCreate}>
                        <FontAwesomeIcon icon={faPlus} /><span className="pl-1">Create Location</span>
                    </button>
                    <div className="iq-header-title">
                        <h4 className="card-title">Location Management</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    {showNoRecordFound ? (
                        <div style={{
                            backgroundColor: '#eaf6ea',
                            borderLeft: '3px solid #5cb85c',
                            padding: '10px 15px',
                            color: 'rgb(22, 160, 133)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '0',
                        }}>
                            No Record Found!
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <Table className="table mb-0 mt-2 table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col" className="font-weight-bold">Sr. no.</th>
                                    <th scope="col" className="font-weight-bold">Name</th>
                                    <th scope="col" className="font-weight-bold">Short Name</th>
                                    <th scope="col" className="font-weight-bold">Company</th>
                                    <th scope="col" className="font-weight-bold">Status</th>
                                    <th scope="col" className="font-weight-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {locations.map((location, index) => (
                                    <tr key={location.id}>
                                        <td>{index + 1}</td>
                                        <td>{location.name}</td>
                                        <td>{location.short_name}</td>
                                        <td>{location.company_name}</td>
                                        <td>
                                                <span className={`badge rounded-pill ${location.status ? 'badge-info' : 'badge-danger'}`}>
                                                    {location.status ? 'Active' : 'Inactive'}
                                                </span>
                                        </td>
                                        <td>
                                            <a onClick={() => handleEdit(location)} className="animate">
                                                <FontAwesomeIcon icon={faEdit} className="text-success pr-1 font-size-18" />
                                            </a>
                                            <a onClick={() => confirmDelete(location.id)} className="animate">
                                                <FontAwesomeIcon icon={faTrash} className="text-danger font-size-18" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>

            <LocationModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onSave={handleSave}
                location={currentLocation}
                companies={companies}
            />

            <ConfirmationModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={() => handleDelete(locationToDelete)}
                message="Are you sure you want to delete this location?"
            />
        </div>
    );
};

const LocationModal = ({ show, onHide, onSave, location, companies }) => {
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (location) {
            setName(location.name);
            setShortName(location.short_name);
            setCompanyId(location.company_id);
            setStatus(location.status);
        } else {
            setName("");
            setShortName("");
            setCompanyId("");
            setStatus(false);
        }
    }, [location]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ name, short_name: shortName, company_id: companyId, status });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header style={{ backgroundColor: 'rgb(22, 160, 133)', color: 'white' }}>
                <Modal.Title style={{ color: 'white' }}>{location ? "Edit Location" : "Create Location"}</Modal.Title>
                <Button variant="link" onClick={onHide} style={{ color: 'white', padding: '0' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            as="select"
                            value={companyId}
                            onChange={(e) => setCompanyId(e.target.value)}
                            required
                        >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Short Name (Enter 2 letters only)</Form.Label>
                        <Form.Control
                            type="text"
                            value={shortName}
                            onChange={(e) => setShortName(e.target.value)}
                            required
                            maxLength={2}
                            placeholder="Enter short name"
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Check
                            type="checkbox"
                            label=" Active/InActive"
                            checked={status}
                            onChange={(e) => setStatus(e.target.checked)}
                        />
                    </Form.Group>
                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="secondary" onClick={onHide} style={{ backgroundColor: '#d9534f', borderColor: '#d9534f' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" style={{ backgroundColor: 'rgb(22, 160, 133)', borderColor: '#5cb85c' }}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Locations;
