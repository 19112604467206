import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglassHalf, faTimesCircle, faSyncAlt, faClipboardCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

const TaskProgress = ({ taskCreatedAt, taskStatusChanges, assignmentDate }) => {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const statusIcons = {
            'Not Started': faHourglassHalf,
            'In-Process': faSyncAlt,
            'Waiting for Purchase': faHourglassHalf,
            'Waiting for Approval': faHourglassHalf,
            'Completed': faCheckCircle,
            'On Hold': faTimesCircle,
            'Cancelled': faTimesCircle,
        };
        const timeZone = 'Asia/Karachi';
        const progressSteps = [
            { name: 'Created', timestamp: taskCreatedAt, icon: faCheckCircle },
            { name: 'Assigned', timestamp: assignmentDate, icon: faSyncAlt },
            ...taskStatusChanges.map((statusChange) => ({
                name: statusChange.status,
                timestamp: statusChange.timestamp,
                icon: statusIcons[statusChange.status] || faClipboardCheck,
            }))
        ];

        setSteps(progressSteps);
    }, [taskCreatedAt, taskStatusChanges, assignmentDate]);

    // Add fade-in animation and styling for steps
    const fadeInStyle = {
        animation: 'fadeIn 1s ease-in-out',
    };

    const stepContainerStyle = {
        position: 'relative',
        padding: '20px 0',
        display: 'flex',
        alignItems: 'center',
    };

    const stepIconStyle = (index, length) => ({
        position: 'relative',
        zIndex: 2,
        color: index === length - 1 ? 'blue' : 'green',
    });

    // Arrow styling and animation
    const arrowStyle = {
        position: 'relative',
        top: '12px',
        left: '20px',
        fontSize: '20px',
        animation: 'arrowMove 1s infinite',
        color: 'green',
    };

    const keyframesStyle = `
        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
        }

        @keyframes arrowMove {
            0% { transform: translateX(0); }
            50% { transform: translateX(5px); }
            100% { transform: translateX(0); }
        }
    `;

    return (
        <div style={stepContainerStyle}>
            <style>{keyframesStyle}</style>
            <Row style={{ justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                {steps.map((step, index) => (
                    <Col
                        key={index}
                        xs={2}
                        className="text-center"
                        style={{ position: 'relative', padding: '0 10px', ...fadeInStyle }}
                    >
                        <div style={{ ...stepIconStyle(index, steps.length) }}>
                            <FontAwesomeIcon
                                icon={step.icon}
                                size="2x"
                            />
                            <div style={{ marginTop: '5px', whiteSpace: 'nowrap' }}>{step.name}</div>
                            <small>{new Date(step.timestamp).toLocaleString()}</small>
                        </div>

                        {index < steps.length - 1 && (
                            <div style={{ position: 'absolute', top: '15%', left: '85%', transform: 'translateY(-50%)' }}>
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    style={arrowStyle}
                                />
                            </div>
                        )}
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default TaskProgress;
